<div class="centered-login">
	<div *ngIf="!profile.userId">
		<h1>Login to IUDX Account</h1>
		<p>
			Please complete your login in the opened tab/popup to get access to
			your dashboard.
			<br />
			In case, a new tab has not opened, please enable the browser to open
			popups from this website.
		</p>
	</div>
	<div *ngIf="profile.userId">
		<h1>Welcome {{profile.name.firstName}} {{profile.name.lastName}},</h1>
		<p *ngIf="profile.roles?.length === 0">
			Your account is still pending approval from our review team. Once
			verified, you can access the provider panel.
		</p>
		<p *ngIf="!is_provider">
			Your account doesn't have sufficient permission to access the
			provider panel.
			<br />
			Please contact technical support team for further support.
		</p>
	</div>
	<div class="d-flex justify-content-center">
		<button *ngIf="profile.userId" (click)="logout()" class="me-3">
			Log Out
		</button>

		<button
			class="register-button mx-0 d-flex justify-content-start pb-lg-3"
			*ngIf="roles?.length>1 || enableSwitchPanel"
			(click)="go_to_profile()"
		>
			<i class="fa-solid fa-repeat"></i>
			<span class="role">switch panel</span>
		</button>
		<button
			class="mx-0 d-flex justify-content-start pb-lg-3"
			*ngIf="roles?.length===1 && !enableSwitchPanel"
			(click)="navigateToPanel(singleRoleName)"
		>
			<i class="fa-solid fa-repeat"></i>

			<span> {{'Go to '+ (singleRoleName | titlecase) + ' Panel'}} </span>
		</button>
	</div>
</div>
