import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
	formData: any;
	constructor(public activeModal: NgbActiveModal) {
		this.formData = {
			isDelegateForm: false,
			isConsumerDelegateForm: false,
			isLocationPickerModal: false,
			title: ''
		};
	}
	ngOnInit(): void {}
}
