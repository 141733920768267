import { Injectable } from '@angular/core';
import { AuthorizationService as authCat } from 'src/app/catalogue/shared/services/authorization.service';
import { AuthorizationService as authConsumer } from 'src/app/consumer/shared/services/authorization.service';
import { NetworkService } from './network.service';
import { GlobalService } from './../../auth/services/global.service';
import { HttpClient } from '@angular/common/http'; // check again
import { NetworkService as catNetworkService } from 'src/app/catalogue/shared/services/network.service';
import { KeycloakService } from 'keycloak-angular';
import { GlobalService as GlobalProviderService } from 'src/app/provider/shared/services/global.service';
import { GlobalService as GlobalConsumerService } from 'src/app/consumer/shared/services/global.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(
		private authCat: authCat,
		private authConsumer: authConsumer,
		private network: NetworkService,
		private catNetwork: catNetworkService,
		public keycloak: KeycloakService,
		public global: GlobalService,
		public global_provider: GlobalProviderService,
		public global_consumer: GlobalConsumerService,
		public http: HttpClient
	) {}

	get_headers(params?: any, headersFor?: any, type?: any): any {
		const headers: any = {};
		const token =
			params && params.token
				? params.token
				: this.authCat.get_auth_token();
		headers['Authorization'] = token;
		if (params == 'res' && token) {
			headers['token'] = this.authConsumer.get_res_token(type);
		}
		if (params == 'delegate_id') {
			const provider_id = localStorage.getItem('provider_delegate_id');
			const delegate_details =
				this.global_provider.get_provider_delegate_details();
			headers['delegationId'] = provider_id
				? provider_id
				: delegate_details.id;
		} else if (params == 'consumer_delegate_id') {
			const consumer_id = localStorage.getItem('consumer_delegate_id');
			const delegate_details =
				this.global_consumer.get_consumer_delegate_details();
			headers['delegationId'] = consumer_id
				? consumer_id
				: delegate_details.id;
		}
		if (headersFor === 'keycloak_auth') {
			headers['Authorization'] =
				params && params.token
					? params.token
					: this.authCat.get_auth_token();
		} else if (params == 'res' && token) {
			headers['token'] = this.authConsumer.get_res_token(type);
		}
		return headers;
	}

	async get_delegations(params?: any): Promise<any> {
		try {
			return await this.network.get_api_without_toaster(
				'auth/v1/delegations',
				this.get_headers(params),
				'delegations'
			);
		} catch (e: any) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	}
	async getNewKey(): Promise<any> {
		try {
			await this.network.get_api_without_toaster(
				'auth/v1/user/clientcredentials',
				'',
				'auth'
			);
		} catch (e: any) {
			const clientId = e.error?.context.clientId;
			if (e.error?.context.clientId) {
				return this.getClientSceret(clientId);
			}
		}
	}
	async getClientSceret(clientId: any): Promise<any> {
		try {
			return await this.network.put_api(
				environment.auth_server_url + 'auth/v1/user/clientcredentials',
				{ clientId },
				'',
				'auth'
			);
		} catch (e: any) {
			// console.log(e);
		}
	}

	async get_resource_servers_by_roles(params?: any): Promise<any> {
		return await this.network.get_api(
			'auth/v1/user/roles',
			this.get_headers(params),
			'delegations'
		);
	}

	async getClientCredentials(): Promise<any> {
		try {
			const resp = await this.network.get_api_without_toaster(
				'auth/v1/user/clientcredentials',
				'',
				'auth'
			);
			return resp;
		} catch (e: any) {
			return e.status;
		}
	}

	async add_delegate(post_body: any): Promise<any> {
		return await this.network.post_api(
			'auth/v1/delegations',
			post_body,
			this.get_headers(),
			'delegations'
		);
	}

	async delete_delegate(request_id: any): Promise<any> {
		const body = {
			request: [
				{
					id: request_id
				}
			]
		};
		return await this.network.delete_api(
			'auth/v1/delegations',
			body,
			this.get_headers(),
			'delegations'
		);
	}

	//naveen acl apd apis
	async get_id_token(post_body: any, header?: any): Promise<any> {
		try {
			const result: any = await this.catNetwork.post_api(
				environment.auth_server_url + 'auth/v1/token',
				post_body,
				this.get_headers(header),
				false
			);
			localStorage.setItem(
				'iudx-ui-aclapd-token',
				result.results?.accessToken
			);
			return 'Bearer ' + result.results?.accessToken;
		} catch (e: any) {
			return;
		}
	}
	//FOR DMP APIS(later combine it with acl-apd-token)
	async get_provider_id_token(post_body: any, header?: any): Promise<any> {
		try {
			const result: any = await this.catNetwork.post_api(
				environment.auth_server_url + 'auth/v1/token',
				post_body,
				this.get_headers(header),
				false
			);
			localStorage.setItem(
				'iudx-ui-dmp-token',
				result.results?.accessToken
			);
			return 'Bearer ' + result.results?.accessToken;
		} catch (e: any) {
			return;
		}
	}
	async get_id_token1(post_body: any): Promise<any> {
		try {
			const result: any = await this.catNetwork.post_api(
				environment.auth_server_url + 'auth/v1/token',
				post_body,
				'',
				false
			);
			localStorage.setItem(
				'iudx-ui-aclapd-token',
				result.results?.accessToken
			);
			return result.results?.accessToken;
		} catch (e: any) {
			// console.log(e);
		}
	}

	async getResourceServerId(serverUrl: any) {
		try {
			const resp: any = await this.network.get_api(
				environment.catalogue_server_url +
					'search?property=[type,resourceServerRegURL]&value=[[iudx:ResourceServer],[' +
					serverUrl +
					']]&filter=[id]'
			);
			return resp;
		} catch (e) {
			// console.log(e);
		}
	}

	//TO DO: Use the above generalised above function instead
	//ojas apis
	async get_token(): Promise<any> {
		// call {{AUTH_ENDPOINT}}/auth/v1/user/roles get the rolesToRsMapping[admin][0]	<== itemId
		const post_body = {
			itemId: 'soi.iudx.io',
			itemType: 'resource_server',
			role: 'admin'
		};
		// const token = 'Bearer '+this.global.get_token();
		// console.log("TOKEN : "+token);
		// const header = {Authorization : token}

		const result: any = await this.catNetwork.post_api(
			environment.auth_server_url + 'auth/v1/token',
			post_body
		);
		return 'Bearer ' + result.results.accessToken;
	}

	async get_resource_servers(): Promise<any> {
		const result: any = await this.catNetwork.get_api(
			environment.auth_server_url + 'auth/v1/resourceservers'
		);
		return result['results'];
	}

	async get_requests(filter: any): Promise<any> {
		const result: any = await this.catNetwork.get_api(
			environment.auth_server_url +
				'auth/v1/admin/provider/registrations?filter=' +
				filter
		);
		return result['results'];
	}

	async getInstancesFromCat(): Promise<any> {
		const result: any = await this.catNetwork.get_api(
			environment.catalogue_server_url + 'list/instance'
		);
		return result['results'];
	}

	async process_provider_request(id: any, status: any): Promise<any> {
		const post_body = {
			request: [
				{
					id: id,
					status: status
				}
			]
		};
		const result: any = await this.catNetwork.put_api(
			environment.auth_server_url +
				'auth/v1/admin/provider/registrations',
			post_body
		);
		return result;
	}

	async approve_provider(token: any, post_body: any, id: any): Promise<any> {
		let result: any;

		try {
			const headers: any = {};
			headers['token'] = token;
			result = await this.catNetwork.post_api(
				environment.onboarding_server_url + 'item/',
				post_body,
				headers,
				false
			);

			try {
				if (result?.results.id) {
					const post_body = {
						request: [
							{
								id: id,
								status: 'approved'
							}
						]
					};
					await this.catNetwork.put_api(
						environment.auth_server_url +
							'auth/v1/admin/provider/registrations',
						post_body
					);
				}
			} catch (e) {
				const headers: any = {};
				headers['token'] = token;
				await this.catNetwork.delete_api(
					environment.onboarding_server_url + 'item/?id=' + id,
					headers,
					false
				);
			}
		} catch (e) {
			return e;
		}
	}
}
