import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
// import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { set_keycloak } from 'src/app/auth/services/keycloak.init';

// const keycloak_subject = new Subject<any>();

export function initializeKeycloak(keycloak: KeycloakService) {
	return () => {
		keycloak.keycloakEvents$?.subscribe({
			next: (e) => {
				if (e.type == KeycloakEventType.OnTokenExpired) {
					const lstoken = localStorage.getItem(
						'iudx-ui-cat-auth-token'
					);
					if (lstoken) {
						keycloak.getToken().then(
							(token) => {
								localStorage.setItem(
									'iudx-ui-cat-auth-token',
									'Bearer ' + token
								);
							},
							(err) => {
								// eslint-disable-next-line no-console
								console.error(
									'Token Refreshed by Get Token function failed: ',
									err
								);
							}
						);
					}
				}
			}
		});
		keycloak
			.init({
				config: environment.keycloak,
				initOptions: {
					checkLoginIframe: true,
					checkLoginIframeInterval: 25
				},
				loadUserProfileAtStartUp: true
			})
			.then(async () => {
				set_keycloak();
			});
	};
}
