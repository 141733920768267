import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/catalogue/shared/services/api.service';
import * as moment from 'moment';

@Component({
	selector: 'api-usage',
	templateUrl: './api-usage.component.html',
	styleUrls: ['./api-usage.component.scss']
})
export class ApiUsageComponent implements OnInit {
	realtimeCalls: any;
	summaryCalls: any;
	options: any;
	optionsSize: any;
	size: any;
	visualize = false;
	oneYearDuration = '';
	sixMonthDuration = '';
	oneMonthDuration = '';
	oneWeekDuration = '';
	yesterdayDuration = '';
	todayDuration = '';
	constructor(private api: ApiService) {}

	async ngOnInit(): Promise<void> {
		await this.getCallCount();
	}

	async getCallCount() {
		this.realtimeCalls = await this.api.get_call_count_realtime();
		const summaryResponse = await this.api.get_call_count_summary();
		this.summaryCalls = [];

		summaryResponse.forEach((element: any, index: number) => {
			let fromDate = '';
			let toDate = '';

			// Determine the from and to dates based on the duration
			switch (index) {
				case 0: // Beginning
					fromDate = moment('2023-04-01').format(
						'DD-MMMM-YYYY HH:mm:ss'
					);
					toDate = moment()
						.endOf('day')
						.format('DD-MMMM-YYYY HH:mm:ss');
					break;
				case 1: // 1 Year
					fromDate = moment()
						.subtract(1, 'years')
						.format('DD-MMMM-YYYY HH:mm:ss');
					toDate = moment()
						.subtract(1, 'days')
						.endOf('day')
						.format('DD-MMMM-YYYY HH:mm:ss');
					this.oneYearDuration = fromDate + '\n\n to \n\n ' + toDate;
					break;
				case 2: // 6 Months
					fromDate = moment()
						.subtract(6, 'months')
						.startOf('month')
						.format('DD-MMMM-YYYY HH:mm:ss');
					toDate = moment()
						.subtract(1, 'days')
						.endOf('day')
						.format('DD-MMMM-YYYY HH:mm:ss');
					this.sixMonthDuration = fromDate + '\n\n to \n\n ' + toDate;
					break;
				case 3: // 1 Month
					fromDate = moment()
						.subtract(1, 'months')
						.startOf('month')
						.format('DD-MMMM-YYYY HH:mm:ss');
					toDate = moment()
						.subtract(1, 'days')
						.endOf('day')
						.format('DD-MMMM-YYYY HH:mm:ss');
					this.oneMonthDuration = fromDate + '\n\n to \n\n ' + toDate;
					break;
				case 4: // 1 Week
					fromDate = moment()
						.subtract(1, 'weeks')
						.startOf('week')
						.format('DD-MMMM-YYYY HH:mm:ss');
					toDate = moment()
						.subtract(1, 'days')
						.endOf('day')
						.format('DD-MMMM-YYYY HH:mm:ss');
					this.oneWeekDuration = fromDate + '\n\n to \n\n ' + toDate;

					break;
				case 5: // Yesterday
					fromDate = moment()
						.subtract(1, 'days')
						.startOf('day')
						.format('DD-MMMM-YYYY HH:mm:ss');
					toDate = moment()
						.subtract(1, 'days')
						.endOf('day')
						.format('DD-MMMM-YYYY HH:mm:ss');
					this.yesterdayDuration =
						fromDate + '\n\n to \n\n ' + toDate;

					break;

				// Add more cases for additional durations as needed
				default:
					break;
			}
			this.summaryCalls.push({
				description: `${element.description}\n`,
				dates: `${fromDate} to ${toDate}`,
				size: Math.round(Number.parseInt(element.size) / 1048576),
				count: element.count
			});
		});

		//Handling to display today dataset
		const currentDate = moment().format('DD-MMMM-YYYY HH:mm:ss');
		this.summaryCalls.push({
			description: `Today\n\n`,
			dates: `${currentDate} to Till now`,
			size: Math.round(
				Number.parseInt(this.realtimeCalls[0].size) / 1048576
			),
			count: Number.parseInt(this.realtimeCalls[0].counts)
		});
		this.todayDuration = currentDate + '\n\n to \n\n' + 'Till Now';

		this.setChartOptions();
	}

	setChartOptions() {
		const sourceData = [
			['Duration', 'Number of API Calls ', 'Data Usage in KB']
		];

		const descriptions = [
			'Beginning',
			this.oneYearDuration,
			this.sixMonthDuration,
			this.oneMonthDuration,
			this.oneWeekDuration,
			this.yesterdayDuration,
			this.todayDuration
		];

		this.summaryCalls.forEach((call: any, index: number) => {
			const callData = [descriptions[index], call.count, call.size];
			sourceData.push(callData);
		});

		sourceData.splice(1, 1);

		this.options = {
			legend: {},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				},
				textStyle: {
					fontSize: 14,
					fontWeight: 'bold'
				}
			},
			dataset: {
				source: sourceData
			},
			xAxis: {
				type: 'category',
				axisLabel: {
					textStyle: {
						fontSize: 8,
						fontStyle: 'italic',
						fontWeight: 'bold'
					}
				}
			},
			yAxis: {},
			series: [{ type: 'bar' }, { type: 'bar' }]
		};
	}

	toggleTabs() {
		this.visualize = !this.visualize;
	}
}
