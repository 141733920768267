<div class="modal-section">
	<div class="modal-header header-top d-block d-flex px-5 py-4">
		<h4 class="modal-title header-heading">{{formData.title}}</h4>
		<i
			class="fa-solid fa-xmark"
			aria-label="Close"
			(click)="activeModal.dismiss('Cross click')"
		></i>
	</div>
	<div class="modal-body p-5">
		<app-location-picker-modal
			*ngIf="formData.isLocationPickerModal"
			[formData]="formData"
		></app-location-picker-modal>
	</div>
</div>
