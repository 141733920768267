import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './beforeAppInit/keycloak.init';
import { RequestInterceptor } from './beforeAppInit/request.interceptor';
import {
	NgbDropdown,
	NgbDropdownModule,
	NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { MakerService } from './catalogue/shared/services/maker.service';
import { PopupMarkerService } from './catalogue/shared/services/popup-marker.service';
import { ComponentsModule } from './shared/components/components.module';
import { CommonModule } from '@angular/common';
import { ComponentsModule as ComponentsModuleProvider } from './provider/shared/components/components.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiUsageComponent } from './catalogue/modules/api-usage/api-usage.component';
import { NgxEchartsModule } from 'ngx-echarts';

import { Ng9OdometerModule } from 'ng9-odometer';

@NgModule({
	declarations: [AppComponent, ApiUsageComponent],
	imports: [
		RouterModule.forChild([]),
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		KeycloakAngularModule,
		NgbModule,
		ReactiveFormsModule,
		CommonModule,
		NgbDropdownModule,
		ComponentsModule,
		ComponentsModuleProvider,
		NgMultiSelectDropDownModule.forRoot(),
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
		Ng9OdometerModule.forRoot()
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeKeycloak,
			multi: true,
			deps: [KeycloakService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true
		},
		MakerService,
		PopupMarkerService
	],
	exports: [NgbDropdown],
	bootstrap: [AppComponent]
})
export class AppModule {}
