import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService as GlobalCatService } from 'src/app/catalogue/shared/services/global.service';
import { ApiService } from '../../services/api.service';
import { GlobalService } from 'src/app/auth/services/global.service';

@Component({
	selector: 'app-roles-modal',
	templateUrl: './roles-modal.component.html',
	styleUrls: ['./roles-modal.component.scss']
})
export class RolesModalComponent implements OnInit {
	selectedRole: any;
	@Input() roles: any;
	@Input() title: any;
	user_profile: any;
	redirectArray: any = [];
	filteredProviderDelegates: any;

	constructor(
		public global_cat: GlobalCatService,
		public activeModal: NgbActiveModal,
		public modalService: NgbModal,
		public shared_api: ApiService,
		public global_shared: GlobalService
	) {
		this.filteredProviderDelegates =
			this.global_cat.getFilteredProviderDelegates();
	}

	async ngOnInit() {
		const url = location.pathname;
		const currentPanel = url.split('/')[1];
		for (const e of this.roles) {
			if (e !== currentPanel) {
				if (e == 'cos_admin') {
					this.redirectArray.push({
						name: 'cop admin',
						url: 'cop-admin',
						description: "Create a new resource server's."
					});
				} else if (e == 'admin') {
					this.redirectArray.push({
						name: 'rs admin',
						url: 'rs-admin',
						description: 'Accept or Reject the providers.'
					});
				} else if (e == 'consumer') {
					this.redirectArray.push({
						name: 'consumer',
						url: 'consumer',
						description:
							'Discover resources, get access information related to availability of a resource'
					});
				} else if (e == 'provider') {
					this.redirectArray.push({
						name: 'provider',
						url: 'provider',
						description:
							'Manage access to the secure resources that you own.'
					});
				} else if (e == 'catalogue') {
					this.redirectArray.push({
						name: 'catalogue',
						url: '',
						description: 'Explore more datasets and resources'
					});
				} else if (e == 'delegate') {
					const response: any =
						await this.shared_api.get_delegations();
					const uniqueIds: any = [];

					response?.results?.filter((element: any) => {
						const isDuplicate = uniqueIds.includes(element.role);

						if (!isDuplicate) {
							uniqueIds.push(element.role);
						}
					});
					const consumerDelegate = uniqueIds?.includes('consumer')
						? true
						: false;
					const providerDelegate = uniqueIds?.includes('provider')
						? true
						: false;
					if (consumerDelegate && currentPanel !== 'consumer') {
						this.redirectArray.push({
							name: 'consumer delegate',
							url: 'consumer',
							description:
								'Manage policies and delegations on behalf of the consumer who have assigned you'
						});
					}
					if (providerDelegate && currentPanel !== 'provider') {
						if (this.filteredProviderDelegates?.length > 0) {
							this.redirectArray.push({
								name: 'provider delegate',
								url: 'provider',
								description:
									'Manage policies and delegations on behalf of the providers who have assigned you'
							});
						}
					}
				}
			}
		}
		if (currentPanel == 'provider') {
			//if providerdelegate is true then he is in provider delegate
			if (localStorage.getItem('isProviderDelegate') === 'true') {
				this.redirectArray.push({
					name: 'provider',
					url: 'provider',
					description:
						'Manage access to the secure resources that you own.'
				});
			} else {
				if (this.filteredProviderDelegates?.length > 0)
					this.redirectArray.push({
						name: 'provider delegate',
						url: 'provider',
						description:
							'Manage policies and delegations on behalf of the providers who have assigned you'
					});
			}
		}
		this.redirectArray.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
	}
	selectProviderDelegate() {
		localStorage.setItem('isProviderDelegate', 'true');
	}
	selectProvider() {
		localStorage.setItem('isProviderDelegate', 'false');
	}
}
