import {
	Component,
	OnInit,
	ViewChild,
	Output,
	EventEmitter
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { SubscriberService } from '../../services/subscriber.service';
import { KeycloakUtilService as keycloakUtilCatService } from 'src/app/catalogue/shared/services/keycloak-util.service';
import { GlobalService as GlobalCatService } from 'src/app/catalogue/shared/services/global.service';
import { environment } from 'src/environments/environment';
@Component({
	selector: 'app-provider-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	@ViewChild('step3') step3: any;
	@ViewChild('step4') step4: any;
	@ViewChild('step5') step5: any;
	@ViewChild('step6') step6: any;
	overviewData: any;
	showAPD: any = false;
	showDatasetdetails: any = false;
	navbarExpanded = true;
	@Output() toggleSidebar: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	tabsArray: any = [];
	hoveredOn = {
		home: false,
		requests: false,
		datasets: false,
		delegates: false,
		consentmanager: false
	};
	is_provider: any;

	constructor(
		public global: GlobalService,
		public global_cat: GlobalCatService,
		public keycloak: KeycloakService,
		public keycloakUtilCat: keycloakUtilCatService,
		private subscriber: SubscriberService,
		private router: Router
	) {
		this.tabsArray = [];
		const user_profile = this.global_cat.get_user_profile();
		if (user_profile.roles?.includes('provider')) {
			this.is_provider = true;
		}
	}

	ngOnInit(): void {
		this.subscriber.getOverviewData().subscribe((data: any) => {
			this.overviewData = data;
		});
		const routeLink = this.router.url.split('#')[0];
		if (routeLink === '/' || routeLink === '/myprofile') {
			this.showAPD = false;
			this.showDatasetdetails = false;
		} else if (routeLink.slice(0, 8) === '/consent') {
			this.showAPD = true;
		} else {
			this.showDatasetdetails = true;
		}
	}
	handleSidebarToggle(): void {
		this.navbarExpanded = !this.navbarExpanded;
		this.toggleSidebar.emit(this.navbarExpanded);
	}
	toggleHover(currentTab: string): void {
		switch (currentTab) {
			case 'home':
				this.hoveredOn.home = !this.hoveredOn.home;
				break;
			case 'requests':
				this.hoveredOn.requests = !this.hoveredOn.requests;
				break;
			case 'datasets':
				this.hoveredOn.datasets = !this.hoveredOn.datasets;
				break;
			case 'delegates':
				this.hoveredOn.delegates = !this.hoveredOn.delegates;
				break;
			case 'consentmanager':
				this.hoveredOn.consentmanager = !this.hoveredOn.consentmanager;
				break;

			default:
				break;
		}
	}

	logout(): void {
		this.keycloakUtilCat.logout(this.keycloak);
		localStorage.removeItem('rs_url');
	}
	redirectHome() {
		window.open(environment.catalogue_web + '/', '_self');
	}
}
