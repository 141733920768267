import { Injectable } from '@angular/core';
import { SubscriberService } from './subscriber.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
// import { GlobalService as authGlobalService } from 'src/app/auth/services/global.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class NetworkService {
	constructor(
		public global: GlobalService,
		public http: HttpClient,
		public keycloak: KeycloakService,
		public subscriber: SubscriberService // public authGlobal : authGlobalService
	) {}

	get_token() {
		const token: any = ''; //this.authGlobal.get_token();
		if (token) {
			return token;
		} else {
			return '';
		}
	}

	get_headers(type: any, header?: any) {
		if (type == 'auth') {
			return {
				headers: new HttpHeaders({
					Authorization: this.get_token()
				})
			};
		} else if (type == 'del') {
			return {
				headers: new HttpHeaders({
					Authorization: this.get_token(),
					delegationId: header
				})
			};
		} else {
			return {
				headers: new HttpHeaders(header)
			};
		}
	}

	get_api(url: string, header?: any, type = 'auth', showToaster = true) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_url(url, type), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster(
									'error',
									err.error?.detail
							  )
							: '';
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	get_api_without_toaster(url: string, header?: any, type = 'auth') {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_url(url, type), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	get_api_for_request(
		url: string,
		header?: any,
		type = 'auth',
		showToaster: boolean = true
	) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_url(url, type), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster(
									'error',
									err.error?.detail
							  )
							: '';
					}
				);
		});
	}

	post_api(
		url: string,
		post_body: any,
		header?: any,
		type = 'auth',
		responseType?: any,
		showToaster: boolean = true
	) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.post(
					this.get_url(url, type),
					post_body,
					this.get_headers(type, header)
				)
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster(
									'success',
									data.detail
							  )
							: '';
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster(
									'error',
									err.error?.detail
							  )
							: '';
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	post_api_without_toaster(
		url: string,
		post_body: any,
		header?: any,
		type = 'auth',
		responseType?: any
	) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.post(this.get_url(url, type), post_body, {
					headers: new HttpHeaders(header),
					responseType: responseType
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	put_api(url: string, put_body: any, header?: any) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.put(this.get_url(url), put_body, {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	delete_api(
		url: string,
		body: any,
		header?: any,
		type = 'auth',
		showToaster: boolean = true
	) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.delete(this.get_url(url, type), {
					body,
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster(
									'success',
									data.detail
							  )
							: '';
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	delete_api_with_multipleHeader(
		url: string,
		header?: any,
		showLoader = true
	): any {
		showLoader && this.subscriber.set_loader(true);
		let headers = new HttpHeaders();
		headers = headers.set('token', `${header.token}`);
		headers = headers.set('options', `streaming`);
		return new Promise((resolve, reject) => {
			this.http
				.delete(this.get_url(url), {
					headers: headers
				})
				.subscribe(
					async (data: any) => {
						showLoader && this.subscriber.set_loader(false);
						this.subscriber.set_toaster('success', data.detail);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	handleErrors(err: any): void {
		if (err.status == 400 || err.status == 404 || err.status == 409)
			this.technicalErrorAlert(err);
		else if (
			err.status == 500 ||
			err.status == 501 ||
			err.status == 502 ||
			err.status == 503 ||
			err.status == 504
		)
			this.serverErrorAlert();
		else {
			if (
				err.error?.detail !==
				'An active and un-expired consent artifact already exists for data principal and resource'
			) {
				this.subscriber.set_toaster('error', err.error?.detail);
			}
		}
	}

	serverErrorAlert(): void {
		this.subscriber.set_toaster('error', 'Network error. Try again later.');
	}

	technicalErrorAlert(err: any): void {
		this.subscriber.set_toaster('error', err.error?.detail);
	}

	get_url(url: string, type = 'auth'): string {
		if (type == 'auth') {
			if (url.indexOf('http') === -1) {
				return environment.authorization_server_url + url;
			}
		}
		return url;
	}
}
