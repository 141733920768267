import {
	Component,
	Input,
	Output,
	EventEmitter,
	AfterViewInit
} from '@angular/core';
import { SubscriberService } from '../../services/subscriber.service';

@Component({
	selector: 'app-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterViewInit {
	@Input() tabs: any = [];
	@Output() changeTab = new EventEmitter<any>();
	currentTab: any;
	activeTabUrl = '';
	isFilter: any;

	constructor(private subscriber: SubscriberService) {
		this.subscriber.get_tagId().subscribe((data) => {
			this.activeTabUrl = data.id;
			this.currentTab = '';
		});
	}

	ngOnInit(): void {
		const parseUrl = window.location.pathname.split('/')[2];
		this.activeTabUrl = this.tabs.urls?.find((tab: any) => tab == parseUrl);
	}
	ngAfterViewInit() {}

	onTabChange(tab: any, i: any): void {
		this.activeTabUrl = '';
		this.changeTab.emit(tab);
		this.currentTab = this.tabs?.names?.[i];
	}
}
