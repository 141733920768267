import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { AuthorizationService } from './authorization.service';
import { environment } from 'src/environments/environment';
import { SubscriberService } from './subscriber.service';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { GlobalService as global_consumer } from 'src/app/consumer/shared/services/global.service';

//--------- Demo Response
import DemoResponse from "src/demoData/demoData.json";
//--------------------------


@Injectable({
	providedIn: 'root'
})
export class ApiService {
	instances: any = [];
	domains: any = [];
	diseaseDomains: any = [];
	tags: any = [];
	providers: any = [];
	summary: any;

	constructor(
		public network: NetworkService,
		public global: GlobalService,
		public subscriber: SubscriberService,
		public auth: AuthorizationService,
		public http: HttpClient,
		private global_consumer: global_consumer
	) { }

	get_headers(auth: any, type?: string, params?: any): any {
		const headers: any = {};
		const token =
			params && params.token ? params.token : this.auth.get_auth_token();
		headers['Authorization'] = token;
		if (auth == 'cat') {
			headers['instance'] = this.global.get_active_instance();
		}
		if (auth == 'res' && token) {
			headers['token'] = this.auth.get_res_token(type);
		}
		if (auth == 'rs' && token) {
			headers['token'] = this.auth.get_cat_token(type);
		}
		if (auth == 'subscription' && token) {
			headers['token'] = this.auth.get_subcription_token(type);
		}
		if (auth == 'consumer_delegate_id') {
			const consumer_id = localStorage.getItem('consumer_delegate_id');
			const delegate_details =
				this.global_consumer.get_consumer_delegate_details();
			headers['delegationId'] = consumer_id
				? consumer_id
				: delegate_details.id;
		}
		return headers;
	}

	async get_instances(): Promise<any> {
		if (this.instances && this.instances.length === 0) {
			//this.instances = await DemoResponse?.instances; // My code
			// return this.instances; // My code
			this.instances = await this.network.get_cat_api(
				'internal/ui/instance'
			);
		}
		return this.instances;
	}

	get_instances1() {
		if (this.instances && this.instances.length === 0) {
			this.instances = this.network.get_cat_api('internal/ui/instance');
		}
		return this.instances;
	}

	async get_domains(): Promise<any> {
		if (this.domains && this.domains.length === 0) {
			this.domains = await this.network.get_cat_api('internal/ui/domain');
			//this.domains = this.domains['results'];   //  UNCOMMENT
			this.domains = this.domains['results'].filter((dom: any) => dom.label.includes("ICMR"));  //  MY CODE
		}
		return this.domains;
	}

	// async get_disease_domains(): Promise<any> {
	// 	if (this.diseaseDomains && this.diseaseDomains.length === 0) {
	// 		return DemoResponse.diseaseDomains?.results;
	// 		this.diseaseDomains = await this.network.get_cat_api('internal/ui/domain');
	// 		this.diseaseDomains = this.diseaseDomains['results'];
	// 	}
	// 	return this.domains;
	// }

	async get_providers(activeInstance: string): Promise<any> {
		if (this.providers && this.providers.length === 0) {
			if (activeInstance) {
				this.providers = await this.network.get_cat_api(
					`internal/ui/providers?instance=${activeInstance}`
				);
			} else {
				this.providers = await this.network.get_cat_api(
					'internal/ui/providers'
				);
			}

			this.providers = this.providers['results'];
		}
		return this.providers;
	}

	async get_summary(): Promise<any> {
		if (!this.summary) {
			const post_body = {
				instance: this.global.get_active_instance()
			};
			this.summary = await this.network.post_api('overview', post_body);
		}
		return this.summary;
	}

	async get_popular_datasets(instance?: any): Promise<any> {
		if (instance === undefined || instance === '') {

			/* --------- MY CODE -------------- */
			// return await DemoResponse?.popularDataset; // My code
			let dataset_res: any = await this.network.get_cat_api(
				'internal/ui/popularDatasets'
			);

			dataset_res.results.featuredDataset = dataset_res.results.featuredDataset.filter((i: any) => i.type.includes("iudx:ICMR"));
			dataset_res.results.latestDataset = dataset_res.results.latestDataset.filter((i: any) => i.type.includes("iudx:ICMR"));

			let instance_res = await this.get_instances();

			// Filter only ICMR data
			dataset_res.results.instance = instance_res.results.filter((i: any) => i.name.includes("ICMR"))
				.map((i: any) => (
					{
						...i,
						name: i.name.split("_ICMR")[0],
						cover: "assets/" + i.cover.split("covers/")[1]
					}
				));
			return dataset_res;
			/* --------- MY CODE - END -----------*/

			return await this.network.get_cat_api(
				'internal/ui/popularDatasets'
			);
		}

		/* --------- MY CODE --------------
		// let response = await DemoResponse?.popularDataset; // My code
		// response.results.featuredDataset = response.results.featuredDataset.filter(item => item.type.toLowerCase() == instance); // My code
		// response.results.latestDataset = response.results.latestDataset.filter(item => item.type.toLowerCase() == instance); // My code
		// return response; // My code
		--------- MY CODE - END -----------*/

		return await this.network.get_cat_api(
			'internal/ui/popularDatasets?instance=' + instance
		);
	}

	async get_tags(): Promise<any> {
		if (this.tags && this.tags.length === 0) {
			this.tags = await this.network.get_cat_api('list/tags');
			this.tags = this.tags['results'];
		}
		return this.tags;
	}

	async get_datasets(filters?: any): Promise<any> {
		let post_body: any;
		if (this.global.get_active_instance() != '') {
			post_body = {
				instance: this.global.get_active_instance(),
				tags: [],
				providers: [],
				domains: [],
				search: ''
			};
		} else {
			post_body = {
				instance: '',
				tags: [],
				providers: [],
				domains: [],
				search: ''
			};
		}
		if (filters) {
			for (const key in post_body) {
				if (filters[key]) {
					post_body[key] = filters[key];
				}
			}
		}
		// return await this.network.post_cat_api('datasets', post_body);
		return await this.get_all_datasets();
	}

	async get_dataset_details(id: string): Promise<any> {
		const post_body = {
			id
		};
		//return DemoResponse?.dataset?.results.find(e => e?.dataset?.id == id); // My Code

		const response: any = await this.network.post_cat_api(
			'internal/ui/dataset',
			post_body
		);
		return response['results'][0];
	}

	//TODO: Refactor this function later when the API is ready
	async get_all_datasets(): Promise<any> {
		const response: any = await this.network.get_cat_api(
			'internal/ui/dataset'
		);
		return response;
	}

	//once api, filters args filters?: any
	async get_geoquery_resource_list(): Promise<any> {
		// const post_body: any = {
		// 	instance: this.global.get_active_instance(),
		// 	datasets: []
		// };
		const post_body: any = {
			id: this.global.get_dataset_filters(),
			instance: this.global.get_active_instance()
		};
		// if (filters) {
		// 	for (const key in post_body) {
		// 		if (filters[key]) {
		// 			post_body[key] = filters[key];
		// 		}
		// 	}
		// }
		return await this.network.post_cat_api(
			'internal/ui/geoquery',
			post_body
		);
	}

	async get_latest_data(
		id: any,
		accessPolicy: any,
		rsUrl: any,
		role: any
	): Promise<any> {
		try {
			let token: any;
			// const url = id.split('/')[2];
			if (accessPolicy === 'SECURE') {
				token = await this.auth.getSecuredToken(id, role);
			} else {
				//TODO: Need to check whether it's open or closed token
				token = await this.auth.getOpenToken('rs', rsUrl);
			}
			localStorage.setItem('iudx-ui-rs-token', token);
			if (token) {
				let headers: any;
				if (accessPolicy === 'SECURE') {
					headers = this.get_headers('auth', 'token');
				} else {
					headers = this.get_headers('res', 'public');
				}
				const data: any = await this.network.get_api(
					environment.resource_server_url +
					`ngsi-ld/v1/entities/${id}`,
					headers
				);
				return data;
			}
		} catch (e: any) {
			// this.subscriber.set_toaster('error', e.error?.detail);
			return e.status;
		}
	}

	async get_resource_map_data(
		resourceId: any,
		rsUrl: any,
		role: any
	): Promise<any> {
		const accessPolicy = this.global.get_single_resource()?.accessPolicy;

		let token: any;
		if (accessPolicy == 'SECURE') {
			//make second arg dynamic
			token = await this.auth.getSecuredToken(resourceId, role);
		} else {
			//TODO: Need to check whether it's open or closed token
			token = await this.auth.getOpenToken('rs', rsUrl);
		}
		localStorage.setItem('iudx-ui-rs-token', token);
		const data: any = await this.network.get_api(
			environment.resource_server_url +
			'ngsi-ld/v1/entities?id=' +
			resourceId +
			'&q=id==' +
			resourceId
		);
		return data;
	}

	async request_dataset(
		id: string,
		itemType: string,
		expiry: any
	): Promise<any> {
		const body = {
			request: [
				{
					itemId: id,
					itemType: itemType,
					expiryDuration: expiry,
					constraints: {
						access: ['api', 'sub', 'file', 'async']
					}
				}
			]
		};
		const header = this.get_headers('auth', 'public');
		const data: any = await this.network.post_api(
			environment.authorization_server_url + 'auth/v1/policies/requests',
			body,
			header
		);
		return data;
	}

	async get_user_profile(params?: any): Promise<any> {
		const header = this.get_headers('auth', '', params);

		return await this.network.get_api(
			environment.authorization_server_url + 'auth/v1/user/profile',
			header
		);
	}

	async get_new_user_profile(params?: any): Promise<any> {
		try {
			const header = this.get_headers('auth', '', params);
			const role: any = await this.network.get_api(
				environment.auth_server_url + 'auth/v1/user/roles',
				header
			);

			this.global.set_email(role?.results?.email);
			return role;
		} catch (e: any) {
			console.log(e);
			return;
		}
	}

	async get_special_data(data: any): Promise<any> {
		let newParams = `geoproperty=location&georel=${data.type}&geometry=${data.geometry}`;
		if (data.geometry == 'Point') {
			newParams += `&maxDistance=${JSON.stringify(data.radius)}`;
		}
		if (data.geometry == 'Polygon') {
			newParams += `&coordinates=${JSON.stringify([data.coordinates])}`;
		} else {
			newParams += `&coordinates=${JSON.stringify(data.coordinates)}`;
		}

		const header = this.get_headers('cat', '');

		return await this.network.get_api(
			environment.catalogue_server_url + `search?${newParams}`,
			header
		);
	}

	async get_access_token(body: any, isPublic: any, role?: any) {
		try {
			if (isPublic) {
				const isToken = await this.auth.get_res_public_token();
				if (isToken) {
					return await this.auth.get_res_token('public');
				} else {
					return null;
				}
			}
			let header = '';
			if (role === 'delegate') {
				header = this.get_headers('consumer_delegate_id');
			}
			const response: any = await this.network.post_api(
				`${environment.authorization_server_url}/auth/v1/token`,
				body,
				header,
				false
			);
			return response.results.accessToken;
		} catch (e: any) {
			this.subscriber.set_toaster('error', e.error?.detail);
		}
	}

	async doAsyncSearch(accessToken: string, query: string) {
		try {
			const userId = this.global.get_user_profile().userId;
			const headers = { token: accessToken, userid: userId };
			return await this.network.get_api(
				environment.resource_server_url +
				`ngsi-ld/v1/async/search?${query}`,
				headers,
				false
			);
		} catch (e: any) {
			this.subscriber.set_toaster('error', e.error.data.message);
		}
	}

	async getProgressStatus(accessToken: string, searchID: string) {
		try {
			const headers = { token: accessToken };
			const response: any = await this.network.get_api(
				`${environment.resource_server_url}ngsi-ld/v1/async/status?searchId=${searchID}`,
				headers,
				false
			);
			const namespace = response.data
				? response.data.results[0]
				: response.results[0];
			if (namespace.status === 'COMPLETE') {
				await this.network.get_api(
					`ngsi-ld/v1/async/status?searchId=${searchID}`,
					headers,
					false
				);
			}
			namespace['downloadUrl'] = namespace['file-download-url'];
			delete namespace['file-download-url'];
			return response;
		} catch (e: any) {
			this.subscriber.set_toaster('error', e);
		}
	}

	async get_average_rating(params: any): Promise<any> {
		return DemoResponse?.rating; // My Code
		const url = environment.catalogue_server_url.split('/')[2];
		const data: any = await this.network.get_api(
			`https://${url}/consumer/ratings?id=${params.id}&type=${params.type}`
		);
		return data;
	}

	async get_user_rating(params: any): Promise<any> {
		const cat_token = await this.auth.get_cat_public_token();
		if (cat_token) {
			const header = this.get_headers('rs', 'token');
			const url = environment.catalogue_server_url.split('/')[2];
			const data: any = await this.network.get_api(
				`https://${url}/consumer/ratings?id=${params.id}`,
				header,
				false
			);
			return data;
		}
	}

	async create_rating(rating: any, id: any): Promise<any> {
		try {
			const cat_token = await this.auth.get_cat_public_token();
			if (cat_token) {
				const header = this.get_headers('rs', 'token');
				const url = environment.base_path_cat_server_url;
				const body = {
					rating: rating,
					comment: 'good resource'
				};
				const data: any = await this.network.post_api(
					`${url}/consumer/ratings?id=${id}`,
					body,
					header
				);
				return data;
			}
		} catch (e: any) {
			this.subscriber.set_toaster('error', e.error?.detail);
			return e.status;
		}
	}
	async createSubscription(
		id: any,
		name: any,
		accessPolicy: any,
		rsUrl: any,
		role?: any
	): Promise<any> {
		try {
			let token;
			if (accessPolicy === 'SECURE') {
				//CHANGE TO DO
				token = await this.auth.getSecuredToken(id, role);
			} else {
				token = await this.auth.getOpenToken('rs', rsUrl);
			}
			localStorage.setItem('iudx-ui-rs-token', token);
			if (token) {
				const body = {
					name: name,
					type: 'subscription',
					entities: [id]
				};
				const data: any = await this.network.post_api(
					environment.resource_server_url + `ngsi-ld/v1/subscription`,
					body
				);
				return data;
			}
		} catch (e: any) {
			this.subscriber.set_toaster('error', e.error?.detail);
			return e.status;
		}
	}

	async downloadFile(id: string): Promise<any> {
		const token = await this.auth.getSubscriptionToken(id, 'resource');
		// const url = id.split('/')[2];
		if (token) {
			const header = this.get_headers('subscription', 'token');
			return this.network.get_api(
				environment.resource_server_url +
				`ogc/v1/collections/${id}/coverage?f=netcdf`,
				header
			);
		}
	}

	async get_cat_datasets(): Promise<any> {
		return await this.network.get_cat_api('internal/ui/dataset');
	}

	async get_instance_specific_cat_datasets(instance: any): Promise<any> {
		const post_body = {
			instance: instance
		};
		const response: any = await this.network.post_cat_api(
			'internal/ui/dataset',
			post_body
		);
		return response;
	}

	/* --------- MY CODE METHOD -------------- */
	async fetch_cat_datasets(filters: any): Promise<any> {
		let post_body = {
			instance: filters?.instance,
			providers: [...(filters?.providers?.length ? filters.providers : [])],
			id: filters?.id,
			domains: [...(filters?.domains?.length ? filters.domains.map((d: any) => "domain-".concat(d.toLowerCase())) : [])],
			tags: [
				...(filters?.tags?.length ? filters.tags : []),
				...(filters?.disease?.length ? filters.disease.map((d: any) => "disease-".concat(d)) : []),
				...(filters?.location?.length ? filters.location.map((d: any) => "locations-".concat(d)) : []),
				...(filters?.domains?.length ? filters.domains.map((d: any) => "subdomain-".concat(d)) : [])
			],
		};

		let res: any = await this.network.post_cat_api(
			'internal/ui/dataset',
			post_body
		)
		res.results = res?.results && res.results.filter((item: any) => item?.tags.includes("icmr"));
		return res;
	}
	/* --------- MY CODE - END -------------- */

	// async fetch_cat_datasets(post_body: any): Promise<any> {
	// 	return await this.network.post_cat_api(
	// 		'internal/ui/dataset',
	// 		post_body
	// 	);
	// }

	async get_call_count_summary() {
		const response: any = await this.network.get_cat_api(
			'internal/ui/summary'
		);
		return response.results;
	}
	async get_call_count_realtime() {
		const response: any = await this.network.get_cat_api(
			'internal/ui/realtimedataset'
		);
		return response.results;
	}

	async get_DX_Entity(id: string): Promise<any> {
		const response: any = await this.network.get_cat_api(
			'item?id=' + id
		);
		return response['results'][0];
	}

	async get_presigned_datasets_link(resId: string): Promise<any> {
		let url = 'dataset/get/' + resId
		let header = { Authorization: "Basic aWNtcjppY21ycGFzc3dk" } 	// Update with time

		// let res = {
		// 	"status": "success",
		// 	"results": [
		// 		{
		// 			"rid": "d330fc54-2a03-471e-868d-9815ce8cb6eb",
		// 			"outbreakId": "1115",
		// 			"files": [
		// 				{
		// 					"name": "result.zip",
		// 					"url": "https://healthxbucket.s3.ap-south-1.amazonaws.com/icmr/diseases/json/result.zip_aa75d0a6-59fa-41d9-875f-6de16e677afb.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAROAMFPEMZHQXAU5E%2F20240823%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240823T221901Z&X-Amz-Expires=120&X-Amz-SignedHeaders=host&X-Amz-Signature=1566f5d2de4e8002d84c6cf70059eb1f5c35fbc387c672b1208ceb438966eee1",
		// 					"createdAt": "2024-08-19T11:58:29.789446Z",
		// 					"updatedAt": "2024-08-19T11:58:29.789446Z"
		// 				}
		// 			],
		// 			"createdAt": "2024-08-19T11:58:29.788906Z",
		// 			"updatedAt": "2024-08-19T11:58:29.788906Z",
		// 			"rowsAffected": 1
		// 		}
		// 	],
		// 	"total": 1
		// }

		// return res;

		return await this.network.get_gsf_api(url, header);
	}
}
