<div class="overlay"></div>
<div class="popup">
	<div class="title">
		<h1>Available Resource Servers</h1>
		<img (click)="close()" src="../../../assets/close.png" />
	</div>
	<div class="content-container">
		<div class="search d-flex justify-content-end">
			<input
				(keyup)="search($event)"
				placeholder="Search"
				aria-label="Search"
			/>
			<i class="fas fa-search magnifying-glass"></i>
		</div>
		<div *ngFor="let rsUrl of rsList" class="content">
			<div class="content-box">
				<div class="title-div">
					<h2>{{rsUrl}}</h2>
				</div>
			</div>
		</div>
		<div class="content" *ngIf="rsList.length===0">
			<div class="content-box">
				<div class="title-div">
					<h2>
						Current search didn't match the existing resource server
						url's
					</h2>
				</div>
			</div>
		</div>
	</div>
</div>
