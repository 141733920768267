import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { circle, latLng, tileLayer } from 'leaflet';
import { Map } from 'leaflet';
import { SubscriberService } from '../../services/subscriber.service';
import L from 'leaflet';

@Component({
	selector: 'app-location-picker-modal',
	templateUrl: './location-picker-modal.component.html',
	styleUrls: ['./location-picker-modal.component.scss']
})
export class LocationPickerModalComponent implements OnInit {
	@Input() formData: any;

	map: Map | undefined;
	options = {
		layers: [
			tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 18,
				attribution: 'Angular Leaflet'
			})
		],
		zoom: 5,
		center: latLng(20.5937, 78.9629)
	};

	layers = [
		circle([20.5937, 78.9629], { radius: 25000 })
		// marker([20.5937, 78.9629])
	];

	latitude = 20.5937;
	longitude = 78.9629;

	circleLayerGroup = L.layerGroup();

	constructor(
		private activeModal: NgbActiveModal,
		private subscriber: SubscriberService,
		private cdr: ChangeDetectorRef
	) {
		const latlng = JSON.parse(localStorage.getItem('latlng') || '{}');
		this.latitude = latlng.lat;
		this.longitude = latlng.lng;
	}

	ngOnInit(): void {}

	onMapReady(map: Map) {
		this.map = map;
		this.map.addLayer(this.circleLayerGroup);
		const newCircle = circle([20.5937, 78.9629], {
			radius: 25000
		});
		this.circleLayerGroup.addLayer(newCircle);
		this.map.on('click', this.onMapClick.bind(this));
	}

	onMapClick(event: any) {
		const latlng = event.latlng;
		localStorage.setItem('latlng', JSON.stringify(latlng));
		this.latitude = latlng.lat;
		this.longitude = latlng.lng;
		this.circleLayerGroup.clearLayers();
		const newCircle = circle([this.latitude, this.longitude], {
			radius: 25000
		});
		this.circleLayerGroup.addLayer(newCircle);
		this.subscriber.set_location_coordinates(latlng);
		this.cdr.detectChanges();
	}

	close(): void {
		this.activeModal.dismiss('Submitted');
	}
}
