<div class="overlay"></div>
<div class="popup">
	<div class="title">
		<h1>Delete Consumer Policy</h1>
		<img (click)="close()" src="../../../assets/close.png" />
	</div>
	<div class="info">
		<div class="name">
			<span>Name</span>
			<p>
				{{consumer_details &&
				consumer_details?.consumer?.name?.firstName}} {{consumer_details
				&& consumer_details?.consumer?.name?.lastName}}
			</p>
		</div>
		<div class="email">
			<span>{{policy?.resourceLabel}}</span>
			<p>{{consumer_details && consumer_details?.itemId}}</p>
		</div>
	</div>
	<div class="link d-flex justify-content-around">
		<a (click)="close()" class="box text-white">Cancel</a>
		<a (click)="deletePolicy()" class="box text-white">Delete</a>
	</div>
</div>
