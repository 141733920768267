// export const environment = {
// 	production: false,

// 	catalogue_server_url: 'https://api.cat-test.iudx.io/iudx/cat/v1/',
// 	base_path_cat_server_url: 'https://api.cat-test.iudx.io/',
// 	resource_server_url: 'https://rs.iudx.io/',
// 	auth_server_url: 'https://authvertx.iudx.io/',
// 	authorization_server_url: 'https://authvertx.iudx.io/',
// 	acl_apd_server_url: 'https://acl-apd.iudx.io/dx/apd/acl/v1/',
// 	base_path_acl_apd_server_url: 'https://acl-apd.iudx.io/',
// 	onboarding_server_url: 'https://onboarding.iudx.io/iudx/cat/v1/',
// 	dmp_server_url: 'https://dmp-apd.iudx.io/',
// 	golang_file_server_url: "https://adarv.in/icmr/",

// 	// User for Rating
// 	rs_item: 'rs.iudx.io',
// 	cos_item: 'cos.iudx.io',
// 	uac_url: 'https://central-catalogue.iudx.org.in/',

// 	//web urls
// 	parent_domain: 'localhost',
// 	catalogue_web: 'http://localhost:4200/',
// 	consumer_web: 'http://localhost:4200/consumer',
// 	provider_web: 'http://localhost:4200/provider',
// 	auth_web: 'http://localhost:4200/auth',
// 	// auth_web: 'https://adarv.in/auth',
// 	cos_web: 'http://localhost:4200/cop-admin',
// 	admin_web: 'http://localhost:4200/rs-admin',
// 	//api urls

// 	//for provider panel
// 	role_urls: {
// 		authorization_server_url: 'authvertx.iudx.io',
// 		resource_server_url: 'rs.iudx.io',
// 		catalogue_server_url: 'cat-test.iudx.io'
// 	},
// 	//keycloak config
// 	keycloak: {
// 		url: 'https://keycloak-update.iudx.io/auth/',
// 		realm: 'demo',
// 		clientId: 'angular-iudx-client'
// 	},
// 	//docs urls
// 	documentation_url: 'https://docs.iudx.org.in/',
// 	catalogue_api_documentation_url: 'https://cos.iudx.org.in/cat/apis',
// 	resource_api_documentation_url: 'https://rs.iudx.org.in/apis',
// 	authorization_api_documentation_url:
// 		'https://authorization.iudx.org.in/apis',
// 	//other panel urls
// 	forum_url: 'https://forum.iudx.org.in/',
// 	sandbox_url: 'https://sandbox.iudx.org.in/',
// 	//identity token itemids

// 	//project level constants
// 	terms_and_conditions_link:
// 		'https://iudx-catalogue-assets.s3.ap-south-1.amazonaws.com/templatepdf.pdf',

// 	//razorypay
// 	razorpay_key: 'rzp_test_z1kURKwkRYz3PC'
// };


export const environment = {
   production  : false,
   catalogue_server_url: 'https://api.cat-test.iudx.io/iudx/cat/v1/',
    base_path_cat_server_url: 'https://api.cat-test.iudx.io/',
    resource_server_url: 'https://rs.iudx.io/',
    auth_server_url: 'https://authvertx.iudx.io/',
    authorization_server_url: 'https://authvertx.iudx.io/',
    acl_apd_server_url: 'https://acl-apd.iudx.io/dx/apd/acl/v1/',
    base_path_acl_apd_server_url: 'https://acl-apd.iudx.io/',
    onboarding_server_url: 'https://onboarding.iudx.io/iudx/cat/v1/',
    dmp_server_url: 'https://dmp-apd.iudx.io/',

    golang_file_server_url: "https://adarv.in/icmr/",

    // User for Rating
    rs_item: 'rs.iudx.io',
    cos_item: 'cos.iudx.io',
    uac_url: 'https://central-catalogue.iudx.org.in/',

    //web urls
    parent_domain: 'adarv.in',
    catalogue_web: 'http://adarv.in/',
    consumer_web: 'http://adarv.in/consumer',
    provider_web: 'http://adarv.in/provider',
    auth_web: 'http://adarv.in/auth',
    cos_web: 'http://adarv.in/cop-admin',
    admin_web: 'http://adarv.in/rs-admin',
    //api urls

    //for provider panel
    role_urls: {
        authorization_server_url: 'authvertx.iudx.io',
        resource_server_url: 'rs.iudx.io',
        catalogue_server_url: 'cat-test.iudx.io'
    },
    //keycloak config
    keycloak: {
        url: 'https://keycloak-update.iudx.io/auth/',
        realm: 'demo',
        clientId: 'angular-iudx-client'
    },
    //docs urls
    documentation_url: 'https://docs.iudx.org.in/',
    catalogue_api_documentation_url: 'https://cos.iudx.org.in/cat/apis',
    resource_api_documentation_url: 'https://rs.iudx.org.in/apis',
    authorization_api_documentation_url:
        'https://authorization.iudx.org.in/apis',
    //other panel urls
    forum_url: 'https://forum.iudx.org.in/',
    sandbox_url: 'https://sandbox.iudx.org.in/',
    //identity token itemids

    //project level constants
    terms_and_conditions_link:
        'https://iudx-catalogue-assets.s3.ap-south-1.amazonaws.com/templatepdf.pdf',

    //razorypay
    razorpay_key: 'rzp_test_z1kURKwkRYz3PC'
}
