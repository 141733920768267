<div class="modal">
	<div id="container">
		<svg viewBox="0 0 100 100">
			<defs>
				<filter id="shadow">
					<feDropShadow
						dx="0"
						dy="0"
						stdDeviation="1.5"
						flood-color="#fc6767"
					/>
				</filter>
			</defs>
			<circle
				id="spinner"
				style="
					fill: transparent;
					stroke: #fff;
					stroke-width: 7px;
					stroke-linecap: round;
				"
				cx="50"
				cy="50"
				r="45"
			/>
		</svg>
		<span>loading..</span>
	</div>
</div>
