import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PopupMarkerService {
	constructor() {}
	leftletBindPopup(data: any): string {
		return (
			`` +
			`<div style='font-size:14px; color:#042f5b; font-weight: 600'>Ref-Id: ${data}</div>`
		);
	}
}
