consumer
<div class="overlay"></div>
<div class="popup">
	<div class="title">
		<h1>Reject request for dataset</h1>
		<img (click)="closeRejectRequest()" src="../../../assets/close.png" />
	</div>
	<div class="info">
		<div class="name">
			<span class="fw-bold">Name</span>
			<p>
				{{user_details && user_details.consumer.name.firstName}}
				{{user_details && user_details.consumer.name.lastName}}
			</p>
		</div>
		<div class="email">
			<span class="fw-bold">Email</span>
			<p>{{user_details && user_details.consumer.email}}</p>
		</div>
	</div>
	<h3 class="secondary-title">Datasets</h3>
	<p class="desc">{{user_details && user_details?.resourceLabel}}</p>
	<div class="link d-flex justify-content-around">
		<a (click)="closeRejectRequest()" class="box text-white">Cancel</a>
		<a (click)="reject_request()" class="box text-white">Reject</a>
	</div>
</div>
