import { Component, OnInit } from '@angular/core';
import { IntroJsService } from '../../services/intro-js.service';
import { SubscriberService } from '../../services/subscriber.service';
import { GlobalService as GlobalCatService } from 'src/app/catalogue/shared/services/global.service';

@Component({
	selector: 'app-provider-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	user: any = {};
	title: any;
	is_provider: any;
	countOfDelegations: any;
	defaultServer: any;
	owner_name: string | undefined;
	provider_delegate_update: any;
	countOfUrls: any;
	userProfile: any;

	constructor(
		public globalCat: GlobalCatService,
		public introjs: IntroJsService,
		private subscriber: SubscriberService
	) {
		this.userProfile = this.globalCat.get_user_profile();
		this.is_provider =
			this.userProfile.roles?.includes('provider') &&
			!(localStorage.getItem('isProviderDelegate') === 'true');
		this.provider_delegate_update = this.subscriber
			.get_provider_delegate()
			.subscribe(async () => {
				this.set_default_provider();
			});
		this.set_default_provider();
	}

	async ngOnInit() {
		if (this.is_provider) {
			this.countOfUrls =
				this.userProfile?.rolesToRsMapping?.provider.length;
		} else {
			const filteredProviderDelegates =
				this.globalCat.getFilteredProviderDelegates();
			this.countOfUrls = filteredProviderDelegates.length;
		}
		this.set_default_provider();
		this.subscriber.getTitle().subscribe((title) => {
			this.title = title;
		});
	}

	ngOnDestroy(): any {
		this.provider_delegate_update.unsubscribe();
	}

	set_default_provider(): any {
		const provider_owner_Details_string = localStorage.getItem(
			'provider_owner_Details'
		);
		if (provider_owner_Details_string) {
			const provider_owner_Details: any = JSON.parse(
				provider_owner_Details_string
			);
			this.defaultServer = provider_owner_Details.url;
			this.owner_name = this.is_provider
				? ''
				: provider_owner_Details?.name?.firstName +
				  ' ' +
				  provider_owner_Details?.name?.lastName;
		}
	}

	openTour(): void {
		this.introjs.homeTour();
	}

	//activate change provider popup provider-delegate
	chooseProvider(): void {
		this.subscriber.set_popup(true, 'choose-provider');
	}
}
