import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SubscriberService {
	location_picker_subject = new Subject<any>();
	toast_subject = new Subject<any>();
	constructor() {}

	set_location_coordinates(coordinates: string): void {
		this.location_picker_subject.next({ coordinates });
	}

	get_location_coordinates(): Observable<any> {
		return this.location_picker_subject.asObservable();
	}
	
	set_toaster(type: string, message: string): void {
		this.toast_subject.next({ type, message });
	}

	get_toaster(): Observable<any> {
		return this.toast_subject.asObservable();
	}
}
