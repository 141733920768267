//angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//other libraries
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

//components
import { LoaderComponent } from './loader/loader.component';
import { ToasterComponent } from './toaster/toaster.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RolesModalComponent } from './roles-modal/roles-modal.component';
import { RegisterComponent } from './register/register.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvailableRsComponent } from './popups/available-rs/available-rs.component';

@NgModule({
	declarations: [
		LoaderComponent,
		ToasterComponent,
		CheckboxComponent,
		RolesModalComponent,
		RegisterComponent,
		AvailableRsComponent
	],
	imports: [
		CommonModule,
		NgMultiSelectDropDownModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule
	],
	exports: [
		LoaderComponent,
		ToasterComponent,
		CheckboxComponent,
		AvailableRsComponent
	]
})
export class ComponentsModule {}
