import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { GlobalService as Globalcat } from 'src/app/catalogue/shared/services/global.service';

import { SubscriberService } from '../../../services/subscriber.service';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
	selector: 'app-change-provider',
	templateUrl: './change-provider.component.html',
	styleUrls: ['./change-provider.component.scss']
})
export class ChangeProviderComponent implements OnInit {
	providers_list: any = [];
	is_provider: any;
	user_profile: any;
	resourceServers: any;
	providers_list_copy: any = [];

	constructor(
		public global: GlobalService,
		public global_cat: Globalcat,
		public shared_api: ApiService,
		public subscriber: SubscriberService
	) {
		this.providers_list = [];
		this.user_profile = this.global_cat.get_user_profile();
		this.is_provider =
			this.user_profile.roles?.includes('provider') &&
			!(localStorage.getItem('isProviderDelegate') === 'true');
	}

	async ngOnInit(): Promise<void> {
		if (this.is_provider) {
			this.user_profile?.rolesToRsMapping?.provider.forEach(
				(element: any) => {
					this.providers_list.push({
						rs_url: element
					});
				}
			);
			this.providers_list.sort((a: any, b: any) =>
				a.rs_url > b.rs_url ? 1 : -1
			);
			this.providers_list_copy = this.providers_list;
		} else {
			const delegates = this.global_cat.getFilteredProviderDelegates();
			delegates?.forEach((element: any) => {
				if (element.role == 'provider') {
					this.providers_list.push({
						name: {
							firstName: element.owner.name.firstName,
							lastName: element.owner.name.lastName
						},
						email: element.owner.email,
						rs_url: element.url,
						id: element.id,
						owner_id: element.owner.id
					});
				}
			});
		}
	}

	close(): void {
		const provider_owner_Details_string = localStorage.getItem(
			'provider_owner_Details'
		);
		if (provider_owner_Details_string) {
			this.subscriber.set_popup(false, 'choose-provider');
		} else {
			const message = this.is_provider
				? 'please select resource server'
				: 'please select resource server and owner';
			this.subscriber.set_toaster('error', message);
		}
	}

	// get values of owner
	getValues(owner: any): void {
		if (!this.is_provider) {
			localStorage.setItem('provider_delegate_id', owner.id);
		}
		//for provider delegat we need id and other details
		this.global.set_provider_delegate_details(
			owner.owner_id,
			owner.rs_url,
			owner.email,
			owner.name
		);
		localStorage.setItem(
			'provider_owner_Details',
			JSON.stringify(this.global.get_provider_delegate_details())
		);
		this.subscriber.set_provider_delegate(owner);

		this.subscriber.set_popup(false, 'provider');
	}

	search(event: any) {
		this.providers_list = this.providers_list_copy.filter((item: any) => {
			return item.rs_url.includes(event.target.value);
		});
	}
}
