<div class="overlay"></div>
<div class="popup">
	<div class="title">
		<h1>Choose {{is_provider? "Resource Url":"Provider"}}</h1>
		<img (click)="close()" src="../../../assets/close.png" />
	</div>
	<div class="search d-flex justify-content-end mb-5 mx-5">
		<input
			(keyup)="search($event)"
			placeholder="Search"
			aria-label="Search"
		/>
		<i class="fas fa-search magnifying-glass"></i>
	</div>
	<div class="content-container">
		<table>
			<tr *ngFor="let provider of providers_list" class="content">
				<td width="55%">
					<div class="content-box" *ngIf="!is_provider">
						<span class="name name-sub">
							{{provider &&
							provider.name.firstName.charAt(0)}}{{provider &&
							provider.name.lastName.charAt(0)}}
						</span>
						<div class="title-div">
							<h2>
								{{provider.name.firstName}}
								{{provider.name.lastName}}
							</h2>
							<p>{{provider.email}}</p>
						</div>
					</div>
				</td>
				<td width="35%">
					<div>{{provider.rs_url}}</div>
				</td>
				<td width="10%">
					<div class="link">
						<a (click)="getValues(provider)">Choose</a>
					</div>
				</td>
			</tr>
		</table>
		<div class="content" *ngIf="providers_list.length===0">
			<div class="content-box">
				<div class="title-div">
					<h2>
						Current search didn't match the existing resource server
						url's
					</h2>
				</div>
			</div>
		</div>
	</div>
</div>
