import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GlobalService } from '../../../services/global.service';
import { SubscriberService } from '../../../services/subscriber.service';
import { ApiService as apiParent } from 'src/app/shared/services/api.service';
import { GlobalService as GlobalCatService } from 'src/app/catalogue/shared/services/global.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-view-request',
	templateUrl: './view-request.component.html',
	styleUrls: ['./view-request.component.scss']
})
export class ViewRequestComponent implements OnInit {
	user_details: any;
	year = 0;
	month = 0;
	day = 0;
	is_provider: any;
	accessArr: any = [];
	selectedResourceServer: any;

	constructor(
		public global: GlobalService,
		public global_cat: GlobalCatService,

		public subscriber: SubscriberService,
		public api: ApiService,
		private apiParent: apiParent
	) {
		this.user_details = this.global.get_temp_data();
		const user_profile = this.global_cat.get_user_profile();
		if (user_profile?.roles?.includes('provider')) {
			this.is_provider = true;
		} else {
			this.is_provider = false;
		}
		this.selectedResourceServer = environment.rs_item;
	}

	ngOnInit(): void {}

	isChecked(access: any) {
		if (access === 'sub' || access === 'subs') {
			return this.accessArr.indexOf(access) !== -1
				? this.accessArr.indexOf(access)
				: this.accessArr.indexOf(access + 's');
		}
		return this.accessArr.indexOf(access);
	}

	setExpiry() {
		const currentDate = new Date();
		const expiryDate = this.addTimeToDate(
			currentDate,
			this.year,
			this.month,
			this.day
		);
		const formattedString = this.formatISOString(expiryDate.toISOString());
		return formattedString;
	}

	addTimeToDate(
		date: Date,
		years: number,
		months: number,
		days: number
	): any {
		//handle no expiryDate set case
		if (
			(this.year === 0 && this.month === 0 && this.day === 0) ||
			(this.year === null && this.month === null && this.day === null)
		) {
			return this.subscriber.set_toaster(
				'error',
				'Please chooose expiry duration'
			);
		} else if (this.year < 0 || this.month < 0 || this.day < 0) {
			return this.subscriber.set_toaster('error', 'Invalid input');
		} else {
			const result = new Date(date);
			result.setFullYear(result.getFullYear() + years);
			result.setMonth(result.getMonth() + months);
			result.setDate(result.getDate() + days);
			return result;
		}
	}

	formatISOString(isoString: any) {
		// Split the ISO string to remove milliseconds and "Z" character
		const parts = isoString.split('.');
		if (parts.length > 0) {
			return parts[0];
		}
		return isoString; // Return the original string if there are no milliseconds
	}

	async approve(): Promise<void> {
		//check below edge case
		if (this.accessArr.length == 0)
			return this.subscriber.set_toaster(
				'error',
				'Please select atleast 1 access.'
			);
		const expiryDate = this.setExpiry();
		if (!expiryDate || expiryDate === null) {
			return this.subscriber.set_toaster(
				'error',
				'Please select expiry duration.'
			);
		}
		const body = {
			requestId: this.user_details.requestId,
			status: 'granted',
			expiryAt: expiryDate,
			constraints: {
				access: this.accessArr
			}
		};

		try {
			//call token api for id token
			// const post_body = {
			// 	itemId: 'rs.iudx.io', // TO DO: make it dynamic later
			// 	itemType: 'resource_server',
			// 	role: 'provider'
			// };
			// const accessToken = await this.apiParent.get_id_token(post_body);
			let body_token;
			let header;
			if (this.is_provider) {
				body_token = {
					itemId: this.selectedResourceServer,
					itemType: 'resource_server',
					role: 'provider'
				};
			} else {
				body_token = {
					itemId: this.selectedResourceServer,
					itemType: 'resource_server',
					role: 'delegate'
				};
				header = 'delegate_id';
			}
			const accessToken = await this.apiParent.get_id_token(
				body_token,
				header
			);
			const data: any = await this.api.update_request(accessToken, body);
			this.subscriber.set_toaster('success', data.title);
			this.closeViewRequest(true);
			this.subscriber.set_temp_data('acl request update successful');
		} catch (error: any) {
			this.subscriber.set_toaster('error', error.error.title);
		}
	}

	closeViewRequest(isChanged?: boolean): void {
		this.subscriber.set_popup(false, 'view-request', !!isChanged);
	}

	toggleAccess(access: any) {
		if (!this.accessArr.includes(access)) {
			this.accessArr.push(access);
		} else {
			const newAccess = this.accessArr.filter(
				(item: any) => item !== access
			);
			this.accessArr = newAccess;
		}
	}
}
