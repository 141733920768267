<div class="w-100">
	<nav class="text-start">
		<div>
			<i
				class="fa-solid fa-bars navbar-toggle-icon fa-lg mt-5"
				(click)="handleSidebarToggle()"
				*ngIf="navbarExpanded"
			></i>
			<i
				class="fa-solid fa-arrow-right navbar-toggle-icon fa-xl position-contracted mt-5"
				(click)="handleSidebarToggle()"
				*ngIf="!navbarExpanded"
			></i>
		</div>
		<div class="logo">
			<a (click)="redirectHome()">
				<img
					class="mt-5"
					*ngIf="navbarExpanded"
					src="../../../assets/iudx_full_logo.png"
				/>
				<img
					*ngIf="!navbarExpanded"
					src="../../../assets/logo.svg"
					class="mobile-logo mt-5"
				/>
			</a>
		</div>

		<ul>
			<li
				class="hover-menu-item py-4 bg-light"
				[ngClass]="{'hover-expand':navbarExpanded,'hover-contract': !navbarExpanded} "
				(mouseenter)="toggleHover('home')"
				(mouseleave)="toggleHover('home')"
			>
				<a
					routerLink="/provider"
					[routerLinkActiveOptions]="{exact: true}"
					routerLinkActive="active"
				>
					<div class="d-flex home">
						<div class="tab-icon tab-icon-overview me-4"></div>
						<span
							*ngIf="navbarExpanded || hoveredOn.home"
							[ngClass]="hoveredOn.home && !navbarExpanded?'me-2':''"
							>Home</span
						>
					</div>
				</a>
			</li>
			<li [ngClass]="[navbarExpanded?'':'d-none']">
				<div
					class="accordion accordian-container mt-4"
					id="datasetsAccordion"
				>
					<div class="content pb-3">
						<div class="accordion-item border-0">
							<h2 class="accordion-header" id="headingOne">
								<button
									class="accordion-button collapsed py-2 border-0"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapse2"
									aria-expanded="false"
									aria-controls="collapse2"
									#accordionButtonTwo
								>
									<div
										class="tab-icon tab-icon-dataset me-4"
									></div>

									<span class="me-3">ACL APD</span>
								</button>
							</h2>
							<div
								id="collapse2"
								class="accordion-collapse collapse"
								aria-labelledby="headingOne"
								[ngClass]="showDatasetdetails?'show':''"
								#accordionElementTwo
							>
								<div class="accordion-body p-0">
									<ul class="m-0 ps-5 border-0">
										<li
											routerLink="/provider/requests"
											class="d-flex px-3 ps-2 pt-4 pb-3"
											routerLinkActive="active"
										>
											<span *ngIf="navbarExpanded"
												>- &nbsp; My Resources</span
											>
										</li>

										<li
											routerLink="/provider/policies"
											class="d-flex px-3 ps-2 pt-4 pb-3"
											routerLinkActive="active"
										>
											<span *ngIf="navbarExpanded"
												>- &nbsp; Policies</span
											>
										</li>
										<li
											routerLink="/provider/data-adapter"
											class="d-flex px-3 ps-2 pt-4 pb-3"
											routerLinkActive="active"
											*ngIf="  overviewData?.categories?.length>0"
										>
											<span *ngIf="navbarExpanded"
												>- &nbsp; Data-Adapter</span
											>
										</li>
										<li
											routerLink="/provider/auditing-report"
											class="d-flex px-3 ps-2 pt-4 pb-3"
											routerLinkActive="active"
											*ngIf="overviewData?.categories?.length>0"
										>
											<span *ngIf="navbarExpanded"
												>- &nbsp; Auditing-Report</span
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</li>
			<li
				class="hover-menu-item py-4 bg-light"
				[ngClass]="{'hover-expand':navbarExpanded,'hover-contract': !navbarExpanded} "
				(mouseenter)="toggleHover('datasets')"
				(mouseleave)="toggleHover('datasets')"
			>
				<a
					routerLink="/provider/datasets"
					[routerLinkActiveOptions]="{exact: true}"
					routerLinkActive="active"
				>
					<div class="d-flex home">
						<div class="tab-icon tab-icon-dataset me-4"></div>
						<span
							*ngIf="navbarExpanded || hoveredOn.datasets"
							[ngClass]="hoveredOn.datasets && !navbarExpanded?'me-2':''"
							>Datasets</span
						>
					</div>
				</a>
			</li>
			<li
				class="hover-menu-item py-4 bg-light"
				[ngClass]="{'hover-expand':navbarExpanded,'hover-contract': !navbarExpanded} "
				(mouseenter)="toggleHover('delegates')"
				(mouseleave)="toggleHover('delegates')"
				*ngIf="is_provider"
			>
				<a
					routerLink="/provider/delegates"
					[routerLinkActiveOptions]="{exact: true}"
					routerLinkActive="active"
				>
					<div class="d-flex home">
						<div class="tab-icon tab-icon-dataset me-4"></div>
						<span
							*ngIf="navbarExpanded || hoveredOn.delegates"
							[ngClass]="hoveredOn.delegates && !navbarExpanded?'me-2':''"
							>Delegates</span
						>
					</div>
				</a>
			</li>
			<li
				routerLinkActive="bg-light  "
				ngbDropdown
				#myDrop="ngbDropdown"
				id="dropdown"
				class="d-flex align-items-center justify-content-between custom-dropdown mt-lg-5"
				ngbDropdownAnchor
				(click)="myDrop.open()"
				(mouseenter)="myDrop.open()"
				(mouseleave)="myDrop.close()"
				*ngIf="!navbarExpanded"
			>
				<a class="d-flex">
					<div class="d-flex">
						<div class="tab-icon tab-icon-dataset"></div>
					</div>
					<ul ngbDropdownMenu class="m-0 p-0">
						<i class="arrow-left"></i>
						<li
							ngbDropdownItem
							routerLink="/provider/requests"
							routerLinkActive="active"
						>
							<span>My Resources</span>
						</li>
						<li
							ngbDropdownItem
							routerLink="/provider/policies"
							routerLinkActive="active"
						>
							<span>Policies</span>
						</li>
						<li
							ngbDropdownItem
							routerLink="/provider/data-adapter"
							routerLinkActive="active"
						>
							<span>Data-Adapter</span>
						</li>
						<li
							ngbDropdownItem
							routerLink="/provider/auditing-report"
							routerLinkActive="active"
						>
							<span> Auditing-Report</span>
						</li>
					</ul>
				</a>
			</li>
		</ul>
	</nav>
</div>
