<div class="overlay"></div>
<div class="popup">
	<div class="title">
		<h1>Add New Delegate</h1>
		<img (click)="closeCreatePolicy()" src="../../../assets/close.png" />
	</div>
	<form [formGroup]="delegateForm" class="content">
		<label class="select-label"
			>Email<i class="fa-solid fa-star-of-life text-danger"></i
		></label>
		<input
			class="input"
			type="email"
			placeholder="Email"
			formControlName="email"
		/>
		<div
			*ngIf="delegateForm.get('email')?.hasError('required') && delegateForm.get('email')?.touched"
			class="error-message text-danger"
		>
			Email field is required.
		</div>
		<div class="my-lg-4">
			<label class="select-label"
				>Resource Server URL<i
					class="fa-solid fa-star-of-life text-danger"
				></i
			></label>
			<ng-multiselect-dropdown
				class="radio-btn-single-select"
				name="resourceServer"
				[data]="resourceServers"
				[(ngModel)]="selectedItem"
				[ngModelOptions]="{standalone: true}"
				[settings]="dropdownSettings"
				(onSelect)="onItemSelect($event)"
				[disabled]="disabled"
			></ng-multiselect-dropdown>
			<span class="text-danger" *ngIf="!isResourceServerSelected"
				>Resource Server is required</span
			>
		</div>
	</form>
	<button class="mx-0 w-50 d-flex justify-content-start pb-lg-3">
		<span class="box w-100" (click)="submit()"> Create New Delegate </span>
	</button>
</div>
