import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () =>
			import('./catalogue/modules/home/home.module').then(
				(m) => m.HomeModule
			)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
