<nav aria-label="breadcrumb">
	<ol class="breadcrumb">
		<li class="breadcrumb-item" routerLink="/">
			<img
				class="home-icon"
				(click)="setTitle('Home')"
				src="assets/home.svg"
			/>
		</li>
		<li
			class="breadcrumb-item"
			[ngClass]="linksObjects.length === (i+1)? 'active':''"
			*ngFor="let link of linksObjects; let i = index"
		>
			<a
				href="#"
				routerLink="/{{link.linkRoute}}"
				*ngIf="!(linksObjects.length === (i+1))"
				>{{link.linkName | titlecase}}</a
			>
			<span *ngIf="(linksObjects.length === (i+1))"
				>{{link.linkName | titlecase}}</span
			>
		</li>
	</ol>
</nav>
