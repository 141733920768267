import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StatusTagComponent } from './status-tag/status-tag.component';
import { TabsComponent } from './tabs/tabs.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ChangeProviderComponent } from './popup/change-provider/change-provider.component';
import { ConsumerPolicyDeleteComponent } from './popup/consumer-policy-delete/consumer-policy-delete.component';
import { RequestRejectComponent } from './popup/request-reject/request-reject.component';
import { ViewRequestComponent } from './popup/view-request/view-request.component';
import { DelegatePolicyCreateComponent } from './popup/delegate-policy-create/delegate-policy-create.component';
import { ConsumerPolicyCreateComponent } from './popup/consumer-policy-create/consumer-policy-create.component';
import { LoginComponent } from './login/login.component';

import { ComponentsModule as parentComponentsModule } from 'src/app/shared/components/components.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CreateLinkedAccountComponent } from '../../modules/data-market-place/create-linked-account/create-linked-account.component';
import { AddTagsComponent } from './add-tags/add-tags.component';

@NgModule({
	declarations: [
		BreadcrumbsComponent,
		ChangeProviderComponent,
		ConsumerPolicyCreateComponent,
		ConsumerPolicyDeleteComponent,
		DelegatePolicyCreateComponent,
		HeaderComponent,
		ProfileComponent,
		RequestRejectComponent,
		SidebarComponent,
		StatusTagComponent,
		TabsComponent,
		ViewRequestComponent,
		BreadcrumbsComponent,
		LoginComponent,
		CreateLinkedAccountComponent,
		AddTagsComponent
	],
	imports: [
		CommonModule,
		NgbDropdownModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		parentComponentsModule,
		NgMultiSelectDropDownModule.forRoot()
	],
	exports: [
		BreadcrumbsComponent,
		ChangeProviderComponent,
		ConsumerPolicyCreateComponent,
		ConsumerPolicyDeleteComponent,
		DelegatePolicyCreateComponent,
		HeaderComponent,
		ProfileComponent,
		RequestRejectComponent,
		SidebarComponent,
		StatusTagComponent,
		TabsComponent,
		ViewRequestComponent,
		LoginComponent,
		AddTagsComponent
	]
})
export class ComponentsModule {}
