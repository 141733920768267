<div class="tag-input-container w-100">
	<div class="input-group d-flex justify-content-center align-items-center">
		<input
			type="text"
			class="form-control"
			placeholder="Type a tag and hit enter"
			[(ngModel)]="tagText"
			(keydown.enter)="addTag()"
		/>
		<div class="input-group-append er">
			<div class="add-btn ms-3" (click)="addTag()">
				<i class="fa-solid fa-plus me-2"></i>Add Tag
			</div>
		</div>
	</div>

	<div class="tag-chips mt-3">
		<div *ngFor="let tag of tags" class="tag-chip">
			{{ tag }}
			<i class="fa-solid fa-xmark" (click)="removeTag(tag)"></i>
		</div>
	</div>
</div>
