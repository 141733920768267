import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
/* eslint @typescript-eslint/no-var-requires: "off" */

declare let require: any;
const IntroJs = require('../../../../../node_modules/intro.js/intro.js');

@Injectable({
	providedIn: 'root'
})
export class IntroJsService {
	intro: any;
	constructor(public global: GlobalService) {}
	homeTour(): any {
		this.intro = IntroJs();
		const steps = this.global.get_introjs_steps_home();
		const steps_arr = [
			{
				intro: 'Welcome to IUDX Provider panel'
			},
			{
				element: steps[1],
				intro: 'You can find the summary of your datasets here'
			},
			{
				element: steps[2],
				intro: 'You can find pending requests here'
			},
			{
				element: steps[3],
				intro: 'You can find all pending requests info here',
				position: 'right'
			},
			{
				element: steps[4],
				intro: 'You can find all datasets detail here',
				position: 'right'
			},
			{
				element: steps[5],
				intro: 'You can find about policies and add policies here',
				position: 'right'
			},
			{
				element: steps[6],
				intro: 'You can find about deligates and add deligates here',
				position: 'right'
			},
			{
				intro: "<h1 style='color:green; text-align:left; padding-right:20px'>Congratulations!</h1></br><p style='text-align:left;'>Your demo is finished</br>Click Demo button to view, demo again</p>"
			}
		];
		this.intro
			.setOptions({
				tooltipClass: 'custom-tooltip',
				disableInteraction: true,
				exitOnOverlayClick: false,
				scrollTo: 'tooltip',
				showStepNumbers: true,

				steps: steps_arr
			})
			.start();
	}
}
