<div class="head p-3">
	<div class="header-title ms-3">
		<ng-container *ngIf="!title || title==='Home' && !is_provider">
			<h2>Welcome to Provider Delegate Panel</h2>
		</ng-container>
		<ng-container *ngIf="!title || title==='Home' && is_provider">
			<h2>
				Welcome to Provider Panel
				<!-- <span>{{user.name.firstName && user.name.lastName}}</span> -->
			</h2>
		</ng-container>
		<ng-container *ngIf="title!=='Home'">
			<h3>{{ title }}</h3>
		</ng-container>
	</div>
	<div class="d-flex head-btns">
		<!-- <button
			class="intro-btn d-none d-md-block d-lg-block"
			(click)="openTour()"
		>
			Demo
		</button> -->
		<div class="d-flex align-items-center me-5">
			<p *ngIf="!is_provider && owner_name" class="me-5 mb-0">
				<span class="fw-bold d-block">Owner</span>
				{{owner_name}}
			</p>
			<p class="mb-0" *ngIf="defaultServer">
				<span class="fw-bold me-3 d-block">Resource Server Url</span>
				{{defaultServer}}
				<span *ngIf="countOfUrls>1">
					<i
						class="fa-solid fa-repeat me-3 change-resource-url cursor-pointer"
						(click)="chooseProvider()"
					></i>
				</span>
			</p>
		</div>

		<app-profile class="user-profile-section"></app-profile>
	</div>
</div>
<!-- <div class="mt-lg-5" *ngIf="!title || title!=='Home'"><app-breadcrumbs></app-breadcrumbs></div> -->
