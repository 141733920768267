import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	loader_subject = new Subject<any>();
	toast_subject = new Subject<any>();
	popup_subject = new Subject<any>();
	user_profile: any;
	token: any;
	client: any;
	constructor() {
		this.user_profile = {};
		this.token = '';
		this.client = {};
	}

	set_loader(flag: boolean) {
		this.loader_subject.next(flag);
	}

	get_loader(): Observable<any> {
		return this.loader_subject.asObservable();
	}

	set_toaster(type: any, message: any) {
		this.toast_subject.next({ type, message });
	}

	get_toaster(): Observable<any> {
		return this.toast_subject.asObservable();
	}

	set_popup(flag: boolean, type: string): void {
		this.popup_subject.next({ flag, type });
	}

	get_popup(): Observable<any> {
		return this.popup_subject.asObservable();
	}

	set_token(value: any) {
		this.token = value;
	}

	get_token() {
		return this.token;
	}

	set_user_profile(value: any) {
		this.user_profile = value;
	}

	get_user_profile() {
		return this.user_profile;
	}

	set_user_role_profile(value: any) {
		this.user_profile = value;
	}

	get_user_role_profile() {
		return this.user_profile;
	}

	set_client(value: any) {
		this.client = value;
	}

	get_client() {
		return this.client;
	}
}
