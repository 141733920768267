import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NetworkService } from './network.service';

@Injectable({
	providedIn: 'root'
})
export class AuthorizationService {
	token: any;
	res_token: any = {};
	subcription_token: any = {};

	constructor(private network: NetworkService) {
		this.token = '';
	}

	get_headers(): any {
		const headers: any = {};
		const token = this.get_token();
		headers['Authorization'] = token;

		return headers;
	}

	set_token(value: string): void {
		this.token = value;
	}

	get_token(): any {
		return this.token;
	}

	get_res_token(key: any): any {
		return this.res_token[key];
	}

	set_res_token(key: any, value: any): any {
		this.res_token[key] = value;
	}

	get_data_adapter_token(key: any): any {
		return this.subcription_token[key];
	}

	set_data_adapter_token(key: any, value: any): any {
		this.subcription_token[key] = value;
	}

	async get_res_public_token(itemId?: any): Promise<any> {
		let token_resp: any;
		const isTokenNeeded = true;

		if (isTokenNeeded) {
			let post_body: any;
			if (itemId) {
				post_body = {
					itemId: itemId,
					itemType: 'resource',
					role: 'provider'
				};
			} else {
				post_body = {
					itemId: environment.rs_item,
					itemType: 'resource_server',
					role: 'provider'
				};
			}

			token_resp = await this.network.post_api(
				environment.authorization_server_url + 'auth/v1/token',
				post_body,
				'',
				'auth',
				'',
				'true',
				false
			);
			if (
				token_resp &&
				token_resp.results &&
				token_resp.results.accessToken
			) {
				this.set_res_token('public', token_resp.results.accessToken);
			} else return false;
		}
		return token_resp.results.accessToken;
	}

	async getSubscriptionToken(res_id: string, itemType: any, role?: any) {
		const token = this.get_data_adapter_token('token');
		let isTokenNeeded = false;
		if (token) {
			const valid: any = await this.check_valid_token(token);
			if (!valid || !valid.type || valid.type.indexOf('Success') == -1) {
				isTokenNeeded = true;
			}
		} else {
			isTokenNeeded = true;
		}
		if (isTokenNeeded) {
			let post_body;
			if (!role) {
				post_body = {
					itemId: res_id,
					itemType: itemType,
					role: 'provider'
				};
			} else {
				post_body = {
					itemId: res_id,
					itemType: itemType,
					role: role
				};
			}
			const header = this.get_headers();
			const token_resp: any = await this.network.post_api(
				environment.authorization_server_url + 'auth/v1/token',
				post_body,
				header
			);
			if (
				token_resp &&
				token_resp.results &&
				token_resp.results.accessToken
			) {
				this.set_data_adapter_token(
					'token',
					token_resp.results.accessToken
				);
			} else return false;
		}
		return true;
	}

	async check_valid_token(token: any): Promise<any> {
		const post_body = {
			accessToken: token
		};
		const header = this.get_headers();
		return await this.network.post_api(
			environment.authorization_server_url + 'auth/v1/introspect',
			post_body,
			header
		);
	}

	get_body(type: any, selectedResourceServerUrl: any): any {
		if (type == 'provider') {
			return {
				// For now only rs.iudx.io  gets valid providerId
				itemId: selectedResourceServerUrl,
				itemType: 'resource_server',
				role: 'provider'
			};
		}
	}

	// Function to get the Identity token based on the type
	async get_identity_token(
		type: any,
		selectedResourceServerUrl: any
	): Promise<any> {
		const token_body = this.get_body(type, selectedResourceServerUrl);
		const response: any = await this.network.post_api(
			environment.auth_server_url + 'auth/v1/token',
			token_body,
			'',
			'',
			false,
			'true',
			false
		);
		return response?.results?.accessToken;
	}
}
