import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NetworkService } from './network.service';
import { GlobalService as global_consumer } from 'src/app/consumer/shared/services/global.service';

@Injectable({
	providedIn: 'root'
})
export class AuthorizationService {
	res_token: any = {};

	constructor(
		private network: NetworkService,
		private global_consumer: global_consumer
	) {}

	get_headers(): any {
		const headers: any = {};
		const token = this.get_auth_token();
		headers['Authorization'] = token;

		return headers;
	}

	get_auth_token(params?: any): string | null {
		const headers: any = {};
		headers['Authorization'] = localStorage.getItem(
			'iudx-ui-con-auth-token'
		);

		if (params == 'consumer_delegate_id') {
			const consumer_id = localStorage.getItem('consumer_delegate_id');
			const delegate_details =
				this.global_consumer.get_consumer_delegate_details();
			headers['delegationId'] = consumer_id
				? consumer_id
				: delegate_details.id;
		}
		return headers;
	}

	set_auth_token(token: any): void {
		localStorage.setItem('iudx-ui-con-auth-token', token);
	}

	get_res_token(key: any): any {
		return this.res_token[key];
	}

	set_res_token(key: any, value: any): any {
		this.res_token[key] = value;
	}

	async get_res_public_token(itemId?: any, role?: any): Promise<any> {
		let token_resp: any;
		const isTokenNeeded = true;
		if (isTokenNeeded) {
			let post_body: any;
			if (itemId) {
				post_body = {
					itemId: itemId,
					itemType: 'resource',
					role: role ? role : 'consumer'
				};
			} else {
				post_body = {
					itemId: environment.rs_item,
					itemType: 'resource_server',
					role: role ? role : 'consumer'
				};
			}

			const header = this.get_auth_token(
				role == 'delegate' ? 'consumer_delegate_id' : null
			);
			token_resp = await this.network.post_api_without_toaster(
				environment.authorization_server_url + 'auth/v1/token',
				post_body,
				header
			);
			if (
				token_resp &&
				token_resp.results &&
				token_resp.results.accessToken
			) {
				this.set_res_token('public', token_resp.results.accessToken);
			} else return false;
		}
		return token_resp.results.accessToken;
	}
	async check_valid_token(token: any): Promise<any> {
		const post_body = {
			accessToken: token
		};
		const header = this.get_headers();
		return await this.network.post_api(
			environment.authorization_server_url + 'auth/v1/introspect',
			post_body,
			header
		);
	}
}
