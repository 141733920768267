import { Injectable } from '@angular/core';
import { SubscriberService } from './../../catalogue/shared/services/subscriber.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { GlobalService } from './global.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class NetworkService {
	constructor(
		// public global: GlobalService,
		public http: HttpClient,
		public keycloak: KeycloakService,
		public subscriber: SubscriberService
	) {}

	get_api(url: string, header?: any, type = 'auth', showLoader = 'true') {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_url(url, type), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	get_api_without_toaster(
		url: string,
		header?: any,
		type = 'auth',
		showLoader = 'true'
	) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_url(url, type), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						reject(err);
					}
				);
		});
	}

	post_api(
		url: string,
		post_body: any,
		header?: any,
		type = 'auth',
		responseType?: any,
		showLoader = 'true'
	) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.post(this.get_url(url, type), post_body, {
					headers: new HttpHeaders(header),
					responseType: responseType
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	put_api(
		url: string,
		put_body: any,
		header?: any,
		type = 'auth',
		showLoader = true
	) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.put(this.get_url(url, type), put_body, {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('Sucess', data.detail);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	delete_api(
		url: string,
		body: any,
		header?: any,
		type = 'auth',
		showLoader = 'true'
	) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.delete(this.get_url(url, type), {
					body,
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('Sucess', data.detail);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	handleErrors(err: any): void {
		if (err.status == 400 || err.status == 404)
			this.technicalErrorAlert(err);
		else if (
			err.status == 500 ||
			err.status == 501 ||
			err.status == 502 ||
			err.status == 503 ||
			err.status == 504
		)
			this.serverErrorAlert();
		else {
			if (
				err.error?.detail !==
				'An active and un-expired consent artifact already exists for data principal and resource'
			) {
				this.subscriber.set_toaster('error', err.error?.detail);
			}
		}
	}

	serverErrorAlert(): void {
		this.subscriber.set_toaster('error', 'Network error. Try again later.');
	}

	technicalErrorAlert(err: any): void {
		this.subscriber.set_toaster('error', err.error?.detail);
	}

	get_url(url: string, type = 'auth'): string {
		if (type == 'auth') {
			if (url.indexOf('http') === -1) {
				return environment.auth_server_url + url;
			}
		}
		if (type == 'delegations') {
			if (url.indexOf('http') === -1) {
				return environment.auth_server_url + url;
			}
		}

		return url;
	}
}
