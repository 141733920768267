<div class="form-check p-0">
	<input
		[checked]="checked !== -1"
		type="checkbox"
		id="{{ id }}"
		value="{{ value }}"
		class="form-check-input"
		(change)="checkItem()"
		[disabled]="disabledProp"
	/>
</div>
