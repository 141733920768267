import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { AuthorizationService } from './authorization.service';
import { HttpClient } from '@angular/common/http';
import { SubscriberService } from './../../../catalogue/shared/services/subscriber.service';
import { AuthorizationService as authCat } from 'src/app/catalogue/shared/services/authorization.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	relationship: any = [];
	requests: any = [];
	datasets: any = [];

	constructor(
		public network: NetworkService,
		public auth: AuthorizationService,
		public http: HttpClient,
		private subscriber: SubscriberService,
		private authCat: authCat
	) {}

	get_headers(params?: any, headersFor?: any, type?: any): any {
		const headers: any = {};
		const token =
			params && params.token
				? params.token
				: this.authCat?.get_auth_token();
		headers['Authorization'] = token;
		if (params == 'res' && token) {
			headers['token'] = this.auth.get_res_token(type);
		}
		if (headersFor === 'keycloak_auth') {
			headers['Authorization'] =
				params && params.token
					? params.token
					: this.authCat?.get_auth_token();
		} else if (params == 'res' && token) {
			headers['token'] = this.auth.get_res_token(type);
		}
		return headers;
	}

	async get_token(
		userItemId: any,
		role = 'consumer',
		delegationId = ''
	): Promise<any> {
		let result: any;
		const post_body = {
			itemId: userItemId,
			itemType: 'resource_server',
			role: role
		};
		if (role == 'consumer') {
			result = await this.network.post_api(
				environment.auth_server_url + 'auth/v1/token',
				post_body,
				'',
				'auth',
				'',
				false
			);
			localStorage.setItem(
				'iudx-ui-aclapd-token',
				result.results.accessToken
			);
		} else if (role == 'delegate') {
			result = await this.network.post_api(
				environment.auth_server_url + 'auth/v1/token',
				post_body,
				delegationId,
				'del',
				'',
				false
			);
			localStorage.setItem('delegationId', result.results.accessToken);
		}

		return result?.results?.accessToken;
	}

	async get_user_profile(params?: any): Promise<any> {
		return await this.network.get_api(
			'auth/v1/user/profile',
			this.get_headers(params)
		);
	}

	async getOverview(itemId?: any): Promise<any> {
		const isToken = await this.auth.get_res_public_token(itemId);
		if (!isToken)
			return {
				type: 'ERROR',
				message: 'Not Authorized Consumer'
			};
		const header = this.get_headers('res', '', 'public');
		localStorage.setItem('iudx-ui-rs-token', isToken);
		return await this.network.get_api_without_toaster(
			`${environment.resource_server_url}ngsi-ld/v1/overview`,
			header
		);
	}

	async getMeteringSummary(params: any): Promise<any> {
		const isToken = await this.auth.get_res_public_token();
		if (!isToken)
			return {
				type: 'ERROR',
				message: 'Not Authorized Consumer'
			};
		localStorage.setItem('iudx-ui-rs-token', isToken);
		return await this.network.get_api(
			`${environment.resource_server_url}ngsi-ld/v1/summary?starttime=${params.start_time}&endtime=${params.end_time}`,
			isToken,
			'',
			false
		);
	}

	async getDetailedSummary(params: any): Promise<any> {
		const isToken = await this.auth.get_res_public_token();
		if (!isToken)
			return {
				type: 'ERROR',
				message: 'Not Authorized Consumer'
			};
		localStorage.setItem('iudx-ui-rs-token', isToken);
		return await this.network.get_api(
			`${environment.resource_server_url}ngsi-ld/v1/consumer/audit?id=${params.id}&timerel=between&time=${params.start_time}&endTime=${params.end_time}`,
			isToken
		);
	}

	async get_relationship(resourceId: any): Promise<any> {
		if (this.relationship.length === 0) {
			const res = await this.network.get_api(
				environment.catalogue_server_url +
					'relationship?id=' +
					resourceId +
					'&rel=resourceServer&filter=[resourceServerRegURL]'
			);
			this.relationship = res;
			return this.relationship['results'];
		} else {
			return this.relationship['results'];
		}
	}

	async get_all_cat_datasets(): Promise<any> {
		const res: any = await this.network.get_api(
			environment.catalogue_server_url + 'internal/ui/dataset',
			'',
			'',
			false
		);
		return res['results'];
	}

	async get_purposes(params?: any): Promise<any> {
		return await this.network.get_api(
			'consent/purposes',
			this.get_headers(params, 'id_token'),
			'apd'
		);
	}

	async get_datasets() {
		const response: any = await this.network.get_api(
			'/auth/v1/policies',
			this.get_headers()
		);
		return response.results
			? await this.getDetailedData(response.results)
			: [];
	}

	// eslint-disable-next-line max-lines-per-function
	async get_pending_request() {
		const response: any = await this.network.get_api(
			'dx/apd/acl/v1/policies/requests',
			this.get_headers()
		);
		return response.results
			? await this.getDetailedData(response.results)
			: [];
	}

	async getDetailedData(res: any): Promise<any> {
		const relationships = await this.get_relationship(res);
		return res?.map((item: any) => {
			item['details'] = {
				icon: 'assets/company-logo.png',
				label: 'Dummy Label',
				provider: 'Dummy  Provider',
				instance: 'Dummy Instance'
			};
			if (relationships[item?.itemId]) {
				item['details'] = {
					...item.details,
					...relationships[item.itemId]
				};
			}
			return item;
		});
	}

	async withraw_dataset_request(request_id: any): Promise<any> {
		const body = {
			id: request_id
		};
		return await this.network.delete_api(
			environment.acl_apd_server_url + 'policies/requests',
			body
		);
	}

	async resetPin(role?: any): Promise<any> {
		const isToken = await this.auth.get_res_public_token(null, role);
		if (!isToken)
			return {
				type: 'ERROR',
				message: 'Not Authorized Consumer'
			};
		return await this.network.post_api(
			`${environment.resource_server_url}ngsi-ld/v1/user/resetPassword`,
			'',
			isToken
		);
	}

	async getSubcriptionList(): Promise<any> {
		const isToken = await this.auth.get_res_public_token();
		if (!isToken)
			return {
				type: 'ERROR',
				message: 'Not Authorized Consumer'
			};
		localStorage.setItem('iudx-ui-rs-token', isToken);
		return await this.network.get_api(
			`${environment.resource_server_url}ngsi-ld/v1/subscription`,
			isToken,
			'',
			false
		);
	}
	async deleteSubcription(id: string): Promise<any> {
		const isToken = await this.auth.get_res_public_token();
		if (isToken) {
			const headers = this.get_headers('res', '', 'public');
			const data: any = await this.network.delete_api_with_multipleHeader(
				`${environment.resource_server_url}ngsi-ld/v1/subscription/${id}`,
				headers
			);
			return data;
		}
	}

	async post_request(id: any): Promise<any> {
		try {
			const post_body = {
				itemId: id,
				itemType: 'RESOURCE'
			};
			const result: any = await this.network.post_api(
				environment.acl_apd_server_url + 'policies/requests',
				post_body
			);
			return result;
		} catch (e: any) {
			return e;
		}
	}

	async get_requests(isRequest = false) {
		try {
			const response: any = await this.network.get_api(
				environment.acl_apd_server_url +
					'policies' +
					(isRequest ? '' : '/requests'),
				'',
				'',
				false
			);
			if (response.length === 0) return [];
			return response.results;
		} catch (e) {
			return [];
		}
	}

	async get_resources(id: any, type: any): Promise<any> {
		const result: any = await this.network.get_api_for_request(
			environment.catalogue_server_url +
				'/search?property=[' +
				type +
				']&value=[[' +
				id +
				']]'
		);
		return result['results'];
	}

	async get_resource_from_dataset_id(id: any): Promise<any> {
		const result: any = await this.network.get_api_for_request(
			environment.catalogue_server_url +
				'relationship?id=' +
				id +
				'&rel=resourceGroup'
		);
		return result['results'];
	}

	async get_users(): Promise<any> {
		try {
			const result: any = await this.network.get_api_for_request(
				environment.auth_server_url + 'auth/v1/user/roles',
				'',
				'',
				false
			);

			return result['results'];
		} catch (e: any) {
			this.subscriber.set_toaster('', '');
		}
	}

	async get_resources_label(resourceId: any): Promise<any> {
		return await this.network.get_api(
			environment.catalogue_server_url +
				`/search?property=[id]&value=[[${resourceId}]]&filter=[id,label,description]`
		);
	}

	//DMP APIs

	async get_provider_list(): Promise<any> {
		try {
			const result: any = await this.network.get_api(
				environment.dmp_server_url + 'consumer/list/providers'
			);
			return result;
		} catch (e: any) {
			return;
		}
	}
	async get_resources_list(): Promise<any> {
		try {
			const result: any = await this.network.get_api(
				environment.dmp_server_url + 'consumer/list/resources'
			);
			return result;
		} catch (e: any) {
			return;
		}
	}

	async get_product_list(params?: any): Promise<any> {
		const key = Object.keys(params)[0];
		try {
			let result: any;
			if (params) {
				result = await this.network.get_api(
					environment.dmp_server_url +
						'consumer/list/products' +
						'?' +
						key +
						'=' +
						params[key]
				);
			} else {
				result = await this.network.get_api(
					environment.dmp_server_url + 'consumer/list/products'
				);
			}
			return result;
		} catch (e: any) {
			return;
		}
	}

	async get_all_product_list(): Promise<any> {
		try {
			const response: any = await this.network.get_api(
				environment.dmp_server_url + 'consumer/list/products'
			);
			return response.results;
		} catch (e: any) {
			return;
		}
	}

	async get_product_variant_list(product_id: any) {
		try {
			const response: any = await this.network.get_api(
				environment.dmp_server_url +
					'consumer/product-variant?productId=' +
					product_id
			);
			return response.results;
		} catch (e) {
			return;
		}
	}
	async get_purchases(filter: any) {
		try {
			const response: any = await this.network.get_api(
				environment.dmp_server_url +
					'consumer/list/purchases?paymentStatus=' +
					filter
			);
			return response.results;
		} catch (e) {
			return;
		}
	}
	async get_invoice(orderId: any) {
		try {
			const response: any = await this.network.get_api(
				environment.dmp_server_url +
					`consumer/list/purchases?orderId=
					${orderId}&paymentStatus=pending`
			);
			return response.results;
		} catch (e) {
			return;
		}
	}

	//payment gateway apis
	async createOrder(variantId: any): Promise<any> {
		try {
			const result: any = await this.network.post_api_without_toaster(
				environment.dmp_server_url + `consumer/order/${variantId}`,
				{}
			);
			return result;
		} catch (e: any) {
			return;
		}
	}

	async verifyPurchase(postBody: any): Promise<any> {
		try {
			const result: any = await this.network.post_api(
				environment.dmp_server_url + `verify-payment`,
				postBody
			);
			return result;
		} catch (e: any) {
			return;
		}
	}
}
