import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { SubscriberService } from '../../../services/subscriber.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ApiService as apiParent } from 'src/app/shared/services/api.service';

@Component({
	selector: 'app-delegate-policy-create',
	templateUrl: './delegate-policy-create.component.html',
	styleUrls: ['./delegate-policy-create.component.scss']
})
export class DelegatePolicyCreateComponent implements OnInit {
	@Input() formData: any;
	serverNames: any;
	servers: any = [];
	delegateForm: FormGroup;

	//dropdown settings
	resourceServers = [''];
	selectedItem = [''];
	dropdownSettings: any = {};
	closeDropdownSelection = false;
	disabled = false;

	isResourceServerSelected = true;

	constructor(
		public subscriber: SubscriberService,
		public api: ApiService,
		private activeModal: NgbActiveModal,
		private formBuilder: FormBuilder,
		private apiParent: apiParent
	) {
		this.serverNames = environment.role_urls;
		this.delegateForm = this.formBuilder.group({
			resource_server: ['', Validators.required],
			email: ['', Validators.required]
		});

		this.resourceServers = [];
		this.selectedItem = [];
		this.dropdownSettings = {
			singleSelection: true,
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			allowSearchFilter: true,
			closeDropDownOnSelection: true
		};
	}

	ngOnInit(): void {
		this.getResourceServers();
		//set default resource set_default_server
		const defaultServer = this.formData.selectedResourceServer;
		this.selectedItem = [defaultServer];
		this.onItemSelect(defaultServer);
	}

	async getResourceServers() {
		const response = await this.apiParent.get_resource_servers_by_roles();
		const resourceServerResults = response.results;
		const resourceServerUrls =
			resourceServerResults?.rolesToRsMapping?.provider.sort();
		this.resourceServers = resourceServerUrls;
	}

	validate() {
		//Validations
		if (this.selectedItem.length === 0) {
			this.isResourceServerSelected = false;
			return false;
		} else {
			this.isResourceServerSelected = true;
		}
		return true;
	}

	onItemSelect(item: any) {
		this.delegateForm.controls.resource_server.setValue(item);
		this.validate();
	}

	isChecked(access: any) {
		return this.servers.indexOf(access);
	}

	closeCreatePolicy() {
		this.activeModal.close(true);
	}

	toggleServer(serverUrl: any) {
		if (!this.servers.includes(serverUrl)) {
			this.servers.push(serverUrl);
		} else {
			this.servers = this.servers.filter((server: any) => {
				return server !== serverUrl;
			});
		}
	}

	async submit() {
		if (this.validate()) {
			try {
				const post_body = {
					request: [
						{
							userEmail: this.delegateForm.controls.email.value,
							resSerUrl:
								this.delegateForm.controls.resource_server
									.value,
							role: 'provider'
						}
					]
				};
				const response = await this.apiParent.add_delegate(post_body);
				this.activeModal.close();
				this.subscriber.set_temp_data('Delegation Successfully added');
				this.subscriber.set_toaster('success', response.title);
			} catch (error: any) {
				this.subscriber.set_toaster('error', error.error?.detail);
			}
		}
	}
}
