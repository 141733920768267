import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { GlobalService } from './global.service';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from './authorization.service';
import { SubscriberService } from './subscriber.service';
import { NetworkService as ConsumerNetwork } from 'src/app/consumer/shared/services/network.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	relationship: any = [];
	requests: any = [];
	datasets: any = [];
	constructor(
		public network: NetworkService,
		public con_net: ConsumerNetwork,
		public global: GlobalService,
		public auth: AuthorizationService,
		public subscribe: SubscriberService
	) {}

	get_headers(params?: any, headersFor?: any, type?: any): any {
		let headers: any = {};
		const token =
			params && params.token ? params.token : this.auth.get_token();
		if (headersFor === 'keycloak_auth') {
			headers['Authorization'] =
				params && params.token ? params.token : this.auth.get_token();
		} else if (params == 'res' && token) {
			headers['token'] = this.auth.get_res_token(type);
		} else if (params == 'subscription' && token) {
			headers['token'] = this.auth.get_res_token('public');
		}
		const role = this.global.get_role();
		const provider_id = this.global.get_provider_id();
		if (role == 'auth_delegate') headers['providerId'] = provider_id;
		if (
			params &&
			Object.keys(params)?.length != 0 &&
			params !== 'res' &&
			params !== 'subscription'
		) {
			headers = { ...headers, ...params };
		}
		return headers;
	}

	async check_policy(body: any, params?: any) {
		try {
			const post_body = body;
			return await this.network.post_api(
				'auth/v1/policies',
				post_body,
				this.get_headers(params, 'id_token'),
				'auth'
			);
		} catch (err: any) {
			if (err.status === 409) {
				// policy already exists case, continue the code flow
				return 409;
			} else if (err.status === 403) {
				this.subscribe.set_toaster('error', err.error?.detail);
			}
		}
	}

	//get resources for a specifc provider
	async get_resources(providerId?: any): Promise<any> {
		try {
			return await this.network.get_api(
				`iudx/cat/v1/search?property=[provider]&value=[[${providerId}]]`,
				this.get_headers(providerId, ''),
				'dataexplorer'
			);
		} catch (err: any) {
			this.network.handleErrors(err);
		}
	}

	//get providerId with ownerUserId and resourceServerRegUrl
	async getProviderId(
		ownerUserId: any,
		resourceServerRegURL: any
	): Promise<any> {
		try {
			return await this.network.get_api(
				`/search?property=[ownerUserId,resourceServerRegURL]&value=[[${ownerUserId}],[${resourceServerRegURL}]]&filter=[id, resourceServer]`,
				this.get_headers(),
				'cat-server'
			);
		} catch (err: any) {
			this.network.handleErrors(err);
		}
	}

	//get ResourceGroup info
	async getResourceGroups(providerId: any): Promise<any> {
		try {
			return await this.network.get_api(
				`/relationship?id=${providerId}&rel=resourceGroup&filter=[id,name]`,
				this.get_headers(),
				'cat-server'
			);
		} catch (err: any) {
			this.network.handleErrors(err);
		}
	}

	/// Delete Apis for consent Manager
	async delete_consent_artifact(
		artifactId?: string,
		params?: any
	): Promise<any> {
		try {
			const body = {
				artifactIds: [artifactId]
			};
			return await this.network.delete_api(
				'consent/artifacts',
				body,
				this.get_headers(params, 'id_token'),
				'apd'
			);
		} catch (err: any) {
			this.subscribe.set_toaster('error', err.error?.detail);
		}
	}
	async delete_agreement(agreementIds?: string, params?: any): Promise<any> {
		try {
			const body = {
				agreementIds: [agreementIds]
			};
			return await this.network.delete_api(
				'consent/agreements',
				body,
				this.get_headers(params, 'id_token'),
				'apd'
			);
		} catch (err: any) {
			this.subscribe.set_toaster('error', err.error?.detail);
		}
	}
	async delete_consent_form(
		revokeConsentArtifacts: boolean,
		formId?: string,
		params?: any
	): Promise<any> {
		try {
			const body = {
				revokeConsentArtifacts: revokeConsentArtifacts,
				formIds: [formId]
			};

			return await this.network.delete_api(
				'consent/forms',
				body,
				this.get_headers(params, 'id_token'),
				'apd'
			);
		} catch (err: any) {
			this.subscribe.set_toaster('error', err.error?.detail);
		}
	}
	async delete_data_principal(
		ppb_number?: string,
		params?: any
	): Promise<any> {
		try {
			const body = {
				dataPrincipals: [ppb_number]
			};
			return await this.network.delete_api(
				'consent/principals',
				body,
				this.get_headers(params, 'id_token'),
				'apd'
			);
		} catch (err: any) {
			this.subscribe.set_toaster('error', err.error?.detail);
		}
	}
	async delete_AIU(aiuId?: string, params?: any): Promise<any> {
		try {
			const body = {
				aiuIds: [aiuId]
			};
			return await this.network.delete_api(
				'consent/aius',
				body,
				this.get_headers(params, 'id_token'),
				'apd'
			);
		} catch (err: any) {
			this.subscribe.set_toaster('error', err.error?.detail);
		}
	}
	async get_user_profile(params?: any): Promise<any> {
		return await this.network.get_api(
			'auth/v1/user/profile',
			this.get_headers(params)
		);
	}

	async get_delegates(): Promise<any> {
		return await this.network.get_api(
			'auth/v1/policies/delegations',
			this.get_headers()
		);
	}

	async create_user_profile(post_body: any): Promise<any> {
		return await this.network.post_api(
			'auth/v1/user/profile',
			post_body,
			this.get_headers()
		);
	}

	async get_all_policies(): Promise<any> {
		const response: any = await this.network.get_api('auth/v1/policies');
		return response.results
			? await this.getDetailedData(response.results)
			: [];
	}

	async get_relationship(): Promise<any> {
		if (this.relationship.length === 0) {
			const res = '';
			// const res = await this.network.get_api(
			// 	environment.mlayer_url + 'relationship'
			// );
			this.relationship = res;
			return this.relationship;
		} else {
			return this.relationship;
		}
	}

	async getDetailedData(res: any): Promise<any> {
		const relationships = await this.get_relationship();

		return res?.map((item: any) => {
			item['details'] = {
				icon: 'assets/company-logo.png',
				label: 'Dummy Label',
				provider: 'Dummy  Provider',
				instance: 'Dummy Instance'
			};
			if (relationships[item.itemId]) {
				item['details'] = {
					...item?.details,
					...relationships[item.itemId]
				};
			}
			return item;
		});
	}

	async create_delegations(body: any): Promise<any> {
		return await this.network.post_api(
			'auth/v1/policies/delegations',
			body,
			this.get_headers()
		);
	}

	async delete_delegations(body: any): Promise<any> {
		return await this.network.delete_api(
			'auth/v1/policies/delegations',
			body,
			this.get_headers()
		);
	}

	async getMeteringSummary(params: any, item_id: any): Promise<any> {
		try {
			const isToken = await this.auth.get_res_public_token(item_id);
			if (!isToken)
				return {
					type: 'ERROR',
					message: 'Not Authorized Provider'
				};
			localStorage.setItem('iudx-ui-rs-token', isToken);
			return await this.network.get_api(
				`${environment.resource_server_url}ngsi-ld/v1/summary?starttime=${params.start_time}&endtime=${params.end_time}`,
				isToken
			);
		} catch (e) {
			// console.log(e);
		}
	}

	async getDetailedSummary(params: any): Promise<any> {
		const isToken = await this.auth.get_res_public_token();
		if (!isToken)
			return {
				type: 'ERROR',
				message: 'Not Authorized Consumer'
			};
		const header = this.get_headers('subscription', '', 'token');
		return await this.con_net.get_api(
			environment.resource_server_url +
				'ngsi-ld/v1/provider/audit?providerId=' +
				params.providerId +
				'&timerel=between&time=' +
				params.start_time +
				'&endTime=' +
				params.end_time,
			header
		);
	}

	async getDataAdapterList(id: any): Promise<any> {
		const token = await this.auth.getSubscriptionToken(
			id,
			'resource_group'
		);
		if (!token)
			return {
				type: 'ERROR',
				message: 'Not Authorized Consumer'
			};
		const header = this.get_headers('subscription', '', 'token');
		const response = await this.network.get_api(
			`${environment.resource_server_url}ngsi-ld/v1/ingestion`,
			header
		);
		localStorage.removeItem('isRemoveDefaultToken');
		return response;
	}

	async registerAdapter(datasetId: any, itemType: any): Promise<any> {
		try {
			const token = await this.auth.getSubscriptionToken(
				datasetId,
				itemType
			);
			if (token) {
				const headers = this.get_headers('subscription', '', 'token');
				const body = {
					entities: [datasetId]
				};
				const data: any =
					await this.network.post_api_with_multipleHeader(
						`${environment.resource_server_url}ngsi-ld/v1/ingestion`,
						body,
						headers
					);
				return data;
			}
		} catch (e: any) {
			this.subscribe.set_toaster(
				'error',
				"You've already registered this below Resource item. Please try with another Resource Item"
			);
			return e.status;
		}
	}

	async resetPin(): Promise<any> {
		const isToken = await this.auth.get_res_public_token();
		if (!isToken)
			return {
				type: 'ERROR',
				message: 'Not Authorized Consumer'
			};
		const header = this.get_headers('res', '', 'public');
		return await this.network.post_api(
			`${environment.resource_server_url}ngsi-ld/v1/user/resetPassword`,
			'',
			header
		);
	}

	async deleteRegisterAdapter(datasetId: any, itemType: any): Promise<any> {
		try {
			const token = await this.auth.getSubscriptionToken(
				datasetId,
				itemType
			);
			if (token) {
				const headers = this.get_headers('subscription', '', 'token');
				const data: any =
					await this.network.delete_api_with_multipleHeader(
						`${environment.resource_server_url}ngsi-ld/v1/ingestion/${datasetId}`,
						headers
					);
				this.subscribe.set_toaster(
					'Success',
					'Successfully deleted the Access'
				);
				localStorage.removeItem('isRemoveDefaultToken');
				return data;
			}
		} catch (e: any) {
			this.subscribe.set_toaster('error', e.error?.detail);
			return e.status;
		}
	}

	//TO DO: who commnted why
	async get_dataset_details(dataset: string): Promise<any> {
		// eslint-disable-next-line no-console
		console.log(dataset);
		// try {
		// 	const post_body = {
		// 		dataset
		// 	};
		// 	return await this.network.post_api(
		// 		`${environment.mlayer_url}dataset`,
		// 		post_body
		// 	);
		// } catch (err: any) {
		// 	this.subscribe.set_toaster('error', err.error?.detail);
		// }
	}

	async getOverview(id: string): Promise<any> {
		const isToken = await this.auth.get_res_public_token(id);
		if (!isToken) {
			return {
				type: 'ERROR',
				message: 'Not Authorized Provider'
			};
		}
		const header = this.get_headers('subscription', '', 'token');
		localStorage.setItem('iudx-ui-rs-token', isToken);
		try {
			return await this.network.get_api(
				environment.resource_server_url + 'ngsi-ld/v1/overview',
				header
			);
		} catch (err: any) {
			// console.log(err);
			// this.subscribe.set_toaster('error', err?.error?.detail)
		}
	}

	async get_provider_id(user_id: any): Promise<any> {
		try {
			return await this.network.get_api(
				environment.catalogue_server_url +
					`search?property=[ownerUserId,type]&value=[[${user_id}],[iudx:Provider]]&filter=[id,resourceServerRegURL]`
			);
		} catch (e: any) {
			return;
		}
	}

	async get_datasets_list(provider_id: any): Promise<any> {
		try {
			return await this.network.get_api(
				environment.catalogue_server_url +
					`relationship?id=${provider_id}&rel=resourceGroup&filter=[instance,label,description,id]`
			);
		} catch (e: any) {
			return;
		}
	}

	async get_datasets_list_with_id(provider_id: any): Promise<any> {
		return await this.network.get_api(
			environment.catalogue_server_url +
				`relationship?id=${provider_id}&rel=resourceGroup&filter=[label,id]`
		);
	}
	async get_dataset_details_api(dataset_id: any): Promise<any> {
		return await this.network.get_api(
			environment.catalogue_server_url +
				'relationship' +
				`?id=${dataset_id}&rel=resourceServer`
		);
	}
	async get_resources_list(datasetID: any): Promise<any> {
		return await this.network.get_api(
			environment.catalogue_server_url +
				`relationship?id=${datasetID}&rel=resource`
		);
	}

	async get_resources_label(resourceId: any): Promise<any> {
		return await this.network.get_api(
			environment.catalogue_server_url +
				`/search?property=[id]&value=[[${resourceId}]]&filter=[id,label,description, accessPolicy]`
		);
	}

	//ACL APD Apis
	async get_policies(accessToken: any): Promise<any> {
		const headers = {
			Authorization: accessToken
		};
		try {
			return await this.network.get_api(
				environment.acl_apd_server_url + `policies`,
				headers
			);
		} catch (err: any) {
			return [];
		}
	}

	async create_policies(accessToken: any, body: any): Promise<any> {
		const headers = {
			Authorization: accessToken
		};

		return await this.network.post_api(
			environment.acl_apd_server_url + 'policies',
			body,
			headers
		);
	}

	async delete_policy(accessToken: any, policyId: any): Promise<any> {
		const headers = {
			Authorization: accessToken
		};
		const body = {
			id: policyId
		};

		return await this.network.delete_api(
			environment.acl_apd_server_url + 'policies',
			body,
			headers
		);
	}

	async list_pending_requests(accessToken: any): Promise<any> {
		const headers = {
			Authorization: accessToken
		};
		try {
			return await this.network.get_api(
				environment.acl_apd_server_url + `policies/requests`,
				headers
			);
		} catch (err: any) {
			return [];
		}
	}
	async get_requests() {
		try {
			const response: any = await this.network.get_api(
				environment.acl_apd_server_url + 'policies/requests'
			);
			// if (response.title === 'Not Found') return [];
			return response.results;
		} catch (e) {
			return;
		}
	}
	async get_datasets() {
		const response: any = await this.network.get_api(
			'/auth/v1/policies',
			this.get_headers()
		);
		return response.results
			? await this.getDetailedData(response.results)
			: [];
	}

	async update_request(accessToken: any, body: any): Promise<any> {
		const headers = {
			Authorization: accessToken
		};
		return await this.network.put_api(
			environment.acl_apd_server_url + 'policies/requests',
			body,
			headers
		);
	}

	// Onboarding apis
	async createResourceGroupOrResourceItem(
		payload: any,
		token: any
	): Promise<any> {
		const headers = {
			Authorization: token
		};
		await this.network.post_api(
			environment.onboarding_server_url + 'item/',
			payload,
			headers
		);
	}

	//DMP APIS

	async get_product_list() {
		try {
			const response: any = await this.network.get_api(
				environment.dmp_server_url + 'provider/list/products'
			);
			return response.results;
		} catch (e) {
			return;
		}
	}
	async get_product_variant_list(product_id: any) {
		try {
			const response: any = await this.network.get_api(
				environment.dmp_server_url +
					'provider/product-variant?productId=' +
					product_id
			);
			return response.results;
		} catch (e) {
			return;
		}
	}

	async get_purchases(filter: any) {
		try {
			const response: any = await this.network.get_api(
				environment.dmp_server_url +
					'provider/list/purchases?paymentStatus=' +
					filter
			);
			return response.results;
		} catch (e) {
			return;
		}
	}

	async fetchLinkedAccount(): Promise<any> {
		try {
			const response: any = await this.network.get_api(
				environment.dmp_server_url + 'dx/apd/dmp/v1/account'
			);
			return response.results;
		} catch (e: any) {
			return;
		}
	}

	async create_product(post_body: any): Promise<any> {
		try {
			const response = await this.network.post_api(
				environment.dmp_server_url + 'provider/product',
				post_body
			);
			return response;
		} catch (e: any) {
			return e.error;
		}
	}

	async create_product_variant(post_body: any): Promise<any> {
		try {
			return await this.network.post_api(
				environment.dmp_server_url + 'provider/product-variant',
				post_body
			);
		} catch (e: any) {
			return;
		}
	}

	async createLinkedAccount(post_body: any): Promise<any> {
		try {
			return await this.network.post_api(
				environment.dmp_server_url + 'dx/apd/dmp/v1/account',
				post_body
			);
		} catch (e: any) {
			return;
		}
	}
	async updateLinkedAccount(post_body: any): Promise<any> {
		try {
			return await this.network.put_api(
				environment.dmp_server_url + 'dx/apd/dmp/v1/account',
				post_body
			);
		} catch (e: any) {
			return;
		}
	}

	async delete_product_variant(productVariantId: any): Promise<any> {
		try {
			const response: any = await this.network.delete_api(
				environment.dmp_server_url +
					'provider/product-variant?productVariantId=' +
					productVariantId,
				''
			);
			return response.results;
		} catch (e) {
			return;
		}
	}

	async delete_product(productId: any): Promise<any> {
		try {
			const response: any = await this.network.delete_api(
				environment.dmp_server_url +
					'provider/product?productId=' +
					productId,
				''
			);
			return response.results;
		} catch (e) {
			return;
		}
	}
}
