<div
	class="account urban"
	(click)="toggleDropdown($event)"
	[ngClass]="{'container-dropdown':global.showDropdown}"
>
	<a
		type="button"
		id="user-profile"
		[ngClass]="{'focused':global.showDropdown}"
		class="name"
	>
		{{user?.name?.firstName &&
		user?.name?.firstName.charAt(0)}}{{user?.name?.lastName &&
		user?.name?.lastName.charAt(0)}}
	</a>

	<ul class="dropdown" *ngIf="global.showDropdown">
		<li class="item py-0">
			<div class="role-profile mt-2">
				<div>
					<p class="ps-lg-2 mb-0 username py-lg-3">
						<i
							class="fa-solid fa-user pe-lg-2"
							style="width: 17px"
						></i>
						{{user?.name?.firstName && user?.name?.firstName }}
						{{user?.name?.lastName && user?.name?.lastName}}
					</p>
					<span class="ps-lg-2 mb-0 pb-lg-3"
						><i
							class="fa-solid fa-envelope pe-lg-2"
							style="width: 21px"
						></i
						>{{user.email && user.email}}</span
					>
				</div>
			</div>
		</li>
		<li
			class="item clickable"
			(click)="go_to_profile()"
			*ngIf="roles.length>1 || enableSwitchPanel"
		>
			<i
				class="fa-solid fa-repeat"
				style="width: 26px; font-size: 12px; padding-left: 5px"
			></i
			><span class="role">switch panel</span>
		</li>
		<li
			(click)="goToProfile()"
			class="item clickable my-profile text-black"
		>
			<i
				class="fa-solid fa-envelope pe-lg-2"
				style="width: 26px; font-size: 12px; padding-left: 5px"
			></i
			><span class="my-profile-text">My Profile</span>
		</li>
		<li (click)="logout()" class="sinn-out">
			<i
				class="fa-solid fa-arrow-right-from-bracket ps-lg-2 text-white"
			></i
			><span class="sign-out-text ps-lg-2"> Sign out</span>
		</li>
	</ul>
</div>
