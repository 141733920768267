import { Injectable } from '@angular/core';
import { SubscriberService } from './subscriber.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	user_profile: any;
	role: string;
	provider_id: string;
	providers: any;
	temp_data: any;
	showDropdown = false;
	intro_steps_home: any = {};
	url: any;
	tab_params: any;
	dataprincipal_id: any;
	tabSwitch_ids: any;
	current_tab: any;
	name: any;
	resource_server: any;
	delegate_details: { id: any; url: any; email: any; name: any };
	constructor(public subscriber: SubscriberService) {
		this.user_profile = {};
		this.role = '';
		this.provider_id = '';
		this.providers = [];
		this.tab_params = {
			url: '',
			isFilter: false
		};
		this.tabSwitch_ids = {
			PPBNumber: [],
			ArtifactsId: '',
			AccessLogsId: '',
			ConsentFormId: '',
			AIUName: ''
		};
		this.delegate_details = {
			id: '',
			url: '',
			email: '',
			name: {}
		};
	}

	set_user_profile(value: any): void {
		this.user_profile = value;
	}

	get_user_profile(): any {
		return this.user_profile;
	}
	get_introjs_steps_home(): any {
		return this.intro_steps_home;
	}
	set_introjs_steps_home(step: string, index: any): any {
		this.intro_steps_home[index] = step;
	}

	set_role(value: any): void {
		this.role = value;
	}

	get_role(): any {
		return this.role;
	}

	set_provider_id(value: any): void {
		this.provider_id = value;
		localStorage.setItem('provider_id', value);
	}

	get_provider_id(): any {
		return this.provider_id;
	}

	set_resource_server(value: any): void {
		this.resource_server = value;
		localStorage.setItem('rs_url', value);
	}
	get_resource_server(): any {
		return this.resource_server;
	}
	set_providers(value: any): void {
		this.providers = value;
	}
	set_provider_delegate_details(id: any, url: any, email?: any, name?: any) {
		this.delegate_details = { id: id, url: url, email: email, name: name };
	}
	get_provider_delegate_details(): any {
		return this.delegate_details;
	}

	get_providers(): any {
		return this.providers;
	}

	get_temp_data(): any {
		return this.temp_data;
	}

	set_temp_data(value: any): void {
		this.temp_data = value;
	}

	is_login_attempted(): any {
		return localStorage.getItem('isLoginAttempted');
	}

	set_login_attempted(value: string): void {
		localStorage.setItem('isLoginAttempted', value);
	}

	remove_login_attempted(): void {
		localStorage.removeItem('isLoginAttempted');
	}

	get_url_without_hash(): any {
		return window.location.href.replace(window.location.hash, '');
	}

	convertUTCDateToLocalDate(expiry_time: any): any {
		const date = new Date(expiry_time);
		const mlist = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];
		const newDate =
			date.getDate() +
			' ' +
			mlist[date.getMonth()] +
			', ' +
			date.getFullYear() +
			', ' +
			this.getLeadingZero(date.getHours()) +
			':' +
			this.getLeadingZero(date.getMinutes()) +
			':' +
			this.getLeadingZero(date.getSeconds());
		return newDate;
	}

	getLeadingZero(time: any): any {
		if (time <= 9) {
			return '0' + time;
		} else {
			return time;
		}
	}

	get_name_value(name: string): string {
		if (name === 'api') {
			return 'API';
		} else if (name === 'subs' || name === 'sub') {
			return 'Subscription';
		} else {
			return name[0].toUpperCase() + name.slice(1, name.length);
		}
	}

	setShowDropdown(val: boolean): void {
		this.showDropdown = val;
	}
	/* To copy any Text */
	copyText(val: any, message: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.subscriber.set_toaster('success', message);
	}
	setUrlForTAb(val: any, isFilter: any) {
		this.tab_params = {
			url: val,
			isFilter: isFilter
		};
	}
	getUrlForTab() {
		return this.tab_params;
	}
	set_tabswitch_id(
		value: any,
		switchTo: any,
		switchFrom?: any,
		name?: any
	): void {
		this.tabSwitch_ids[switchTo] = value;
		this.current_tab = switchFrom;
		this.name = name;
	}

	get_tabSwitch_id(tabName: string): any {
		return {
			id: this.tabSwitch_ids[tabName],
			filterbyname: this.name,
			prev_tab: this.current_tab
		};
	}

	get_StartDate_EndDate(monthcount: number) {
		const start_date = new Date();
		const end_date = new Date();
		const month = end_date.getMonth();
		start_date.setMonth(end_date.getMonth() + 1 - monthcount);
		if (end_date.getMonth() == month) start_date.setDate(0);
		start_date.setHours(0, 0, 0, 0);
		start_date.setDate(1);
		return { start: start_date.toISOString(), end: end_date.toISOString() };
	}

	setTitle(title: string) {
		if (title !== '') {
			this.subscriber.setTitle(title);
		} else {
			this.subscriber.setTitle(undefined);
		}
	}

	getResourceServerProvider() {
		const provider_owner_Details_string = localStorage.getItem(
			'provider_owner_Details'
		);
		const defaultServer = provider_owner_Details_string
			? JSON.parse(provider_owner_Details_string).url
			: environment.rs_item;
		return defaultServer;
	}

	getProviderDelegateOwner() {
		const provider_owner_Details_string = localStorage.getItem(
			'provider_owner_Details'
		);
		const ownerId = provider_owner_Details_string
			? JSON.parse(provider_owner_Details_string).id
			: '';
		return ownerId;
	}
}
