<div class="overlay"></div>
<div class="popup">
	<div class="title">
		<h1>Approve request</h1>
		<img (click)="closeViewRequest()" src="../../../assets/close.png" />
	</div>
	<div class="info">
		<div class="name">
			<span class="fw-bold">Name</span>
			<p>
				{{user_details && user_details.consumer.name.firstName}}
				{{user_details && user_details.consumer.name.lastName}}
			</p>
		</div>
		<div class="email">
			<span class="fw-bold">Email</span>
			<p>{{user_details && user_details.consumer.email}}</p>
		</div>
	</div>
	<h3 class="secondary-title">Dataset</h3>
	<p class="desc">{{user_details && user_details.resourceLabel}}</p>

	<div class="access-type-container">
		<div (click)="toggleAccess('api')" role="button" class="type">
			<app-checkbox [checked]="isChecked('api')"></app-checkbox>
			<span class="access-type d-flex align-items-center">API</span>
		</div>
		<div (click)="toggleAccess('sub')" class="type">
			<app-checkbox [checked]="isChecked('sub')"></app-checkbox>
			<span class="access-type d-flex align-items-center"
				>Subscription</span
			>
		</div>
		<div (click)="toggleAccess('file')" class="type">
			<app-checkbox [checked]="isChecked('file')"></app-checkbox>
			<span class="access-type d-flex align-items-center">File</span>
		</div>
		<div (click)="toggleAccess('async')" class="type">
			<app-checkbox [checked]="isChecked('async')"></app-checkbox>
			<span class="access-type d-flex align-items-center">Async</span>
		</div>
	</div>

	<div class="data">
		<p class="tagline fw-bold">Policy expires in*</p>
		<div class="data-list">
			<div class="data-item">
				<span>Years</span
				><input value="{{year}}" type="number" [(ngModel)]="year" />
			</div>
			<div class="data-item">
				<span>Months</span
				><input value="{{month}}" type="number" [(ngModel)]="month" />
			</div>
			<div class="data-item">
				<span>Days</span
				><input value="{{day}}" type="number" [(ngModel)]="day" />
			</div>
		</div>
	</div>

	<div class="link">
		<a (click)="approve()" class="box">Approve</a>
	</div>

	<!-- <h3 class="secondary-title">Files</h3> -->
	<!-- <ul>
		<li>
			<div>
				<img src="./../../../../assets/file.png" alt="" />
				<span>Adaptive traffic control system corridor info</span>
			</div>

			<span>250kb</span>
			<img src="./../../../../assets/download.png" alt="" />
		</li>
		<li>
			<div>
				<img src="./../../../../assets/file.png" alt="" /> <span>Control system corridor info</span>
			</div>

			<span>600kb</span>
			<img src="./../../../../assets/download.png" alt="" />
		</li>
		<li>
			<div>
				<img src="./../../../../assets/file.png" alt="" />
				<span>Traffic control system corridor info</span>
			</div>

			<span>300kb</span>
			<img src="./../../../../assets/download.png" alt="" />
		</li>
	</ul> -->
</div>
