<div class="m-3">
	<span class="text-success"
		><span class="fw-bold text-decoration-underline">Latitude:</span
		>{{latitude}}
		<span class="fw-bold text-decoration-underline ms-5">Longitude:</span>
		{{longitude}}</span
	>
</div>
<div
	style="height: 500px"
	leaflet
	[leafletOptions]="options"
	(leafletMapReady)="onMapReady($event)"
></div>
