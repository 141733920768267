<form
	[formGroup]="linkedAccountForm"
	autocomplete="off"
	(ngSubmit)="onSubmit()"
>
	<div class="form-group mt-3">
		<label class="form-label"
			>Phone Number <i class="fa-solid fa-star-of-life text-danger"></i
		></label>
		<input
			type="text"
			class="form-control"
			formControlName="phone"
			placeholder="9000090000"
		/>
		<span
			class="error-text"
			[ngClass]="(linkedAccountForm.controls.phone.status==='INVALID' && (linkedAccountForm.controls.phone.touched || submitClicked))?'visible':'invisible'"
			>Please enter phone number</span
		>
	</div>
	<div class="form-group mt-3">
		<label class="form-label"
			>Business Name <i class="fa-solid fa-star-of-life text-danger"></i
		></label>
		<input
			type="text"
			class="form-control"
			formControlName="legalBusinessName"
			placeholder="Dummy Corp V1"
		/>
		<span
			class="error-text"
			[ngClass]="(linkedAccountForm.controls.legalBusinessName.status==='INVALID' && ( linkedAccountForm.controls.legalBusinessName.touched|| submitClicked))?'visible':'invisible'"
			>Please enter legal business name</span
		>
	</div>
	<div class="form-group mt-3">
		<label class="form-label">customer Facing BusinessName</label>
		<input
			type="text"
			class="form-control"
			formControlName="customerFacingBusinessName"
			placeholder="Dummy Service Corp V1"
		/>
	</div>
	<div class="form-group mt-3">
		<label class="select-label"
			>Business Type <i class="fa-solid fa-star-of-life text-danger"></i
		></label>
		<ng-multiselect-dropdown
			class="radio-btn-single-select"
			[settings]="businessdropdownSettings"
			[data]="availabeBusinessTypes"
			[(ngModel)]="businessType"
			[ngModelOptions]="{standalone: true}"
			(onSelect)="selectBusinessType($event)"
			(onDeSelect)="selectBusinessType('')"
			placeholder="partnership"
			[disabled]="updateMode"
		>
		</ng-multiselect-dropdown>
		<span
			class="error-text"
			[ngClass]="(businessType?.length===0 && (businessTypeTouched|| submitClicked))?'visible':'invisible'"
			>Please select business type</span
		>
	</div>
	<div class="form-group mt-3">
		<label class="form-label">Contact Name</label>
		<input
			type="text"
			class="form-control"
			formControlName="contactName"
			placeholder="Bob Davis"
		/>
	</div>
	<div class="mt-3">
		<h2>Profile</h2>
		<div class="form-group mt-3">
			<label class="select-label"
				>Category <i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<ng-multiselect-dropdown
				class="radio-btn-single-select"
				[settings]="categorySettings"
				[data]="businessCategories"
				[(ngModel)]="profileCategory"
				[ngModelOptions]="{standalone: true}"
				(onSelect)="selectBusinessCategory($event)"
				(onDeSelect)="selectBusinessCategory('')"
				placeholder="healthcare"
			>
			</ng-multiselect-dropdown>
			<span
				class="error-text"
				[ngClass]="(profileCategory?.length ===0 && (businessCategoryTouched|| submitClicked))?'visible':'invisible'"
				>Please select category</span
			>
		</div>

		<div class="form-group mt-3">
			<label class="select-label"
				>Sub Category
				<i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<input
				type="text"
				class="form-control"
				formControlName="profileSubCategory"
				placeholder="clinic"
			/>
			<p _ngcontent-end-c183="" class="mt-2">
				<a
					_ngcontent-end-c183=""
					href="https://razorpay.com/docs/payments/route/integration-guide/#business-sub-category"
					target="_blank"
					>Link
					<i
						_ngcontent-end-c183=""
						class="fa-solid fa-arrow-up-right-from-square"
					></i
				></a>
			</p>
			<span
				class="error-text"
				[ngClass]="(linkedAccountForm.controls.profileSubCategory.status==='INVALID' && ( linkedAccountForm.controls.profileSubCategory.touched|| submitClicked))?'visible':'invisible'"
				>Please enter sub category</span
			>
		</div>

		<h2>Registered Address</h2>
		<div class="form-group mt-3">
			<label class="form-label"
				>Street1<i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<input
				type="text"
				class="form-control"
				formControlName="street1"
				placeholder="507, Koramangala 1st block"
			/>
			<span
				class="error-text"
				[ngClass]="(linkedAccountForm.controls.street1.status==='INVALID' && (linkedAccountForm.controls.street1.touched  || submitClicked))?'visible':'invisible'"
				>Please enter street1</span
			>
		</div>
		<div class="form-group mt-3">
			<label class="form-label"
				>Street2<i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<input
				type="text"
				class="form-control"
				formControlName="street2"
				placeholder="MG Road"
			/>
			<span
				class="error-text"
				[ngClass]="(linkedAccountForm.controls.street2.status==='INVALID' && (linkedAccountForm.controls.street2.touched  || submitClicked))?'visible':'invisible'"
				>Please enter street2</span
			>
		</div>

		<div class="form-group mt-3">
			<label class="form-label"
				>City<i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<input
				type="text"
				class="form-control"
				formControlName="city"
				placeholder="Bengaluru"
			/>
			<span
				class="error-text"
				[ngClass]="(linkedAccountForm.controls.city.status==='INVALID' && (linkedAccountForm.controls.city.touched  || submitClicked))?'visible':'invisible'"
				>Please enter city</span
			>
		</div>
		<div class="form-group mt-3">
			<label class="form-label"
				>State<i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<input
				type="text"
				class="form-control"
				formControlName="state"
				placeholder="KARNATAKA"
			/>
			<span
				class="error-text"
				[ngClass]="(linkedAccountForm.controls.state.status==='INVALID' && (linkedAccountForm.controls.state.touched  || submitClicked))?'visible':'invisible'"
				>Please enter state</span
			>
		</div>
		<div class="form-group mt-3">
			<label class="form-label"
				>Postal Code<i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<input
				type="text"
				class="form-control"
				formControlName="postalCode"
				placeholder="560034"
			/>
			<span
				class="error-text"
				[ngClass]="(linkedAccountForm.controls.postalCode.status==='INVALID' && (linkedAccountForm.controls.postalCode.touched  || submitClicked))?'visible':'invisible'"
				>Please enter postal code</span
			>
		</div>
		<div class="form-group mt-3">
			<label class="form-label"
				>Country<i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<input
				type="text"
				class="form-control"
				formControlName="country"
				placeholder="IN"
			/>
		</div>
	</div>
	<div class="form-check form-switch">
		<label class="form-check-label" for="flexSwitchCheckChecked">
			Legal Info</label
		>

		<input
			class="form-check-input"
			type="checkbox"
			role="switch"
			id="flexSwitchCheckChecked"
			formControlName="legalInfo"
			checked
		/>
	</div>
	<div *ngIf="linkedAccountForm.controls.legalInfo.value">
		<div class="form-group mt-3">
			<label class="form-label">Pan</label>
			<input
				type="text"
				class="form-control"
				formControlName="pan"
				placeholder="AAACL1234C"
			/>
		</div>
		<div class="form-group mt-3">
			<label class="form-label">Gst</label>
			<input
				type="text"
				class="form-control"
				formControlName="gst"
				placeholder="18AABCU9603R1ZM"
			/>
		</div>
	</div>

	<div class="d-flex justify-content-end">
		<button class="box mt-5" type="submit">
			{{updateMode?'Update Linked Account':'Create Linked Account'}}
		</button>
	</div>
</form>
