import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GlobalService } from '../../../services/global.service';
import { SubscriberService } from '../../../services/subscriber.service';
import { ApiService as apiParent } from 'src/app/shared/services/api.service';
import { GlobalService as GlobalCatService } from 'src/app/catalogue/shared/services/global.service';

@Component({
	selector: 'app-consumer-policy-delete',
	templateUrl: './consumer-policy-delete.component.html',
	styleUrls: ['./consumer-policy-delete.component.scss']
})
export class ConsumerPolicyDeleteComponent implements OnInit {
	consumer_details: any;
	is_provider: any;
	selectedResourceServer: string | null;
	policy: any;

	constructor(
		public global: GlobalService,
		public subscriber: SubscriberService,
		public api: ApiService,
		public global_cat: GlobalCatService,
		private apiParent: apiParent
	) {
		this.policy = this.global.get_temp_data();
		this.consumer_details = this.global.get_temp_data();
		const user_profile = this.global_cat.get_user_profile();
		if (user_profile?.roles?.includes('provider')) {
			this.is_provider = true;
		} else {
			this.is_provider = false;
		}
		this.selectedResourceServer = localStorage.getItem(
			'selectedResourceServer'
		);
	}

	ngOnInit(): void {}

	close(isChanged?: boolean): void {
		this.subscriber.set_popup(false, 'delete-policy', !!isChanged);
	}

	async deletePolicy() {
		try {
			this.global.set_temp_data(null);
			const policy_id = this.consumer_details.policyId;

			let body_token;
			let header;
			if (this.is_provider) {
				body_token = {
					itemId: this.selectedResourceServer,
					itemType: 'resource_server',
					role: 'provider'
				};
			} else {
				body_token = {
					itemId: this.selectedResourceServer,
					itemType: 'resource_server',
					role: 'delegate'
				};
				header = 'delegate_id';
			}
			const accessToken = await this.apiParent.get_id_token(
				body_token,
				header
			);

			//const accessToken = await this.apiParent.get_id_token(post_body);
			await this.api.delete_policy(accessToken, policy_id);
			this.subscriber.set_popup(false, 'delete-policy', true);
			this.subscriber.set_temp_data('policy delete successfull');
		} catch (error: any) {
			//
		}
	}
}
