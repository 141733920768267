import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SubscriberService } from './subscriber.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class KeycloakUtilService {
	cookie_interval: any;
	open_window: any;
	user_subject = new Subject<any>();

	constructor(private subscriber: SubscriberService) {}

	//below 2 methods copied from consumer and provider keycloak-util.service.ts, remove if not being used
	set_user(): void {
		this.user_subject.next();
	}
	get_user(): Observable<any> {
		return this.user_subject.asObservable();
	}

	// Authentication Section
	get_cookie_value(key: string): string {
		let value = '';
		if (
			document.cookie &&
			document.cookie.split(key + '=')[1] &&
			document.cookie.split(key + '=')[1].split(';')[0]
		) {
			value = document.cookie.split(key + '=')[1].split(';')[0];
		}
		return value;
	}

	listen_cookie(keycloak: any): void {
		if (this.get_cookie_value('iudx-ui-sso') == 'logged-in') {
			clearInterval(this.cookie_interval);
			this.open_window?.close();
			keycloak?.login({
				redirectUri: window.location.href,
				prompt: 'none'
			});
		}
	}

	login(keycloak: any): void {
		const origin = location.pathname.split('/')[1];
		localStorage.removeItem('provider_owner_Details');
		localStorage.removeItem('consumer_owner_details');
		if (
			origin == 'consumer' ||
			origin == 'provider' ||
			origin == 'rs-admin' ||
			origin == 'cop-admin'
		) {
			this.cookie_interval = setInterval(() => {
				this.listen_cookie(keycloak);
			}, 100);

			this.open_window = window.open(environment.auth_web, '_blank');
		}
	}

	login_cat(keycloak: any): void {
		this.cookie_interval = setInterval(() => {
			this.listen_cookie(keycloak);
		}, 100);

		this.open_window = window.open(environment.auth_web, '_blank');
	}

	logout(keycloak: any): void {
		document.cookie =
			'iudx-ui-sso=logged-out;path=/;max-age=0;domain=' +
			environment.parent_domain;
		localStorage.removeItem('iudx-ui-cat-auth-token');
		localStorage.removeItem('iudx-ui-pub-auth-token');
		localStorage.removeItem('iudx-ui-con-id-token');
		localStorage.removeItem('iudx-ui-pub-id-token');
		localStorage.removeItem('consumer_rs_url');
		localStorage.removeItem('rs_url');
		localStorage.removeItem('iudx-ui-aclapd-token');
		localStorage.removeItem('iudx-ui-dmp-token');
		localStorage.removeItem('consumer_id');
		localStorage.removeItem('provider_id');
		localStorage.removeItem('provider_owner_Details');
		localStorage.removeItem('consumer_owner_Details');
		localStorage.removeItem('dmpFilter');
		localStorage.removeItem('dmpCheckoutDetails');
		localStorage.removeItem('admin_rs_url');
		localStorage.removeItem('isProviderDelegate');
		localStorage.removeItem('provider_delegate_id');
		localStorage.removeItem('consumer_delegate_id');
		setTimeout(() => {
			keycloak.logout(window.location.href);
			this.subscriber.set_toaster(
				'error',
				'You have been logged out. Please login again.'
			);
		}, 100);
	}
}
