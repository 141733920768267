<div class="overlay" (click)="closeCreatePolicy()"></div>
<div class="popup">
	<div class="title">
		<h1>Add Policy</h1>
		<img (click)="closeCreatePolicy()" src="../../../assets/close.png" />
	</div>

	<div class="content">
		<div>
			<label for=""
				>Email<i class="fa-solid fa-star-of-life text-danger"></i
			></label>
			<input
				class="input"
				type="email"
				placeholder="Consumer email"
				[(ngModel)]="email"
			/>
			<span class="text-danger" *ngIf="emailRequired"
				>Email is required</span
			>

			<!-- Datasets dropdown -->
			<div class="my-4">
				<label for="" class="select-label"
					>Choose Dataset<i
						class="fa-solid fa-star-of-life text-danger"
					></i
				></label>
				<ng-multiselect-dropdown
					[placeholder]="'Select Dataset'"
					[settings]="datasetsDropdownSettings"
					[data]="datasetsDropdownList"
					id="datasetId"
					(onSelect)="select_dataset($event)"
					(onDeSelect)="select_dataset($event)"
					[(ngModel)]="selectedDataset"
				>
				</ng-multiselect-dropdown>
				<span class="text-danger" *ngIf="isDatasetSelected"
					>Dataset is required</span
				>
			</div>
			<!-- Resources dropdown -->
			<div class="my-4">
				<label for="" class="select-label"
					>Choose Resource<i
						class="fa-solid fa-star-of-life text-danger"
					></i
				></label>
				<ng-multiselect-dropdown
					[placeholder]="'Select Resource'"
					[settings]="resourceDropdownSettings"
					[data]="resourceDropdownList"
					id="datasetId"
					(onSelect)="select_resource($event)"
					(onDeSelect)="select_resource($event)"
					[(ngModel)]="selectedResource"
				>
				</ng-multiselect-dropdown>
				<span class="text-danger" *ngIf="isResourceSelected"
					>Resource is required</span
				>
			</div>
			<div class="data">
				<p class="tagline">
					Policy expires in<i
						class="fa-solid fa-star-of-life text-danger"
					></i>
				</p>
				<div class="data-list">
					<div class="data-item">
						<label for="">Years</label>
						<input class="input" type="number" [(ngModel)]="year" />
					</div>
					<div class="data-item">
						<label for="">Months</label>
						<input
							class="input"
							type="number"
							[(ngModel)]="month"
						/>
					</div>
					<div class="data-item">
						<label for="">Days</label>
						<input class="input" type="number" [(ngModel)]="day" />
					</div>
				</div>
			</div>
			<span class="text-danger" *ngIf="isExpiryEmpty"
				>Policy Expiry cannot be 0</span
			>
			<div class="access-type-container mb-1">
				<div class="type">
					<span class="access-type ms-1"
						>By clicking on Add policy, You agree to the<a
							target="_blank"
							class="mx-2"
							href="{{termsConditionsLink}}"
							>Terms and Conditions
						</a>
					</span>
				</div>
			</div>
		</div>
		<div class="link justify-content-around d-flex">
			<a (click)="closeCreatePolicy()" class="box text-white">Cancel</a>

			<a (click)="submit()" id="save" class="box text-white"
				>Add Policy</a
			>
		</div>
	</div>
</div>
