import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
	@Input() checked = -1;
	@Input() value = '';
	@Input() id = '';
	@Output() newClickEvent = new EventEmitter<string>();
	@Input() disabledProp: any;
	constructor() {}

	ngOnInit(): void {}

	checkItem() {
		this.newClickEvent.emit();
	}
}
