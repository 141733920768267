import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import { PopupMarkerService } from './popup-marker.service';

@Injectable({
	providedIn: 'root'
})
export class MakerService {
	pointList: any = [];
	plottedResponse: any;
	constructor(
		private http: HttpClient,
		private popupService: PopupMarkerService
	) {}

	makeCapitalMarkers(map: L.Map, mapData: any, zoomLevel: any): void {
		for (const c of mapData.features) {
			const markersLayer = new L.FeatureGroup();
			if (c.geometry.type == 'Point') {
				const lon = c.geometry.coordinates[0];
				const lat = c.geometry.coordinates[1];
				const marker = L.marker([lat, lon]);
				marker.bindPopup(this.popupService.leftletBindPopup(c.id));
				markersLayer.addLayer(marker);
				markersLayer.addTo(map);
			} else if (c.geometry.type == 'MultiPolygon') {
				const id = c.id;
				this.plottedResponse = L.geoJSON(c.geometry, {
					style: {
						weight: 2,
						opacity: 1,
						fillOpacity: 0.5
					}
				})
					.bindPopup(this.popupService.leftletBindPopup(id))
					.addTo(map);
				markersLayer.addTo(map);
				map.setView(
					this.plottedResponse.getBounds().getCenter(),
					zoomLevel
				);
			} else if (c.geometry.type == 'Polygon') {
				const id = c.id;
				this.plottedResponse = L.geoJSON(c.geometry, {
					style: {
						weight: 2,
						opacity: 1,
						fillOpacity: 0.5
					}
				})
					.bindPopup(this.popupService.leftletBindPopup(id))
					.addTo(map);
				markersLayer.addTo(map);
				map.setView(this.plottedResponse.getBounds().getCenter(), 11);
			} else if (c.geometry.type == 'MultiLineString') {
				const id = c.id;
				const polylines = new L.FeatureGroup();
				for (const coordinate of c.geometry.coordinates) {
					for (const latlon of coordinate) {
						this.pointList.push(new L.LatLng(latlon[1], latlon[0]));
						L.geoJSON(c.geometry, {
							style: {
								weight: 2,
								opacity: 1,
								fillOpacity: 0.5
							}
						})
							.bindPopup(this.popupService.leftletBindPopup(id))
							.addTo(map);
						polylines.on('popupopen', function (e) {
							const popup = e.popup;
							popup.setContent(id);
						});
					}
					map.fitBounds(this.pointList);
				}
			}
		}
	}
}
