import { Component, Input, OnInit } from '@angular/core';

import { SubscriberService } from '../../../services/subscriber.service';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
	selector: 'app-available-rs',
	templateUrl: './available-rs.component.html',
	styleUrls: ['./available-rs.component.scss']
})
export class AvailableRsComponent implements OnInit {
	rsList: any = [];
	@Input() rolesToRsMapping: any;
	role: any;
	rsListCopy = [];

	constructor(public subscriber: SubscriberService, private api: ApiService) {
		this.role = localStorage.getItem('role-for-available-rs');
	}

	async ngOnInit(): Promise<void> {
		this.rsList = this.rolesToRsMapping[this.role];
		this.rsListCopy = this.rsList;
	}

	close(): void {
		this.subscriber.set_popup(false, 'view-available-rs');
	}

	search(event: any) {
		this.rsList = this.rsListCopy.filter((item: any) => {
			return item.includes(event.target.value);
		});
	}
}
