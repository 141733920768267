import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	intercept(
		httpRequest: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const delegate_id = httpRequest.headers.get('delegationId');
		const Authorization = httpRequest.headers.get('Authorization');
		const aclapd_token = localStorage.getItem('iudx-ui-aclapd-token');
		const dmp_token = localStorage.getItem('iudx-ui-dmp-token');
		const rs_token = localStorage.getItem('iudx-ui-rs-token');
		if (
			httpRequest.url.includes(environment.base_path_acl_apd_server_url)
		) {
			const newHeaders: any = {
				Authorization: aclapd_token
			};

			if (Authorization) {
				httpRequest = httpRequest.clone({
					headers: new HttpHeaders(newHeaders)
				});
			}
		} else if (
			httpRequest.url.includes(environment.onboarding_server_url)
		) {
			const newHeaders: any = {
				Token: aclapd_token
			};

			if (Authorization) {
				httpRequest = httpRequest.clone({
					headers: new HttpHeaders(newHeaders)
				});
			}
		} else if (httpRequest.url.includes(environment.dmp_server_url)) {
			const newHeaders: any = {
				Token: dmp_token
			};

			if (Authorization) {
				httpRequest = httpRequest.clone({
					headers: new HttpHeaders(newHeaders)
				});
			}
		} else if (
			httpRequest.url.includes(
				environment.resource_server_url + 'ngsi-ld/v1/subscription'
			)
		) {
			const newHeaders: any = {
				token: rs_token,
				options: 'streaming'
			};

			if (Authorization) {
				httpRequest = httpRequest.clone({
					headers: new HttpHeaders(newHeaders)
				});
			}
		} else if (httpRequest.url.includes(environment.resource_server_url)) {
			const newHeaders: any = {
				token: rs_token
			};

			if (Authorization) {
				httpRequest = httpRequest.clone({
					headers: new HttpHeaders(newHeaders)
				});
			}
		} else if (
			httpRequest.url.includes(
				environment.auth_server_url + 'auth/v1/token'
			)
		) {
			let newHeaders: any;
			if (delegate_id) {
				newHeaders = {
					Authorization: Authorization,
					delegationId: delegate_id
				};
			} else {
				newHeaders = {
					Authorization: Authorization
				};
			}

			if (Authorization) {
				httpRequest = httpRequest.clone({
					headers: new HttpHeaders(newHeaders)
				});
			}
		}

		if (
			httpRequest.url.indexOf(
				environment.authorization_server_url + '/iudx/cat/v1/internal'
			) == -1 &&
			httpRequest.url.indexOf(
				environment.authorization_server_url + 'auth'
			) == -1 &&
			httpRequest.url.indexOf(
				environment.authorization_server_url + '/auth'
			) == -1 &&
			httpRequest.url.indexOf(environment.keycloak.url) == -1
		) {
			if (Authorization) {
				httpRequest = httpRequest.clone({
					headers: httpRequest.headers.delete(
						'Authorization',
						Authorization
					)
				});
			}
		}
		return next.handle(httpRequest);
	}
}
