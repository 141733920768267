import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SubscriberService {
	loader_subject = new Subject<any>();
	toast_subject = new Subject<any>();
	popup_subject = new Subject<any>();
	delete_subject = new Subject<any>();
	queryqueue_subject = new Subject<any>();
	url_subject = new Subject<any>();
	search_input_subject = new Subject<any>();
	active_instance_subject = new Subject<any>();
	constructor() {}

	set_active_instance(instance: string): void {
		this.active_instance_subject.next(instance);
	}

	get_active_instance(): Observable<any> {
		return this.active_instance_subject.asObservable();
	}

	set_search_input(searchText: string): void {
		this.toast_subject.next(searchText);
	}

	get_search_input(): Observable<any> {
		return this.toast_subject.asObservable();
	}

	set_loader(flag: boolean): void {
		this.loader_subject.next(flag);
	}

	get_loader(): Observable<any> {
		return this.loader_subject.asObservable();
	}

	set_toaster(type: string, message: string): void {
		this.toast_subject.next({ type, message });
	}

	get_toaster(): Observable<any> {
		return this.toast_subject.asObservable();
	}

	set_popup(flag: boolean, type: string, data?: any): void {
		if (data) {
			this.popup_subject.next({ flag, type, data });
		} else {
			this.popup_subject.next({ flag, type });
		}
	}

	get_popup(): Observable<any> {
		return this.popup_subject.asObservable();
	}

	set_query_queue(): void {
		this.queryqueue_subject.next();
	}

	get_query_queue(): Observable<any> {
		return this.queryqueue_subject.asObservable();
	}

	set_url_path(urlLink: string): void {
		this.url_subject.next(urlLink);
	}
	get_url_path(): Observable<any> {
		return this.url_subject.asObservable();
	}
	set_delete(id: any, tabName?: any): void {
		this.delete_subject.next({ id, tabName });
	}

	get_delete(): Observable<any> {
		return this.delete_subject.asObservable();
	}
}
