import { Injectable } from '@angular/core';
import { SubscriberService } from './subscriber.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	user_profile: any;
	showDropdown = false;
	intro_steps_home: any = {};
	dataset: any = {};
	tab_params: any;
	dataprincipal_id: any;
	data_principal_name: any;
	currentTableId: any;
	delegate_details: { id: any; url: any; email: any; name: any };
	consumer_id: any;
	consumer_resource_server: any;
	dmpFilter: { provider_id: any; resource_id: any };
	dmp_checkout: {
		variantId: any;
		product_name: any;
		provider_name: any;
		product_id: any;
		productVariant: any;
		price: any;
		duration: any;
		resources: any;
	};

	constructor(private subscriber: SubscriberService) {
		this.delegate_details = { id: '', url: '', name: {}, email: '' };
		this.dmpFilter = { provider_id: '', resource_id: '' };
		this.dmp_checkout = {
			provider_name: '',
			product_name: '',
			product_id: '',
			productVariant: '',
			price: '',
			duration: '',
			resources: [],
			variantId: ''
		};
	}

	set_user_profile(value: any): void {
		this.user_profile = value;
	}

	get_user_profile(): any {
		return this.user_profile;
	}
	set_filter_for_tab_switch(
		currentTableId: any,
		dp_id: any,
		dp_name: any
	): void {
		this.dataprincipal_id = dp_id;
		this.data_principal_name = dp_name;
		this.currentTableId = currentTableId;
	}

	get_filter_for_tab_switch(): any {
		const filter = {
			dp_id: this.dataprincipal_id,
			dp_name: this.data_principal_name,
			currentTableId: this.currentTableId
		};
		return filter;
	}
	get_introjs_steps_home(): any {
		return this.intro_steps_home;
	}
	set_introjs_steps_home(step: string, index: any): any {
		this.intro_steps_home[index] = step;
	}
	convertUTCDateToLocalDate(expiry_time: any): any {
		const date = new Date(expiry_time);
		const mlist = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];
		const newDate =
			date.getDate() +
			' ' +
			mlist[date.getMonth()] +
			', ' +
			date.getFullYear() +
			', ' +
			this.getLeadingZero(date.getHours()) +
			':' +
			this.getLeadingZero(date.getMinutes()) +
			':' +
			this.getLeadingZero(date.getSeconds());
		return newDate;
	}

	get_StartDate_EndDate(monthcount: number) {
		const start_date = new Date();
		const end_date = new Date();
		const month = end_date.getMonth();
		start_date.setMonth(end_date.getMonth() + 1 - monthcount);
		if (end_date.getMonth() == month) start_date.setDate(0);
		start_date.setHours(0, 0, 0, 0);
		start_date.setDate(1);
		return { start: start_date.toISOString(), end: end_date.toISOString() };
	}

	getLeadingZero(time: any): any {
		if (time <= 9) {
			return '0' + time;
		} else {
			return time;
		}
	}

	is_login_attempted(): string | null {
		return localStorage.getItem('isLoginAttempted');
	}

	set_login_attempted(value: string): void {
		localStorage.setItem('isLoginAttempted', value);
	}
	set_consumer_delegate_details(id: any, url: any, email?: any, name?: any) {
		this.delegate_details = { id: id, url: url, email: email, name: name };
	}
	get_consumer_delegate_details(): any {
		return this.delegate_details;
	}

	set_consumer_resource_server(value: any): void {
		this.consumer_resource_server = value;
		localStorage.setItem('consumer_rs_url', value);
	}
	get_consumer_resource_server(): any {
		return this.consumer_resource_server;
	}
	remove_login_attempted(): void {
		localStorage.removeItem('isLoginAttempted');
	}

	get_url_without_hash(): string {
		return window.location.href.replace(window.location.hash, '');
	}

	get_name_value(name: string): string {
		if (name === 'api') {
			return 'API';
		} else if (name === 'subs' || name === 'sub') {
			return 'Subscription';
		} else {
			return name[0].toUpperCase() + name.slice(1, name.length);
		}
	}

	setShowDropdown(val: boolean): void {
		this.showDropdown = val;
	}
	set_temp_data(dataset: any): void {
		this.dataset = dataset;
	}
	get_temp_data() {
		return this.dataset;
	}

	/* To copy any Text */
	copyText(val: any, message: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.subscriber.set_toaster('success', message);
	}
	setUrlForTAb(val: any, isFilter: any) {
		this.tab_params = {
			url: val,
			isFilter: isFilter
		};
	}

	setTitle(title: string) {
		this.subscriber.setTitle(title);
	}
	set_dmp_filter_params(provider_id: string, resource_id: string) {
		this.dmpFilter.provider_id = provider_id;
		this.dmpFilter.resource_id = resource_id;
		localStorage.setItem('dmpFilter', JSON.stringify(this.dmpFilter));
	}
	set_dmp_checkout_params(
		provider_name: string,
		product_name: string,
		product_id: string
	) {
		this.dmp_checkout.provider_name = provider_name;
		this.dmp_checkout.product_name = product_name;
		this.dmp_checkout.product_id = product_id;

		localStorage.setItem(
			'dmpCheckoutDetails',
			JSON.stringify(this.dmp_checkout)
		);
	}

	set_dmp_pv_checkout_params(
		product_id: any,
		productVariant: any,
		productName: any,
		price: any,
		duration: any,
		resources: any,
		variantId?: any
	) {
		this.dmp_checkout.product_id = product_id;

		this.dmp_checkout.productVariant = productVariant;
		this.dmp_checkout.price = price;
		this.dmp_checkout.duration = duration;
		this.dmp_checkout.resources = resources;
		this.dmp_checkout.product_name = productName;
		this.dmp_checkout.variantId = variantId;
		localStorage.setItem(
			'dmpCheckoutDetails',
			JSON.stringify(this.dmp_checkout)
		);
	}

	getDefaultServerConsumer() {
		const consumer_owner_Details_string = localStorage.getItem(
			'consumer_owner_details'
		);
		const defaultServer = consumer_owner_Details_string
			? JSON.parse(consumer_owner_Details_string).url
			: environment.rs_item;
		return defaultServer;
	}
}
