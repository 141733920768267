import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { GlobalService } from 'src/app/catalogue/shared/services/global.service';
import { KeycloakUtilService } from 'src/app/catalogue/shared/services/keycloak-util.service';
import { ApiService as SharedApiService } from 'src/app/shared/services/api.service';
import { RolesModalComponent } from 'src/app/shared/components/roles-modal/roles-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-provider-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	profile: any;
	is_provider: boolean;
	roles: any;
	consumerDelegate: any;
	providerDelegate: any;
	redirectArray: any[];
	enableSwitchPanel = false;
	singleRoleName: string;
	filteredProviderDelegates: any;
	constructor(
		public globalCat: GlobalService,
		public keycloak: KeycloakService,
		public shared_api: SharedApiService,
		public keycloakUtilCat: KeycloakUtilService,
		private modalService: NgbModal
	) {
		this.profile = {};
		this.is_provider = false;
		this.profile = this.globalCat.get_user_profile();
		this.filteredProviderDelegates =
			this.globalCat.getFilteredProviderDelegates();
		this.is_provider =
			this.profile?.roles?.includes('provider') ||
			this.filteredProviderDelegates?.length > 0;
		this.roles = this.profile.roles;
		this.redirectArray = [];
		this.singleRoleName = '';
	}

	async ngOnInit(): Promise<void> {
		if (this.roles?.length === 1) {
			if (this.roles[0] == 'delegate') {
				await this.get_delegations();

				if (this.consumerDelegate && this.providerDelegate) {
					this.redirectArray?.push('consumer delegate');
					if (this.filteredProviderDelegates?.length > 0) {
						this.redirectArray?.push('provider delegate');
						this.enableSwitchPanel = true;
					}
				} else if (this.consumerDelegate) {
					this.singleRoleName = 'consumer delegate';
				} else if (this.providerDelegate) {
					this.singleRoleName = 'provider delegate';
				} else {
					this.singleRoleName = 'catalogue';
				}
			} else {
				this.singleRoleName = this.roles[0];
				if (
					this.singleRoleName === 'consumer' ||
					this.singleRoleName === 'consumer delegate'
				) {
					this.enableSwitchPanel = true;
				}
			}
		} else if (this.redirectArray?.length > 1) {
			//enable switch panel
			this.enableSwitchPanel = true;
		} else {
			this.enableSwitchPanel = false;
		}
	}

	async get_delegations() {
		const response: any = await this.shared_api.get_delegations();
		const uniqueIds: any = [];

		response?.results?.filter((element: any) => {
			const isDuplicate = uniqueIds.includes(element.role);

			if (!isDuplicate) {
				uniqueIds.push(element.role);
			}
		});
		this.consumerDelegate = uniqueIds.includes('consumer') ? true : false;
		this.providerDelegate = uniqueIds.includes('provider') ? true : false;
	}
	async go_to_profile() {
		if (this.roles?.includes('delegate')) {
			await this.get_delegations();
		}
		if (
			(this.roles?.includes('consumer') || this.consumerDelegate) &&
			!this.roles?.includes('catalogue')
		) {
			this.roles.push('catalogue');
		}
		const modalRef = this.modalService.open(RolesModalComponent, {
			centered: true,
			size: 'lg'
		});
		modalRef.componentInstance.roles = this.roles;
		modalRef.componentInstance.title = 'Choose your panel to explore !!';
	}
	navigateToPanel(role: string) {
		if (role === 'provider') {
			window.open(environment.catalogue_web + 'provider', '_self');
		} else if (role === 'consumer') {
			window.open(environment.catalogue_web + 'consumer', '_self');
		} else if (role === 'cos_admin') {
			window.open(environment.catalogue_web + 'cop-admin', '_self');
		} else if (role === 'admin') {
			window.open(environment.catalogue_web + 'rs-admin', '_self');
		} else if (role === 'provider delegate') {
			window.open(environment.catalogue_web + 'provider', '_self');
		} else if (role === 'consumer delegate') {
			window.open(environment.catalogue_web + 'consumer', '_self');
		} else if (role === 'catalogue') {
			window.open(environment.catalogue_web, '_self');
		}
	}
	logout(): void {
		this.keycloakUtilCat.logout(this.keycloak);
		localStorage.removeItem('rs_url');
	}
}
