import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubscriberService } from './subscriber.service';

@Injectable({
	providedIn: 'root'
})
export class NetworkService {
	constructor(
		private global: GlobalService,
		private http: HttpClient,
		public subscriber: SubscriberService
	) { }

	get_api(url: string, header?: any, showLoader = true) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_url(url), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						showLoader && this.subscriber.set_loader(false);
						// this.subscriber.set_toaster('success', data.detail);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	post_api(url: string, post_body: any, header?: any, showToaster = true) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.post(this.get_url(url), post_body, {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster('success', data.title)
							: '';
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	post_api_multiHeader(
		url: string,
		post_body: any,
		header?: any,
		showLoader = true
	): Promise<any> {
		showLoader && this.subscriber.set_loader(true);
		let headers = new HttpHeaders();
		headers = headers.set('token', `${header?.token}`);
		return new Promise((resolve, reject) => {
			this.http
				.post(this.get_url(url), post_body, {
					headers: headers
				})
				.subscribe(
					async (data: any) => {
						showLoader && this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}
	put_api(url: string, put_body: any, header?: any) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.put(this.get_url(url), put_body, {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('success', data.title);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	delete_api(url: string, header?: any, showToaster = true) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.delete(this.get_url(url), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster('success', data.title)
							: '';
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster(
								'error',
								err.error?.detail
							)
							: '';
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	handleErrors(err: any): void {
		if (err.status == 400 || err.status == 404)
			this.technicalErrorAlert(err);
		else if (
			err.status == 500 ||
			err.status == 501 ||
			err.status == 502 ||
			err.status == 503 ||
			err.status == 504
		) {
			this.serverErrorAlert();
		} else if (err.status) {
			this.subscriber.set_popup(false, 'gs-map');
			document.body.style.overflowY = '';
		} else {
			//Excluding the unable to parse issues (GSX, download netcdf issue)
			if (err.status != 200) {
				this.subscriber.set_toaster(
					'error',
					err.statusText +
					' statusCode:  ' +
					err.status +
					' (HTTP method failed)'
				);
			}
		}
	}

	serverErrorAlert(): void {
		this.subscriber.set_toaster('error', 'Network error. Try again later.');
	}

	technicalErrorAlert(err: any): void {
		this.subscriber.set_toaster('error', err.error?.detail);
	}

	get_url(url: string): string {
		if (url.indexOf('http') === -1) {
			return environment.resource_server_url + url;
		}
		return url;
	}

	get_cat_api(url: string, header?: any, showLoader = true) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_cat_url(url), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						showLoader && this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	post_cat_api(url: string, post_body: any, header?: any, showLoader = true) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.post(this.get_cat_url(url), post_body, {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						showLoader && this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	get_cat_url(url: string): string {
		if (url.indexOf('http') === -1) {
			return environment.catalogue_server_url + url;
		}
		return url;
	}

	// TODO: Need refactoring
	post_rating_api(
		url: string,
		post_body: any,
		header?: any,
		showLoader = true
	) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.post(url, post_body, {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						showLoader && this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	// Golang File Server API
	get_gsf_api(url: string, header?: any, showLoader = true) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_gsf_url(url), {
					headers: new HttpHeaders(header)
				})
				.subscribe(
					async (data: any) => {
						showLoader && this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						this.handleErrors(err);
						reject(err);
					}
				);
		});
	}

	// Get Golang File Server URL
	get_gsf_url(url: string): string {
		if (url.indexOf('http') === -1) {
			return environment.golang_file_server_url + url;
		}
		return url;
	}
}
