import { Component, OnInit } from '@angular/core';
import { SubscriberService } from '../../../services/subscriber.service';

import { GlobalService } from '../../../services/global.service';
import { GlobalService as GlobalServiceCat } from 'src/app/catalogue/shared/services/global.service';

import { ApiService as SharedApi } from 'src/app/shared/services/api.service';
import { ApiService as apiParent } from 'src/app/shared/services/api.service';
import { ApiService } from '../../../services/api.service';

import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-consumer-policy-create',
	templateUrl: './consumer-policy-create.component.html',
	styleUrls: ['./consumer-policy-create.component.scss']
})
export class ConsumerPolicyCreateComponent implements OnInit {
	email_error = '';
	email: string;
	profile: any;
	year = 1;
	month = 0;
	day = 0;
	datasets_list: any = [];
	result: any = [];
	showDropdown = false;

	//Datasets DROPDOWN
	datasetsDropdownList: any = [];
	datasetsDropdownSettings = {};
	selectedDataset: any = {};

	//Resources DROPDOWN
	resourceDropdownList: any = [];
	resourceDropdownSettings = {};
	selectedResource: any = {};

	emailRequired = false;
	isDatasetSelected = false;
	isResourceSelected = false;
	isExpiryEmpty = false;
	is_provider: any;
	selectedResourceServer: any;
	termsConditionsLink = '';

	constructor(
		public subscriber: SubscriberService,
		public global: GlobalService,
		public global_cat: GlobalServiceCat,
		public api: ApiService,
		public shared_api: SharedApi,
		private apiParent: apiParent
	) {
		this.termsConditionsLink = environment.terms_and_conditions_link;
		this.email = '';
		this.selectedResourceServer = localStorage.getItem(
			'selectedResourceServer'
		);
		const user_profile = this.global_cat.get_user_profile();
		if (user_profile?.roles?.includes('provider')) {
			this.is_provider = true;
		} else {
			this.is_provider = false;
		}

		//Datasets DROPDOWN
		this.datasetsDropdownList = [];
		this.selectedDataset = [];
		this.datasetsDropdownSettings = {
			singleSelection: true,
			closeDropDownOnSelection: true,
			idField: 'item_id',
			textField: 'item_text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			allowSearchFilter: true
		};

		//Resources DROPDOWN
		this.resourceDropdownList = [];
		this.selectedResource = [];
		this.resourceDropdownSettings = {
			singleSelection: true,
			closeDropDownOnSelection: true,
			idField: 'item_id',
			textField: 'item_text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			allowSearchFilter: true
		};
	}

	async ngOnInit(): Promise<void> {
		// await this.listOnboardedDatasets();
		await this.get_datasets();
	}

	toggleDropdown(): void {
		this.showDropdown = !this.showDropdown;
	}

	async get_datasets() {
		let user_id;

		if (this.is_provider) {
			user_id = this.global.get_provider_id();
		} else {
			user_id = this.global.getProviderDelegateOwner();
		}
		const data = await this.api.get_provider_id(user_id);
		const results = data.results;
		const provider_id = results[0].id;

		//list datasets using provider id
		const datasets = await this.api.get_datasets_list_with_id(provider_id);
		this.datasets_list = datasets.results;
		this.result = this.datasets_list;
		const temp = [];
		for (const dataset of this.datasets_list) {
			const dataset_details = await this.api.get_dataset_details_api(
				dataset.id
			);
			if (
				dataset_details.results[0].resourceServerRegURL ===
				this.selectedResourceServer
			) {
				temp.push({ item_id: dataset.id, item_text: dataset.label });
			}
		}
		temp.sort((a: any, b: any) => (a.item_text > b.item_text ? 1 : -1));
		this.datasetsDropdownList = temp;
	}

	async select_dataset(event: any) {
		this.selectedDataset = [];
		this.selectedResource = [];
		this.selectedDataset = [
			{ item_id: event.item_id, item_text: event.item_text }
		];
		this.get_resources(this.selectedDataset[0].item_id);
		this.validate();
	}

	async get_resources(datasetId: any) {
		//list resources with datasetID
		const resources = await this.api.get_resources_list(datasetId);
		this.result = resources?.results;
		const temp = [];
		for (const resource of this?.result) {
			temp.push({ item_id: resource.id, item_text: resource.label });
		}
		temp.sort((a: any, b: any) => (a.item_text > b.item_text ? 1 : -1));
		this.resourceDropdownList = temp;
	}

	async select_resource(event: any) {
		this.selectedResource = [];
		this.selectedResource = [
			{ item_id: event.item_id, item_text: event.item_text }
		];
		this.validate();
	}

	closeCreatePolicy(): any {
		this.subscriber.set_popup(false, 'create-policy', false);
	}

	convertDurationToNewTime(): any {
		const currentDate = new Date();
		const cDay = currentDate.getDate();
		const cMonth = currentDate.getMonth();
		const cYear = currentDate.getFullYear();
		const nyear = this.year + cYear;
		const nmonth = this.month + cMonth;
		const nday = this.day + cDay;
		const utcDate1 = new Date(Date.UTC(nyear, nmonth, nday));
		return utcDate1.toISOString();
	}

	validate() {
		this.email == ''
			? (this.emailRequired = true)
			: (this.emailRequired = false);

		this.selectedDataset.length === 0
			? (this.isDatasetSelected = true)
			: (this.isDatasetSelected = false);

		this.selectedResource.length === 0
			? (this.isResourceSelected = true)
			: (this.isResourceSelected = false);

		this.day === 0 && this.month === 0 && this.year === 0
			? (this.isExpiryEmpty = true)
			: (this.isExpiryEmpty = false);

		if (
			this.email == '' ||
			this.selectedDataset.length === 0 ||
			this.selectedResource.length === 0 ||
			(this.day === 0 && this.month === 0 && this.year === 0)
		) {
			return false;
		}
		return true;
		//TO DO: FOr later, for now empty validations only
		// if (this.year < 0) {
		// 	return this.subscriber.set_toaster(
		// 		'error',
		// 		`Year should be greater than or equal to 0 `
		// 	);
		// }
		// if (this.year > 10) {
		// 	return this.subscriber.set_toaster(
		// 		'error',
		// 		`Year should be less than or equal to 10 `
		// 	);
		// }
		// if (this.day < 0 || this.day > 31) {
		// 	this.subscriber.set_toaster('error', 'Days should be between 1-31');
		// }
		// if (this.month < 0 || this.month > 12) {
		// 	return this.subscriber.set_toaster(
		// 		'error',
		// 		'Month' + ' should be between 0-12'
		// 	);
		// }
	}

	async submit() {
		if (this.validate()) {
			try {
				const expiryDate = this.setExpiry();
				const post_body: any = {
					request: []
				};
				post_body.request = [
					{
						userEmail: this.email,
						itemId: this.selectedResource[0].item_id,
						itemType: 'RESOURCE',
						expiryTime: expiryDate,
						constraints: {
							access: ['api', 'sub', 'file', 'async']
						}
					}
				];

				let body_token;
				let header;
				if (this.is_provider) {
					body_token = {
						itemId: this.selectedResourceServer,
						itemType: 'resource_server',
						role: 'provider'
					};
				} else {
					body_token = {
						itemId: this.selectedResourceServer,
						itemType: 'resource_server',
						role: 'delegate'
					};
					header = 'delegate_id';
				}
				const accessToken = await this.apiParent.get_id_token(
					body_token,
					header
				);

				await this.api.create_policies(accessToken, post_body);
				this.subscriber.set_popup(false, 'create-policy', true);
				this.subscriber.set_temp_data('acl create policy successful');
			} catch (error) {
				const err: any = error;
				this.subscriber.set_toaster('error', err.error?.detail);
			}
		}
	}

	//utility functions
	setExpiry() {
		const currentDate = new Date();
		const expiryDate = this.addTimeToDate(
			currentDate,
			this.year,
			this.month,
			this.day
		);

		const formattedString = this.formatISOString(expiryDate.toISOString());
		return formattedString;
	}

	addTimeToDate(
		date: Date,
		years: number,
		months: number,
		days: number
	): any {
		//handle no expiryDate set case
		if (
			(this.year === 0 && this.month === 0 && this.day === 0) ||
			(this.year === null && this.month === null && this.day === null)
		) {
			return this.subscriber.set_toaster(
				'error',
				'Please chooose expiry duration'
			);
		} else if (this.year < 0 || this.month < 0 || this.day < 0) {
			return this.subscriber.set_toaster('error', 'Invalid input');
		} else {
			const result = new Date(date);
			result.setFullYear(result.getFullYear() + years);
			result.setMonth(result.getMonth() + months);
			result.setDate(result.getDate() + days);
			return result;
		}
	}

	formatISOString(isoString: any) {
		// Split the ISO string to remove milliseconds and "Z" character
		const parts = isoString.split('.');
		if (parts.length > 0) {
			return parts[0];
		}
		return isoString; // Return the original string if there are no milliseconds
	}
}
