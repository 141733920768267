import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { SubscriberService } from './../../catalogue/shared/services/subscriber.service';

@Injectable({
	providedIn: 'root'
})
export class NetworkService {
	current_role: any;
	headers = new HttpHeaders();
	constructor(
		private http: HttpClient,
		private global: GlobalService,
		public subscriber: SubscriberService
	) {}

	get_token() {
		const token: any = this.global.get_token();
		if (token) {
			return token;
		} else {
			return '';
		}
	}

	async get_api(url: string, type = 'auth', showToaster = false) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.get(this.get_url(url, type), {
					headers: new HttpHeaders({
						Authorization: this.get_token()
					})
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster(
									'error',
									err.error?.detail
							  )
							: '';
						if (err.status != 404) this.error_alert(err);
						reject(err);
					}
				);
		});
	}
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	async post_api(
		url: string,
		post_body: Record<string, unknown>,
		type = 'auth',
		showToaster = false
	) {
		this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.post(this.get_url(url, type), post_body, {
					headers: new HttpHeaders({
						Authorization: this.get_token()
					})
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('success', data.title);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						showToaster
							? this.subscriber.set_toaster(
									'error',
									err.error?.detail
							  )
							: '';
						if (err.status != 404) this.error_alert(err);
						reject(err);
					}
				);
		});
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	async put_api(
		url: string,
		put_body: Record<string, unknown>,
		showLoader = true
	) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.put(environment.authorization_server_url + url, put_body, {
					headers: new HttpHeaders({
						Authorization: this.get_token()
					})
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						if (err.status != 404) this.error_alert(err);
						reject(err);
					}
				);
		});
	}

	async delete_api(url: string, showLoader = true) {
		showLoader && this.subscriber.set_loader(true);
		return new Promise((resolve, reject) => {
			this.http
				.delete(environment.authorization_server_url + url, {
					headers: new HttpHeaders({
						Authorization: this.get_token()
					})
				})
				.subscribe(
					async (data: any) => {
						this.subscriber.set_loader(false);
						resolve(data);
					},
					async (err) => {
						this.subscriber.set_loader(false);
						this.subscriber.set_toaster('error', err.error?.detail);
						if (err.status != 404) this.error_alert(err);
						reject(err);
					}
				);
		});
	}

	error_alert(err: any) {
		this.global.set_toaster('error', err.error?.detail);
	}
	get_url(url: string, type = 'auth'): string {
		if (type == 'auth') {
			if (url.indexOf('http') === -1) {
				return environment.auth_server_url + url;
			}
		}
		return url;
	}
}
