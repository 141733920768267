import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SubscriberService {
	loader_subject = new Subject<any>();
	toast_subject = new Subject<any>();
	popup_subject = new Subject<any>();
	tag_subject = new Subject<any>();
	temp_data_subject = new Subject<any>();
	datatable_subject = new Subject<any>();
	ppb_subject = new Subject<any>();
	delete_subject = new Subject<any>();
	messageSubject = new BehaviorSubject(undefined);
	title = new BehaviorSubject(undefined);
	audit_subject = new BehaviorSubject(undefined);
	overview_subject = new BehaviorSubject(undefined);
	owner_subject = new Subject<any>();

	constructor() {
		const storedProp = localStorage.getItem('storedProp');
		if (storedProp) {
			this.setTitle(storedProp, false);
		}

		const auditData = localStorage.getItem('storeAuditData');
		if (auditData) {
			this.setAuditDetails(JSON.parse(auditData), false);
		}

		const overviewData = localStorage.getItem('overViewData');
		if (overviewData) {
			this.setOverviewData(JSON.parse(overviewData), false);
		}
	}

	set_loader(flag: boolean): void {
		this.loader_subject.next(flag);
	}

	get_loader(): Observable<any> {
		return this.loader_subject.asObservable();
	}

	set_toaster(type: string, message: string): void {
		this.toast_subject.next({ type, message });
	}

	get_toaster(): Observable<any> {
		return this.toast_subject.asObservable();
	}

	set_tagId(id: string): void {
		this.tag_subject.next({ id });
	}

	get_tagId(): Observable<any> {
		return this.tag_subject.asObservable();
	}
	set_delete(id: any, tabName?: any): void {
		this.delete_subject.next({ id, tabName });
	}

	get_delete(): Observable<any> {
		return this.delete_subject.asObservable();
	}

	set_popup(flag: boolean, type: string, isChanged?: boolean): void {
		this.popup_subject.next({ flag, type, isChanged });
	}

	get_popup(): Observable<any> {
		return this.popup_subject.asObservable();
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	set_datatable(data: Array<object>): void {
		this.datatable_subject.next({ data });
	}

	get_datatable(): Observable<any> {
		return this.datatable_subject.asObservable();
	}

	setData(message: any) {
		this.messageSubject.next(message);
	}

	getData(): Observable<any> {
		return this.messageSubject.asObservable();
	}

	setTitle(title: any, storeProp = false) {
		if (!storeProp) localStorage.setItem('storedProp', title);
		this.title.next(title);
	}

	getTitle(): Observable<any> {
		return this.title.asObservable();
	}

	setAuditDetails(property: any, storeProp = false) {
		if (!storeProp)
			localStorage.setItem('storeAuditData', JSON.stringify(property));
		this.audit_subject.next(property);
	}

	getAuditDetails(): Observable<any> {
		return this.audit_subject.asObservable();
	}

	setOverviewData(property: any, storeProp = false) {
		if (!storeProp)
			localStorage.setItem('overViewData', JSON.stringify(property));
		this.overview_subject.next(property);
	}

	getOverviewData(): Observable<any> {
		return this.overview_subject.asObservable();
	}
	// eslint-disable-next-line @typescript-eslint/ban-types
	set_datatable_ppb(data: Array<object>): void {
		this.ppb_subject.next({ data });
	}

	get_datatable_ppb(): Observable<any> {
		return this.ppb_subject.asObservable();
	}

	get_temp_data(): Observable<any> {
		return this.temp_data_subject.asObservable();
	}
	set_temp_data(data: any): void {
		this.temp_data_subject.next(data);
	}

	set_provider_delegate(data: any) {
		this.owner_subject.next(data);
	}

	get_provider_delegate(): Observable<any> {
		return this.owner_subject.asObservable();
	}
}
