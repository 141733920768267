<div class="tab-container">
	<ul class="nav">
		<li
			*ngFor="let url of tabs.urls;let i=index;"
			[ngClass]="currentTab === tabs.names[i] || url=== activeTabUrl  ? 'active':''"
			class="nav-item"
			routerLinkActive="active"
			[routerLinkActiveOptions]="{exact: true}"
		>
			<a class="nav-link" (click)="onTabChange(url,i)">
				<span *ngIf="tabs.names[i]!=='DSA'">{{tabs.names[i]}}</span>
				<span *ngIf="tabs.names[i]==='DSA'"
					>DATA SHARING AGREEMENT(DSA)</span
				>
			</a>
		</li>
	</ul>
</div>

<div>
	<router-outlet></router-outlet>
</div>
