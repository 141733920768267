<div class="modal-container">
	<div class="modal-header">
		<div class="row w-100">
			<div class="col-10">
				<h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
			</div>
			<i
				(click)="activeModal.dismiss('Cross click')"
				class="col-2 fa-solid fa-xmark d-flex justify-content-end text-white f2-bolder"
			></i>
		</div>
	</div>
	<div class="modal-body row">
		<div class="card col-4 border-0" *ngFor="let role of redirectArray">
			<a
				class="card1"
				[href]="role.url"
				*ngIf="role.name!=='provider delegate' && role.name!=='provider'"
			>
				<p class="role-name">{{ role.name }}</p>
				<p class="role-description">{{ role.description }}</p>
				<div class="go-corner" href="#">
					<div class="go-arrow">→</div>
				</div>
			</a>
			<a
				class="card1"
				[href]="role.url"
				(click)="selectProviderDelegate()"
				*ngIf="role.name=='provider delegate'"
			>
				<p class="role-name">{{ role.name }}</p>
				<p class="role-description">{{ role.description }}</p>
				<div class="go-corner" href="#">
					<div class="go-arrow">→</div>
				</div>
			</a>
			<a
				class="card1"
				[href]="role.url"
				(click)="selectProvider()"
				*ngIf="role.name=='provider'"
			>
				<p class="role-name">{{ role.name }}</p>
				<p class="role-description">{{ role.description }}</p>
				<div class="go-corner" href="#">
					<div class="go-arrow">→</div>
				</div>
			</a>
		</div>
	</div>
</div>
