import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-add-tags',
	templateUrl: './add-tags.component.html',
	styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent implements OnInit {
	@Output() addTagsEvent = new EventEmitter<string[]>();
	@Input() tags: string[] = [];
	tagText = '';

	constructor() {}

	ngOnInit(): void {}

	addTag(): void {
		if (this.tagText && this.tags.indexOf(this.tagText) === -1) {
			this.tags.push(this.tagText);
			this.tagText = '';
		}
		this.addTagsEvent.emit(this.tags);
	}

	removeTag(tag: string): void {
		const index = this.tags.indexOf(tag);
		if (index !== -1) {
			this.tags.splice(index, 1);
		}
	}

	// resetTags(): void {
	// 	this.tags = [];
	// 	this.addTagsEvent.emit(this.tags);
	// }
}
