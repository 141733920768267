<form>
	<label class="choose-role d-flex justify-content-between mx-3 my-2">
		<span
			>Choose Roles<i class="fa-solid fa-star-of-life text-danger"></i>
		</span>
		<i
			(click)="activeModal.dismiss('Cross click')"
			class="fa-solid fa-xmark d-flex justify-content-end text-black f2-bolder"
		></i>
	</label>
	<span class="text-danger ms-4" *ngIf="atleastOneRoleSelected"
		>{{atleastOneRoleSelected}}</span
	>
	<hr />
	<div class="capabilities mb-5 d-flex">
		<div class="form-check d-flex me-3">
			<app-checkbox
				[checked]="alreadyConsumer?1:-1"
				(newClickEvent)="toggleCapability('consumer')"
				[value]="'Consumer'"
				[id]="'consumerCheckbox'"
				[disabledProp]="alreadyConsumer"
			></app-checkbox>
			<label class="form-check-label" for="consumerCheckbox">
				Consumer
			</label>
			<span *ngIf="alreadyConsumer" class="ms-1">
				<img
					class="position-relative"
					src="../../../assets/information.svg"
					width="16vw"
					style="top: -1px"
					ngbTooltip="you are already a consumer"
				/>
			</span>
			<span *ngIf="!alreadyConsumer" class="ms-1">
				<img
					class="position-relative"
					src="../../../assets/information.svg"
					width="16vw"
					style="top: -1px"
					ngbTooltip="On sending the request as Consumer, will gain access to all the resource server immedietly without any approval."
				/>
			</span>
		</div>

		<div class="input-group w-50" [ngClass]="isConsumer ? '': 'd-none'">
			<ng-multiselect-dropdown
				class="position-absolute w-100"
				[placeholder]="'View selected Resource Server'"
				[settings]="serverDropdownSettings"
				[data]="serverDropdownList"
				id="datasetId"
				[(ngModel)]="selectedConsumerServer"
				[disabled]="disable"
				[ngModelOptions]="{standalone: true}"
			>
			</ng-multiselect-dropdown>
			<label for="">Select RS</label>
		</div>
	</div>

	<div class="capabilities d-flex">
		<div class="form-check d-flex me-3">
			<app-checkbox
				[checked]="disableProvider?1:-1"
				[value]="'Provider'"
				[id]="'providerCheckbox'"
				(newClickEvent)="toggleCapability('provider')"
				[disabledProp]="providerDropdownList.length===0"
			></app-checkbox>
			<label
				class="form-check-label provider-label"
				for="providerCheckbox"
			>
				Provider
			</label>
			<span>
				<img
					class="position-relative"
					src="../../../assets/information.svg"
					width="16vw"
					style="top: -1px"
					ngbTooltip="On sending the request as Provider, you will have to wait for the apporval of this role."
				/>
			</span>
		</div>

		<div
			class="input-group w-50"
			[ngClass]="isProvider ? '': 'd-none'"
			*ngIf="!disableProvider"
		>
			<ng-multiselect-dropdown
				class="w-100 provider-multi-select"
				[placeholder]="'Select Resource Server'"
				[settings]="providerDropdownSettings"
				[data]="providerDropdownList"
				id="datasetId1"
				(onSelect)="validate()"
				(onDeSelect)="validate()"
				[(ngModel)]="selectedServer"
				[ngModelOptions]="{standalone: true}"
			>
			</ng-multiselect-dropdown>
			<span class="text-danger" *ngIf="isServerSelected"
				>Resource Server is required</span
			>
		</div>
	</div>
	<div *ngIf="(isProvider)&&(!alreadyProvider)" class="body">
		<form
			class="w-100"
			[formGroup]="providerForm"
			autocomplete="off"
			[ngClass]="isProvider ? '': 'd-none'"
		>
			<div class="organization mt-lg-3 p-0">
				<div class="form-fields">
					<div class="d-flex justify-content-between">
						<div class="my-2 me-3 flex-fill">
							<label
								>Name
								<i
									class="fa-solid fa-star-of-life text-danger"
								></i
							></label>
							<input
								type="text"
								class="form-control"
								formControlName="providerName"
								id="providerName"
							/>
							<span
								class="text-danger"
								[ngClass]="((providerForm.controls.providerName.touched || providerFormValid) && providerForm.controls.providerName.status==='INVALID')?'visible':'invisible'"
								>Name is required</span
							>
						</div>
						<div class="my-2 ms-3 flex-fill">
							<label for="additionalInfoUrl" class="form-label"
								>Description
								<i
									class="fa-solid fa-star-of-life text-danger"
								></i
							></label>
							<input
								type="text"
								class="form-control"
								formControlName="providerDescription"
								id="providerDescription"
							/>
							<span
								class="text-danger"
								[ngClass]="((providerForm.controls.providerDescription.touched || providerFormValid) && providerForm.controls.providerDescription.status==='INVALID')?'visible':'invisible'"
								>Description is required</span
							>
						</div>
					</div>
					<h3 class="my-lg-4">Organization details</h3>

					<div class="d-flex justify-content-between">
						<div class="my-2 me-3 flex-fill">
							<label
								>Name
								<i
									class="fa-solid fa-star-of-life text-danger"
								></i
							></label>
							<input
								type="text"
								class="form-control"
								formControlName="OrganizationName"
								[readonly]="true"
							/>
						</div>
						<div class="my-2 ms-3 flex-fill">
							<label for="additionalInfoUrl" class="form-label"
								>Additional Info Url
								<i
									class="fa-solid fa-star-of-life text-danger"
								></i
							></label>
							<input
								type="text"
								class="form-control"
								formControlName="additionalInfoUrl"
								id="additionalInfoUrl"
							/>
							<span
								class="text-danger"
								[ngClass]="((providerForm.controls.additionalInfoUrl.touched || providerFormValid) && providerForm.controls.additionalInfoUrl.status==='INVALID')?'visible':'invisible'"
								>Additional Info Url is required</span
							>
						</div>
					</div>
					<div
						class="d-flex justify-content-between align-items-center"
					>
						<div class="my-2 me-3 flex-fill col-6">
							<label for="organisationAddress" class="form-label"
								>Address
								<i
									class="fa-solid fa-star-of-life text-danger"
								></i
							></label>
							<input
								type="text"
								class="form-control"
								formControlName="organisationAddress"
								id="organisationAddress"
							/>
							<span
								class="text-danger"
								[ngClass]="((providerForm.controls.organisationAddress.touched || providerFormValid) && providerForm.controls.organisationAddress.status==='INVALID')?'visible':'invisible'"
								>Address is required</span
							>
						</div>
						<div class="ms-3 col-6">
							<div class="mt-lg-5 flex-fill row">
								<div class="col-12">
									<span
										class="cust-btn me-3 ps-3"
										(click)="openLocationPickerModal()"
									>
										<i
											class="fa-solid fa-location-dot fa-lg me-2"
										></i
										>Location Picker
									</span>
								</div>
								<div class="col-6">
									<span class="fw-bold ps-5"
										>Latitude:
										<span class="text-success me-3"
											>{{" "+coordinates.lat |
											number:'1.2-2'}}</span
										>
									</span>
								</div>
								<div class="col-6">
									<span class="fw-bold"
										>Longitude:<span
											class="text-success me-3"
											>{{" "+coordinates.lng |
											number:'1.2-2'}}</span
										>
									</span>
								</div>
							</div>
							<span
								class="text-danger"
								*ngIf="isCoordinatesSelected"
								>Location is required</span
							>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="d-flex justify-content-end mt-lg-3">
		<button
			class="register-button mx-0 w-100 d-flex justify-content-end pb-lg-3"
		>
			<span class="box w-25" (click)="submit($event)">
				Update Profile
			</span>
		</button>
	</div>
</form>
