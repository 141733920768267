import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { GlobalService as global_consumer } from 'src/app/consumer/shared/services/global.service';

@Injectable({
	providedIn: 'root'
})
export class AuthorizationService {
	res_token: any;
	cat_token: any;
	subcription_token: any;

	constructor(
		public network: NetworkService,
		public global: GlobalService,
		private global_consumer: global_consumer
	) {
		this.res_token = {
			public: '',
			private: ''
		};

		this.cat_token = {
			token: ''
		};

		this.subcription_token = {
			token: ''
		};
	}

	get_auth_token(): string | null {
		return localStorage.getItem('iudx-ui-cat-auth-token');
	}

	set_auth_token(token: any): void {
		localStorage.setItem('iudx-ui-cat-auth-token', token);
	}

	get_headers(params?: string): any {
		const headers: any = {};
		const token = this.get_auth_token();
		if (token) {
			headers['Authorization'] = token;
		}
		if (params == 'consumer_delegate_id') {
			const consumer_id = localStorage.getItem('consumer_delegate_id');
			const delegate_details =
				this.global_consumer.get_consumer_delegate_details();
			headers['delegationId'] = consumer_id
				? consumer_id
				: delegate_details.id;
		}
		return headers;
	}

	get_res_token(key: any): any {
		return this.res_token[key];
	}

	set_res_token(key: any, value: any): any {
		this.res_token[key] = value;
	}

	get_cat_token(key: any): any {
		return this.cat_token[key];
	}

	set_cat_token(key: any, value: any): any {
		this.cat_token[key] = value;
	}

	get_subcription_token(key: any): any {
		return this.subcription_token[key];
	}

	set_subcription_token(key: any, value: any): any {
		this.subcription_token[key] = value;
	}

	async check_valid_token(token: any): Promise<any> {
		const post_body = {
			accessToken: token
		};
		const header = this.get_headers();
		return await this.network.post_api(
			environment.authorization_server_url + 'auth/v1/introspect',
			post_body,
			header
		);
	}

	async get_res_public_token(body?: any): Promise<any> {
		const isTokenNeeded = true;
		if (isTokenNeeded) {
			let post_body: any;
			if (!body) {
				post_body = {
					itemId: environment.rs_item,
					itemType: 'resource_server',
					role: 'consumer'
				};
			} else {
				post_body = body;
			}
			const header = this.get_headers();
			const token_resp: any = await this.network.post_api(
				environment.authorization_server_url + 'auth/v1/token',
				post_body,
				header
			);
			if (
				token_resp &&
				token_resp.results &&
				token_resp.results.accessToken
			) {
				this.set_res_token('public', token_resp.results.accessToken);
			} else return false;
		}
		return true;
	}

	async get_cat_public_token(): Promise<any> {
		const isTokenNeeded = true;

		if (isTokenNeeded) {
			const post_body = {
				itemId: environment.rs_item,
				itemType: 'resource_server',
				role: 'consumer'
			};
			const header = this.get_headers();
			const token_resp: any = await this.network.post_cat_api(
				environment.authorization_server_url + '/auth/v1/token',
				post_body,
				header
			);
			if (
				token_resp &&
				token_resp.results &&
				token_resp.results.accessToken
			) {
				this.set_cat_token('token', token_resp.results.accessToken);
			} else return false;
		}
		return true;
	}

	async getSubscriptionToken(res_id: string, itemType: any) {
		try {
			const isTokenNeeded = true;
			if (isTokenNeeded) {
				const post_body = {
					itemId: res_id,
					itemType: itemType,
					role: 'consumer'
				};
				const header = this.get_headers();
				const token_resp: any = await this.network.post_api(
					environment.authorization_server_url + 'auth/v1/token',
					post_body,
					header
				);
				if (
					token_resp &&
					token_resp.results &&
					token_resp.results.accessToken
				) {
					this.set_subcription_token(
						'token',
						token_resp.results.accessToken
					);
				} else return false;
			}
			return true;
		} catch (err: any) {
			return false;
		}
	}

	async getSecuredToken(id: any, role?: any) {
		const post_body = {
			itemId: id,
			itemType: 'resource',
			role: role
		};
		let header = '';
		if (role == 'delegate') {
			header = this.get_headers('consumer_delegate_id');
		}
		const token_resp: any = await this.network.post_api(
			environment.authorization_server_url + 'auth/v1/token',
			post_body,
			header,
			false
		);
		return token_resp.results.accessToken;
	}

	async getSecuredTokenForRG(id: any) {
		const post_body = {
			itemId: id,
			itemType: 'resource_group',
			role: 'consumer'
		};
		const token_resp: any = await this.network.post_api(
			environment.authorization_server_url + '/auth/v1/token',
			post_body
		);
		return token_resp.results.accessToken;
	}

	async getOpenToken(item: any, rsUrl: any = '') {
		// let itemId: any;
		// if (item == 'cos') {
		// 	itemId = environment.cos_item;
		// } else if (item == 'rs') {
		// 	itemId = environment.rs_item;
		// }

		const post_body = {
			itemId: rsUrl,
			itemType: 'resource_server',
			role: 'consumer'
		};

		const token_resp: any = await this.network.post_api(
			environment.authorization_server_url + 'auth/v1/token',
			post_body,
			'',
			false
		);

		return token_resp.results.accessToken;
	}
}
