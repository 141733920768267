import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GlobalService } from '../../../services/global.service';
import { SubscriberService } from '../../../services/subscriber.service';
import { ApiService as apiParent } from 'src/app/shared/services/api.service';
import { GlobalService as GlobalServiceCat } from 'src/app/catalogue/shared/services/global.service';

@Component({
	selector: 'app-request-reject',
	templateUrl: './request-reject.component.html',
	styleUrls: ['./request-reject.component.scss']
})
export class RequestRejectComponent implements OnInit {
	user_details: any;
	selectedResourceServer: any;
	is_provider: any;

	constructor(
		public global: GlobalService,
		public global_cat: GlobalServiceCat,
		public subscriber: SubscriberService,
		public api: ApiService,
		private apiParent: apiParent
	) {
		this.selectedResourceServer = localStorage.getItem(
			'selectedResourceServer'
		);
		const user_profile = this.global_cat.get_user_profile();
		if (user_profile?.roles?.includes('provider')) {
			this.is_provider = true;
		} else {
			this.is_provider = false;
		}
		this.user_details = this.global.get_temp_data();
	}

	ngOnInit(): void {}

	async reject_request(): Promise<void> {
		try {
			const body = {
				requestId: this.user_details.requestId,
				status: 'rejected'
			};
			let body_token;
			let header;
			if (this.is_provider) {
				body_token = {
					itemId: this.selectedResourceServer,
					itemType: 'resource_server',
					role: 'provider'
				};
			} else {
				body_token = {
					itemId: this.selectedResourceServer,
					itemType: 'resource_server',
					role: 'delegate'
				};
				header = 'delegate_id';
			}
			const accessToken = await this.apiParent.get_id_token(
				body_token,
				header
			);

			const data = await this.api.update_request(accessToken, body);
			this.closeRejectRequest(true);
			this.subscriber.set_toaster('success', data.title);
			this.subscriber.set_temp_data(
				'acl request update to reject successful'
			);
		} catch (error: any) {
			this.subscriber.set_toaster('danger', error);
		}
	}

	closeRejectRequest(isChanged?: boolean): void {
		this.subscriber.set_popup(false, 'reject-request', !!isChanged);
	}
}
