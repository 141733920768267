import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GlobalService as Globalcat } from 'src/app/catalogue/shared/services/global.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakUtilService as KeycloakUtilServiceCat } from 'src/app/catalogue/shared/services/keycloak-util.service';
import { environment } from 'src/environments/environment';
import { SubscriberService } from '../../services/subscriber.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesModalComponent } from 'src/app/shared/components/roles-modal/roles-modal.component';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
	user: any;
	delegate_details: any;
	roles: any;
	redirectArray: any;
	enableSwitchPanel = false;
	singleRoleName = '';
	consumerDelegate: any;
	providerDelegate: any;
	filteredProviderDelegates: any;

	constructor(
		public global: GlobalService,
		public global_cat: Globalcat,
		public keycloak: KeycloakService,
		public subscriber: SubscriberService,
		public keycloakUtilcat: KeycloakUtilServiceCat,
		private modalService: NgbModal,
		private sharedApiService: ApiService
	) {
		this.redirectArray = [];
		this.filteredProviderDelegates =
			this.global_cat.getFilteredProviderDelegates();
	}

	async ngOnInit() {
		this.user = this.global_cat.get_user_profile();
		this.roles = this.user.roles;
		await this.get_delegations();

		// switching panels logic
		if (this.roles?.length === 1) {
			if (this.roles[0] == 'delegate') {
				if (this.consumerDelegate && this.providerDelegate) {
					this.redirectArray.push('consumer delegate');
					// this.redirectArray.push('catalogue');
					if (this.filteredProviderDelegates?.length > 0) {
						this.redirectArray.push('provider delegate');
						this.enableSwitchPanel = true;
					}
				} else if (this.consumerDelegate) {
					this.singleRoleName = 'consumer delegate';
				} else if (this.providerDelegate) {
					this.singleRoleName = 'provider delegate';
				}
			} else {
				this.singleRoleName = this.roles[0];
			}
		} else if (this.redirectArray.length > 1) {
			//enable switch panel
			this.enableSwitchPanel = true;
		} else {
			this.enableSwitchPanel = false;
		}
	}

	logout(): void {
		this.keycloakUtilcat.logout(this.keycloak);
	}
	goToProfile(): void {
		window.open(environment.provider_web + '/my-profile', '_self');
	}
	navigateToPanel(role: string) {
		if (role === 'provider') {
			window.open(environment.catalogue_web + 'provider', '_blank');
		} else if (role === 'consumer') {
			window.open(environment.catalogue_web + 'consumer', '_blank');
		} else if (role === 'cos_admin') {
			window.open(environment.catalogue_web + 'cop-admin', '_blank');
		} else if (role === 'admin') {
			window.open(environment.catalogue_web + 'rs-admin', '_blank');
		} else if (role === 'provider delegate') {
			window.open(environment.catalogue_web + 'provider', '_blank');
		} else if (role === 'consumer delegate') {
			window.open(environment.catalogue_web + 'consumer', '_blank');
		}
	}
	toggleDropdown(event: any): void {
		this.global.setShowDropdown(!this.global.showDropdown);
		event?.stopPropagation();
	}
	async get_delegations() {
		const response: any = await this.sharedApiService.get_delegations();
		const uniqueIds: any = [];

		response?.results?.filter((element: any) => {
			const isDuplicate = uniqueIds.includes(element.role);

			if (!isDuplicate) {
				uniqueIds.push(element.role);
			}
		});
		this.consumerDelegate = uniqueIds.includes('consumer') ? true : false;
		this.providerDelegate = uniqueIds.includes('provider') ? true : false;
	}
	go_to_profile() {
		if (
			(this.roles?.includes('consumer') || this.consumerDelegate) &&
			!this.roles?.includes('catalogue')
		) {
			this.roles.push('catalogue');
		}
		const modalRef = this.modalService.open(RolesModalComponent, {
			centered: true,
			size: 'lg'
		});
		modalRef.componentInstance.roles = this.roles;
		modalRef.componentInstance.title = 'Choose your panel to explore !!';
	}
	redirectRoleBased(role: any) {
		switch (role) {
			case 'cos_admin':
				window.open(environment.catalogue_web + '/cop-admin', '_self');
				break;
			case 'admin':
				window.open(environment.catalogue_web + '/rs-admin', '_self');
				break;
			case 'consumer':
				window.open(environment.catalogue_web + '/consumer', '_self');
				break;
			case 'provider':
				window.open(environment.catalogue_web + '/provider', '_self');
				break;
			case 'delegate':
				this.getDelegates();
				break;
			default:
				// window.location.replace(environment.catalogue_web)
				break;
		}
	}
	getDelegates(): void {
		const delegate = {
			consumer: this.global_cat.get_is_consumer_delegate(),
			provider: this.global_cat.get_is_provider_delegate()
		};
		if (delegate.consumer && delegate.provider) {
			const delegate_roles = ['consumer', 'provider'];
			const modalRef = this.modalService.open(RolesModalComponent, {
				centered: true,
				size: 'md'
			});
			modalRef.componentInstance.roles = delegate_roles;
			modalRef.componentInstance.title = 'Delegate Roles';
		} else if (delegate.consumer) {
			window.open(environment.catalogue_web + '/consumer', '_self');
		} else {
			window.open(environment.catalogue_web + '/provider', '_self');
		}
	}
	redirect(item: string): void {
		if (item === 'consumer') {
			window.open(environment.consumer_web, '_blank');
		} else if (item === 'cos_admin') {
			window.open(environment.catalogue_web + 'cop-admin', '_blank');
		} else if (item === 'admin') {
			window.open(environment.catalogue_web + 'rs-admin', '_blank');
		}
	}
	chooseProvider(): void {
		this.subscriber.set_popup(true, 'choose-provider');
	}
}
