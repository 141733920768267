import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/catalogue/shared/services/global.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
	constructor(public global: GlobalService) {}

	ngOnInit(): void {}
}
