import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SubscriberService } from '../../services/subscriber.service';

@Component({
	selector: 'app-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
	links: any = [];
	linksObjects: any = [];

	constructor(
		private router: Router,
		private subscriber: SubscriberService
	) {}

	ngOnInit(): void {
		this.breadcrumbsRoutesLinkForming();
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.breadcrumbsRoutesLinkForming();
			}
		});
	}
	breadcrumbsRoutesLinkForming(): void {
		const url = this.router.url.substring(1);
		this.links = url.split('#')[0].split('/');
		//for forming link routes with names
		this.linksObjects = [];
		for (let index = 0; index < this.links.length; index++) {
			const linkName = this.links[index];
			const linkRoute = this.links.slice(0, index + 1).join('/');
			this.linksObjects.push({ linkName, linkRoute });
		}
	}

	setTitle(title: string) {
		if (title !== '') {
			this.subscriber.setTitle(title);
		} else {
			this.subscriber.setTitle(undefined);
		}
	}
}
