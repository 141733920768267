import { Component, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { KeycloakService } from 'keycloak-angular';
import { KeycloakUtilService } from './catalogue/shared/services/keycloak-util.service';
import { get_keycloak } from 'src/app/auth/services/keycloak.init';

import { GlobalService as ProviderGlobalService } from './provider/shared/services/global.service';
import { GlobalService as globalCatService } from './catalogue/shared/services/global.service';
import { GlobalService as ConsumerGlobalService } from './consumer/shared/services/global.service';

import { AuthorizationService } from './provider/shared/services/authorization.service';
import { AuthorizationService as authConsumer } from './consumer/shared/services/authorization.service';

import { ApiService as apiServiceConsumer } from './consumer/shared/services/api.service';
import { ApiService as ProviderApiService } from './provider/shared/services/api.service';
import { ApiService } from './catalogue/shared/services/api.service';
import { ApiService as SharedApiService } from './shared/services/api.service';

import { SubscriberService } from './catalogue/shared/services/subscriber.service';
import { SubscriberService as conSubscriber } from './consumer/shared/services/subscriber.service';
import { SubscriberService as providerSubscriber } from './provider/shared/services/subscriber.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {
	loader = false;
	toast_props: any;
	show_toast: boolean;
	is_loaded: boolean;

	constructor(
		public title: Title,
		public globalCat: globalCatService,
		public provider_global: ProviderGlobalService,
		public consumer_global: ConsumerGlobalService,
		public provider_api: ProviderApiService,
		public provider_auth: AuthorizationService,
		private authConsumer: authConsumer,
		public subscriber: SubscriberService,
		public conSubscriber: conSubscriber,
		public providerSubscriber: providerSubscriber,
		public api: ApiService,
		public shared_api: SharedApiService,
		public apiConsumer: apiServiceConsumer,
		public keycloakUtil: KeycloakUtilService,
		public keycloak: KeycloakService,
		public router: Router,
		private cdr: ChangeDetectorRef
	) {
		this.is_loaded = false;
		this.show_toast = false;
		this.subscriber.get_loader().subscribe((flag) => {
			this.loader = flag;
		});
		this.conSubscriber.get_loader().subscribe((flag) => {
			this.loader = flag;
		});
		this.subscriber.get_toaster().subscribe((data) => {
			this.toast_props = data;
			this.show_toast = true;
			setTimeout(() => {
				this.show_toast = false;
			}, 5000);
		});
		this.conSubscriber.get_toaster().subscribe((data) => {
			this.toast_props = data;
			this.show_toast = true;
			setTimeout(() => {
				this.show_toast = false;
			}, 5000);
		});
		this.providerSubscriber.get_toaster().subscribe((data) => {
			this.toast_props = data;
			this.show_toast = true;
			setTimeout(() => {
				this.show_toast = false;
			}, 5000);
		});
		get_keycloak().subscribe(() => {
			this.initialize();
		});
	}
	async initialize(): Promise<void> {
		this.is_loaded = await this.analyse_user_login();
	}

	async analyse_user_login(): Promise<boolean> {
		if (this.keycloakUtil.get_cookie_value('iudx-ui-sso') === 'logged-in') {
			await this.keycloak.isLoggedIn().then(async (result) => {
				if (result) {
					this.globalCat.set_login_status(true);
					const token: any = await this.keycloak.getToken();
					await this.analyse_user_profile('Bearer ' + token);
					this.globalCat.remove_login_attempted();
				} else {
					if (this.globalCat.is_login_attempted() == 'Y') {
						document.cookie =
							'iudx-ui-sso=logged-in;path=/;max-age=0;domain=' +
							environment.parent_domain;

						let iudxUiUsername = this.keycloak.getKeycloakInstance().idTokenParsed?.preferred_username;
						document.cookie =
							'iudx-ui-username=' + iudxUiUsername + ';path=/;max-age=0;domain=' +
							environment.parent_domain;
						this.globalCat.remove_login_attempted();
						window.location.replace(
							this.globalCat.get_url_without_hash()
						);
					} else {
						this.globalCat.set_login_attempted('Y');
						this.keycloak.login({
							redirectUri: window.location.href,
							prompt: 'none'
						});
					}
				}
			});
		} else {
			this.login();
			await this.keycloak.isLoggedIn().then(async (result) => {
				if (result) {
					this.globalCat.set_login_status(true);
					const token: any = await this.keycloak.getToken();
					await this.analyse_user_profile('Bearer ' + token);
					this.globalCat.remove_login_attempted();
				}
			});
			return true;
		}
		this.keycloakUtil.set_user();
		return true;
	}
	login(): void {
		this.keycloakUtil.login(this.keycloak);
	}

	async analyse_user_profile(token: any): Promise<boolean> {
		this.provider_auth.set_token(token);
		// replaced old profile with new one
		const response: any = await this.api.get_new_user_profile({ token });

		const profile = response?.results;

		await this.analyse_user_roles(response?.results);
		this.globalCat.set_user_profile(response?.results);
		// localStorage.setItem('userProfile', JSON.stringify(response?.results));
		const role = response?.results?.roles.includes('consumer')
			? 'consumer'
			: 'non-consumer';
		this.globalCat.set_role(role);
		localStorage.setItem('iudx-ui-cat-auth-token', token);
		if (
			profile?.roles.includes('consumer') ||
			profile?.roles.includes('delegate')
		) {
			this.authConsumer.set_auth_token(token);
		} else if (
			profile?.roles.includes('provider') ||
			profile?.roles.includes('delegate')
		) {
			const origin = location.pathname.split('/')[1];
			if (origin == 'provider') {
				localStorage.setItem('iudx-ui-pub-auth-token', token);
			}
		} else {
			this.subscriber.set_toaster(
				'error',
				"You don't have access to some of the resources in this panel."
			);
		}
		if (profile?.roles?.includes('delegate')) {
			const response: any = await this.shared_api.get_delegations();
			// //set deafult resource_server
			const temp = response?.results;
			temp.sort((a: any, b: any) => (a?.url > b?.url ? 1 : -1));
			// const defaultResourceServer = temp[0].url;
			// localStorage.setItem('defaultRsUrl', defaultResourceServer);
			// this.provider_global.set_resource_server(defaultResourceServer);
			const providerDelegates: any = [];

			response?.results?.forEach((element: any) => {
				if (element.role == 'consumer') {
					this.globalCat.set_is_consumer_delegate(true);
					if (!profile?.roles.includes('consumer')) {
						this.consumer_global.set_consumer_delegate_details(
							element.id,
							element.url,
							element.owner.email,
							element.owner.name
						);
					}
				} else if (element.role == 'provider') {
					if (
						!profile.rolesToRsMapping?.provider?.includes(
							element.url
						)
					) {
						providerDelegates.push(element);
					}
					this.globalCat.set_is_provider_delegate(true);
				}
			});
			this.globalCat.setFilteredProviderDelegates(providerDelegates);
		}
		return true;
	}

	async analyse_user_roles(user: any) {
		let flag = false;
		if (
			user?.roles.includes('provider') &&
			!(localStorage.getItem('isProviderDelegate') === 'true')
		) {
			flag = true;
			this.provider_global.set_role('provider');
			this.provider_global.set_provider_id(user.userId);
		}
		//  else if (user.roles.includes('delegate'))
		// flag = await this.analyse_delegate_type();
		return flag;
	}

	async analyse_delegate_type() {
		const data: any = await this.provider_api.get_delegates();
		const providers: any = [];
		let provider = '';
		let count = 0;
		const old_provider = localStorage.getItem('provider_id');
		if (old_provider && old_provider != '') provider = old_provider;
		data.results.forEach((a: any) => {
			if (a.url == environment.role_urls.authorization_server_url) {
				providers.push(a.owner);
				if (a.owner.id == provider) count++;
			}
		});
		if (count == 0) provider = '';
		if (providers.length > 0) {
			this.provider_global.set_role('auth_delegate');
			this.provider_global.set_providers(providers);
			provider = provider != '' ? provider : providers[0].id;
			this.provider_global.set_provider_id(provider);
			return true;
		} else {
			this.subscriber.set_toaster(
				'error',
				"You don't have access to this panel."
			);
			return false;
		}
	}
	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
}
