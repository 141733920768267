import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NetworkService as CatNetworkService } from 'src/app/catalogue/shared/services/network.service';
import { NetworkService } from 'src/app/auth/services/network.service';
import { GlobalService as GlobalCatService } from 'src/app/catalogue/shared/services/global.service';

import { environment } from 'src/environments/environment';
import { ModalComponent } from 'src/app/cop-admin/shared/components/modal/modal.component';
import { SubscriberService } from 'src/app/cop-admin/shared/services/subscriber.service';

@Component({
	selector: 'app-shared-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	isServerSelected = false;
	isConsumer: boolean;
	isProvider: boolean;
	roles: any[];
	disable = true;

	providerDropdownList: any = [];
	providerDropdownSettings = {};
	resourceServerList: any;
	selectedServer: any = [];

	alreadyConsumer = false;
	alreadyProvider = false;

	profile: any;
	provider_rs_urls: any = [];
	serverDropdownList: any[];
	selectedConsumerServer: any;
	atleastOneRoleSelected: any;
	serverDropdownSettings: {
		singleSelection: boolean;
		idField: string;
		textField: string;
		itemsShowLimit: number;
		enableCheckAll: boolean;
	};
	disableProvider = false;
	providerForm!: FormGroup;
	providerFormValid: boolean;
	coordinates = {
		lng: 0,
		lat: 0
	};
	isCoordinatesSelected: boolean;
	showCoordinates: boolean;
	getLocationSubscriber: any;
	constructor(
		private catNetwork: CatNetworkService,
		private globalCat: GlobalCatService,
		private fb: FormBuilder,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private subscriber: SubscriberService,
		private network: NetworkService
	) {
		this.isProvider = false;
		this.isConsumer = false;
		this.showCoordinates = false;
		this.roles = [];

		// consumer dropdownlist and settings
		this.serverDropdownList = [];
		this.serverDropdownSettings = {
			singleSelection: false,
			idField: 'item_id',
			textField: 'item_text',
			itemsShowLimit: 2,
			enableCheckAll: false
		};
		this.providerForm = this.fb.group({
			providerName: ['', [Validators.required]],
			providerDescription: ['', [Validators.required]],
			OrganizationName: ['', [Validators.required]],
			additionalInfoUrl: ['', [Validators.required]],
			organisationAddress: ['', [Validators.required]]
		});
		this.providerForm
			.get('providerName')
			?.valueChanges.subscribe((value) => {
				this.providerForm.get('OrganizationName')?.setValue(value);
			});

		this.providerFormValid = false;
		this.isCoordinatesSelected = false;
		this.getLocationSubscriber = this.subscriber
			.get_location_coordinates()
			.subscribe((data) => {
				this.showCoordinates = true;
				this.coordinates = data.coordinates;
				this.coordinates.lat == 0 && this.coordinates.lng == 0
					? (this.isCoordinatesSelected = true)
					: (this.isCoordinatesSelected = false);
			});
	}

	async ngOnInit(): Promise<void> {
		const response: any = await this.catNetwork.get_api(
			environment.auth_server_url + 'auth/v1/resourceservers'
		);
		this.profile = this.globalCat.get_user_profile();
		this.alreadyConsumer = this.profile.roles.includes('consumer');
		this.alreadyProvider = this.profile.roles.includes('provider');
		if (this.profile.roles.includes('provider')) {
			this.provider_rs_urls = this.profile.rolesToRsMapping?.provider;
		}

		this.resourceServerList = response?.results;
		//provider dropdown list  and settings
		this.providerDropdownList = [];
		this.selectedServer = [];
		this.providerDropdownSettings = {
			singleSelection: true,
			idField: 'item_id',
			textField: 'item_text',
			closeDropDownOnSelection: true,
			allowSearchFilter: true
		};

		this.selectedConsumerServer = [];

		this.populateDropdown();
	}

	ngOnDestroy() {
		this.getLocationSubscriber.unsubscribe();
	}
	populateDropdown() {
		this.disable = false;
		this.resourceServerList?.forEach((e: any) => {
			this.serverDropdownList?.push(e.url);
			this.selectedConsumerServer?.push(e.url);
			if (this.provider_rs_urls.indexOf(e.url) === -1) {
				this.providerDropdownList?.push(e.url);
			}
		});
		this.disableProvider = this.providerDropdownList?.length === 0;
		this.disable = true;
	}

	toggleCapability(role: any) {
		if (!this.roles.includes(role)) {
			this.roles?.push(role);
		} else {
			const capability = this.roles.filter((item: any) => {
				return item !== role;
			});
			this.roles = capability;
		}
		this.isProvider = this.roles.includes('provider');
		this.isConsumer = this.roles.includes('consumer');
	}

	validate() {
		this.selectedServer?.length === 0
			? (this.isServerSelected = true)
			: (this.isServerSelected = false);
		return this.isServerSelected;
	}

	openLocationPickerModal() {
		const modalRef = this.modalService.open(ModalComponent, {
			centered: true,
			size: 'lg'
		});
		modalRef.componentInstance.formData = {
			isLocationPickerModal: true,
			title: 'Pick Location on Map'
		};
	}

	async submit(event: any) {
		event?.preventDefault();
		//validations
		this.providerFormValid = this.providerForm.status == 'INVALID';
		this.coordinates.lat == 0 && this.coordinates.lng == 0
			? (this.isCoordinatesSelected = true)
			: (this.isCoordinatesSelected = false);
		let res: any = {};
		if (this.alreadyConsumer) {
			if (this.isProvider) {
				if (!this.selectedServer || this.selectedServer?.length < 1) {
					this.atleastOneRoleSelected = '';
					this.isServerSelected = true;
					return;
				}
				if (!this.alreadyProvider) {
					if (this.providerFormValid || this.isCoordinatesSelected) {
						return;
					}
				}
				res = { provider: this.selectedServer };
			} else {
				this.atleastOneRoleSelected = 'Please select provider role';
				return;
			}
		} else {
			this.atleastOneRoleSelected = '';
			if (this.isProvider) {
				if (!this.selectedServer || this.selectedServer?.length < 1) {
					this.isServerSelected = true;
					return;
				}
				if (!this.alreadyProvider) {
					if (this.providerFormValid) {
						return;
					}
				}
				res.provider = this.selectedServer;
			}
			if (this.isConsumer) {
				res.consumer = this.selectedConsumerServer;
			}
			if (!this.isConsumer && !this.isProvider) {
				this.atleastOneRoleSelected = 'Please select atleast one role';
				return;
			}
		}
		const userInfo = {
			serverId: this.selectedServer[0], //server?.results[0].id,
			providerName: this.providerForm.value.providerName,
			providerDescription: this.providerForm.value.providerDescription,
			resourceServerOrg: {
				name: this.providerForm.value.OrganizationName,
				additionalInfoURL: this.providerForm.value.additionalInfoUrl,
				location: {
					type: 'Place',
					address: this.providerForm.value.organisationAddress,
					geometry: {
						type: 'Point',
						coordinates: [
							this.coordinates.lat,
							this.coordinates.lng
						]
					}
				}
			}
		};
		res.userInfo = userInfo;
		await this.network.post_api(
			environment.auth_server_url + 'auth/v1/user/roles',
			res,
			'',
			true
		);
	}
}
