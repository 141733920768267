<div class="container my-5">
	<h1 class="mb-5">Transactions Summary</h1>

	<div class="d-flex justify-content-start tabs">
		<span [ngClass]="visualize? '' : 'selected'" (click)="toggleTabs()"
			>Table</span
		>
		<span [ngClass]="visualize? 'selected' : ''" (click)="toggleTabs()"
			>Graph</span
		>
	</div>
	<div *ngIf="visualize">
		<div
			echarts
			[options]="options"
			class="demo-chart mt-5 border pt-4"
			style="height: 70vh"
		></div>
	</div>
	<div *ngIf="!visualize">
		<div echarts [options]="optionsSize" class="demo-chart"></div>
		<div class="body mt-5">
			<table>
				<tr>
					<th>Description</th>
					<th>Call Count</th>
					<th>Total data Size (in MB)</th>
				</tr>
				<tr *ngFor="let value of summaryCalls">
					<td>
						<p class="fw-bold">{{ value.description }}</p>
						<div *ngIf="value.dates">{{ value.dates }}</div>
					</td>
					<td>{{value.count}}</td>
					<td>{{value.size}}</td>
				</tr>
			</table>
		</div>
	</div>
</div>
