import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SubscriberService } from './subscriber.service';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	active_instance: any = '';
	instance: any;
	instances: any = [];
	res_instance: any;
	id: any;
	type: any;
	intro_steps_home: any = {};
	intro_steps_dataset: any = {};
	filters: any = window.sessionStorage.filters
		? JSON.parse(window.sessionStorage.filters)
		: this.get_default_filters();
	dataset_filters: any = window.sessionStorage.datasets
		? JSON.parse(window.sessionStorage.datasets)
		: [];
	filters_sub = new Subject<any>();
	datasets: any;
	temp_data: any;
	single_resource: any;
	url_resource: any;
	dataset_res: any;
	res_token: any;
	request_dataset_id = '';
	loadingMessage = '';
	map_colors: any = [
		'#1c699d',
		'#ff7592',
		'#564d65',
		'#2fcb83',
		'#0ea3b1',
		'#f39c1c',
		'#d35414',
		'#9b59b6'
	];
	user_profile: any = null;
	role: any;
	query_res: any;
	// count = 0;
	asyncQueries: any = window.sessionStorage.asyncQueries
		? JSON.parse(window.sessionStorage.asyncQueries)
		: [];
	maxQueries: any = 5;
	login_status = false;
	is_provider_delegate = false;
	is_consumer_delegate = false;
	email: any;
	filteredProviderDelegates = [];
	selDomain: any = {};
	constructor(private subscriber: SubscriberService) {
		this.temp_data = {};
		this.user_profile = {};
		this.res_token = {
			public: '',
			private: ''
		};
		this.role = '';
	}

	set_login_status(status: boolean): void {
		this.login_status = status;
	}

	get_login_status(): boolean {
		return this.login_status;
	}

	set_role(value: any): void {
		this.role = value;
	}

	get_role(): string {
		return this.role;
	}

	get_email(): string {
		return this.email;
	}

	set_email(value: any): void {
		this.email = value;
	}

	// Intro js
	set_introjs_steps_home(step: string, index: any): any {
		this.intro_steps_home[index] = step;
	}
	get_introjs_steps_home(): any {
		return this.intro_steps_home;
	}

	set_introjs_steps_dataset(step: string, index: any): any {
		this.intro_steps_dataset[index] = step;
	}
	get_introjs_steps_dataset(): any {
		return this.intro_steps_dataset;
	}

	set_active_instance(instance: any): void {
		this.active_instance = instance;
	}

	get_active_instance(): any {
		return this.active_instance;
	}

	set_instance(instance: any): void {
		this.instance = instance;
	}

	get_instance(): any {
		return this.instance;
	}

	set_instances(instances: any): void {
		this.instances = instances;
	}

	get_instances(): any {
		return this.instances;
	}

	set_res_instance(instance: any): void {
		this.res_instance = instance;
	}

	get_res_instance(): any {
		return this.res_instance;
	}

	get_default_filters(): any {
		return {
			instance: this.get_active_instance(),
			tags: ["icmr"],
			providers: [],
			id: '',
			domains: [],
			disease: [],
			location: []
		};
	}

	get_filters_Obj(): any {
		return this.filters;
	}

	set_filters(filters: any): void {		
		this.filters = filters;
		window.sessionStorage.filters = JSON.stringify(this.filters);
		this.filters_sub.next(this.filters);
	}

	get_filters(): Observable<any> {
		return this.filters_sub.asObservable();
	}

	set_datasets(datasets: any): void {
		this.datasets = datasets;
	}

	get_datasets(): any {
		return this.datasets;
	}

	set_dataset_filters(filters: any): void {
		this.dataset_filters = filters;
		window.sessionStorage.datasets = JSON.stringify(this.dataset_filters);
	}

	get_dataset_filters(): any {
		return this.dataset_filters;
	}

	get_map_colors(): any {
		return this.map_colors;
	}

	copy(id: string, message: string): void {
		const el = document.createElement('textarea');
		el.id = 'copyId';
		el.value = id;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		this.subscriber.set_toaster('success', message);
	}

	/* To copy any Text */
	copyText(val: any, message: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.subscriber.set_toaster('success', message);
	}

	set_temp_data(data: any): void {
		this.temp_data = data;
	}

	get_temp_data(): any {
		return this.temp_data;
	}

	manipulate_data_descriptor(obj: any) {
		if (!obj) return {};
		const arr: any = [],
			flags: any = [];
		const keys = Object.keys(obj);
		keys.forEach((a: any) => {
			if (
				typeof obj[a] == 'object' &&
				a != 'dataDescriptorLabel' &&
				a != 'type'
			) {
				const o = { key: a, ...obj[a] };
				arr.push(o);
			}
		});
		const data_descriptor = arr;
		flags.length = data_descriptor.length;
		data_descriptor.forEach((a: any, i: number) => {
			data_descriptor[i] = this.convert_obj_array_of_objs(a);
			flags[i] = false;
		});
		return { data_descriptor: data_descriptor, flags: flags };
	}

	convert_obj_array_of_objs(obj: any) {
		const keys = Object.keys(obj);
		const arr: any = [];
		keys.forEach((a) => {
			if (a != 'type') {
				let data;
				if (typeof obj[a] == 'string') {
					data = {
						key: a,
						value: obj[a].includes(':')
							? obj[a].split(':')[1]
							: obj[a]
					};
				} else if (typeof obj[a] == 'number') {
					data = {
						key: a,
						value: obj[a],
						level: 1
					};
				} else if (typeof obj[a] == 'object') {
					data = {
						key: a,
						value: this.convert_obj_array_of_objs(obj[a])
					};
				}
				if (data) arr.push(data);
			}
		});
		return arr;
	}

	set_single_resource(resource: any): void {
		this.single_resource = resource;
	}

	get_single_resource(): any {
		return this.single_resource;
	}

	set_url_resource(resource: any): void {
		this.url_resource = resource;
	}

	get_url_resource(): any {
		return this.url_resource;
	}
	
	get_dataset_res(): any {
		return this.dataset_res;
	}

	set_dataset_res(res: any): void {
		this.dataset_res = res;
	}

	set_user_profile(value: any): void {
		this.user_profile = value;
	}

	get_user_profile(): any {
		return this.user_profile;
	}

	set_is_consumer_delegate(value: any): void {
		this.is_consumer_delegate = value;
	}
	set_is_provider_delegate(value: any): void {
		this.is_provider_delegate = value;
	}
	get_is_consumer_delegate(): boolean {
		return this.is_consumer_delegate;
	}
	get_is_provider_delegate(): boolean {
		return this.is_provider_delegate;
	}

	setFilteredProviderDelegates(value: any): void {
		this.filteredProviderDelegates = value;
	}

	getFilteredProviderDelegates(): any {
		return this.filteredProviderDelegates;
	}

	is_login_attempted(): any {
		return localStorage.getItem('isLoginAttempted');
	}

	set_login_attempted(value: string): void {
		localStorage.setItem('isLoginAttempted', value);
	}

	remove_login_attempted(): void {
		localStorage.removeItem('isLoginAttempted');
	}

	get_url_without_hash(): string {
		return window.location.href.replace(window.location.hash, '');
	}

	set_query_resource(res: any): void {
		this.query_res = res;
	}

	get_query_resource(): any {
		return this.query_res;
	}

	setLoadingMessage(message: string): void {
		this.loadingMessage = message;
	}

	getQueries(): any {
		return this.asyncQueries;
	}
	deleteQueries(index: number): void {
		const queries = this.getQueries();
		const removedQuery = queries.splice(index, 1);
		return removedQuery[0];
	}
	addQueries(querydetail: any): any {
		const queries = this.getQueries();
		queries.push(querydetail);
	}
	findQueries(querydetail: any): any {
		const queries = this.getQueries();
		for (let i = 0; i < queries.length; i++) {
			if (
				querydetail.userId == queries[i].userId &&
				querydetail.resourceId == queries[i].resourceId
			) {
				return i;
			}
		}
		return -1;
	}

	set_async_queries(): void {
		window.sessionStorage.asyncQueries = JSON.stringify(this.asyncQueries);
	}

	updateQueriesQueue(queryDetail: any): void {
		const index = this.findQueries(queryDetail);
		const queries = this.getQueries();

		if (index != -1) {
			this.deleteQueries(index);
			this.addQueries(queryDetail);
		} else {
			const filteredQueries = queries.filter(
				(query: any) => query.userId == queryDetail.userId
			);
			if (filteredQueries.length >= this.maxQueries) {
				this.deleteQueries(0);
			}
			this.addQueries(queryDetail);
		}
		this.set_async_queries();
	}
}
