import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from '../../../shared/services/api.service';
import { ApiService as SharedApiService } from 'src/app/shared/services/api.service';
import { GlobalService } from '../../../shared/services/global.service';
import { GlobalService as GlobalCatService } from 'src/app/catalogue/shared/services/global.service';
import { SubscriberService } from 'src/app/provider/shared/services/subscriber.service';

@Component({
	selector: 'app-create-linked-account',
	templateUrl: './create-linked-account.component.html',
	styleUrls: ['./create-linked-account.component.scss']
})
export class CreateLinkedAccountComponent implements OnInit {
	businessdropdownSettings: IDropdownSettings = {};
	categorySettings: IDropdownSettings = {};

	linkedAccountForm: any;
	availabeBusinessTypes: any;
	businessTypeTouched: any;
	businessCategories: any;
	businessCategoryTouched: any;
	categoriesAndSubCatagoriesObj: any;
	businessSubCategoryTouched: any;
	rsUrl: any;
	userProfile: any;
	providerDelegateUpdate: any;
	updateMode: any;
	accoutDetails: any;
	businessType: any = [];
	profileCategory: any = [];
	//profileSubCategory: any;
	submitClicked = false;

	constructor(
		private formBuilder: FormBuilder,
		private shared_api: SharedApiService,
		private api: ApiService,
		private global: GlobalService,
		private global_cat: GlobalCatService,
		private subscriber: SubscriberService
	) {
		this.global.setTitle('Create Linked Account');
		this.userProfile = this.global_cat.get_user_profile();

		this.providerDelegateUpdate = this.subscriber
			.get_provider_delegate()
			.subscribe(async () => {
				await this.fetchLinkedAccount();
			});

		this.linkedAccountForm = this.formBuilder.group({
			phone: ['', Validators.required],
			legalBusinessName: ['individual', Validators.required],
			customerFacingBusinessName: [''],
			contactName: [''],
			profileSubCategory: ['', Validators.required],
			street1: ['', Validators.required],
			street2: ['', Validators.required],
			city: ['', Validators.required],
			state: ['', Validators.required],
			country: [
				{
					value: 'India',
					disabled: true
				},
				Validators.required
			],
			postalCode: ['', Validators.required],
			legalInfo: [false],
			pan: [''],
			gst: ['']
		});

		this.availabeBusinessTypes = [
			{ type: 'educational_institutes' },
			{ type: 'individual' },
			{ type: 'llp' },
			{ type: 'ngo' }
		];

		this.businessCategories = [
			'financial_services',
			'education',
			'healthcare',
			'utilities'
		];

		this.categoriesAndSubCatagoriesObj = {
			healthcare: [
				'mutual_fund',
				'lending',
				'cryptocurrency',
				'insurance',
				'clinic'
			]
		};
	}

	async ngOnInit(): Promise<void> {
		this.businessdropdownSettings = {
			singleSelection: true,
			closeDropDownOnSelection: true,
			idField: 'type',
			textField: 'type',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};
		this.categorySettings = {
			singleSelection: true,
			closeDropDownOnSelection: true,
			itemsShowLimit: 3,
			allowSearchFilter: true
		};
		await this.fetchLinkedAccount();
	}

	ngOnDestroy(): void {
		this.providerDelegateUpdate.unsubscribe();
	}

	async fetchLinkedAccount() {
		await this.getIdToken();
		const fetchAccountResult: any = await this.api.fetchLinkedAccount();
		if (fetchAccountResult) {
			this.updateMode = true;
			this.global.setTitle('Update Linked Account');

			this.accoutDetails = fetchAccountResult;
			this.linkedAccountForm.controls.phone.setValue(
				fetchAccountResult.phone
			);
			this.linkedAccountForm.controls.legalBusinessName.setValue(
				fetchAccountResult.legalBusinessName
			);
			this.linkedAccountForm.controls.customerFacingBusinessName.setValue(
				fetchAccountResult.customerFacingBusinessName
			);
			this.linkedAccountForm.controls.contactName.setValue(
				fetchAccountResult.contactName
			);
			this.linkedAccountForm.controls.street1.setValue(
				fetchAccountResult.profile.addresses.registered.street1
			);
			this.linkedAccountForm.controls.street2.setValue(
				fetchAccountResult.profile.addresses.registered.street2
			);
			this.linkedAccountForm.controls.city.setValue(
				fetchAccountResult.profile.addresses.registered.city
			);
			this.linkedAccountForm.controls.state.setValue(
				fetchAccountResult.profile.addresses.registered.state
			);
			this.linkedAccountForm.controls.country.setValue(
				fetchAccountResult.profile.addresses.registered.country
			);
			this.linkedAccountForm.controls.postalCode.setValue(
				fetchAccountResult.profile.addresses.registered.postalCode
			);

			//dropdown values
			if (fetchAccountResult.businessType) {
				this.selectBusinessType({
					type: fetchAccountResult.businessType
				});
			}
			if (fetchAccountResult.profile.category) {
				this.selectBusinessCategory(
					fetchAccountResult.profile.category
				);
			}
			this.linkedAccountForm.controls.profileSubCategory.setValue(
				fetchAccountResult.profile.subcategory
			);
			//legalInfo
			if (fetchAccountResult.legalInfo) {
				this.linkedAccountForm.controls.legalInfo.setValue(true);
				this.linkedAccountForm.controls.pan.setValue(
					fetchAccountResult.legalInfo.pan
				);
				this.linkedAccountForm.controls.gst.setValue(
					fetchAccountResult.legalInfo.gst
				);
			}
		}
	}

	async getIdToken(): Promise<void> {
		let post_body;
		let header;
		this.rsUrl = this.global.getResourceServerProvider();

		if (
			this.userProfile.roles?.includes('provider') &&
			!(localStorage.getItem('isProviderDelegate') === 'true')
		) {
			post_body = {
				itemId: this.rsUrl,
				itemType: 'resource_server',
				role: 'provider'
			};
		} else {
			post_body = {
				itemId: this.rsUrl,
				itemType: 'resource_server',
				role: 'delegate'
			};
			header = 'delegate_id';
		}

		const accessToken = await this.shared_api.get_provider_id_token(
			post_body,
			header
		);
		return accessToken;
	}

	async onSubmit(): Promise<void> {
		this.submitClicked = true;
		if (this.linkedAccountForm.status === 'INVALID') {
			return;
		}
		const postBody: any = {
			email: this.userProfile.email,
			phone: this.linkedAccountForm.controls.phone.value,
			legalBusinessName:
				this.linkedAccountForm.controls.legalBusinessName.value,
			businessType: this.businessType[0].type,
			profile: {
				category: this.profileCategory[0],
				subcategory:
					this.linkedAccountForm.controls.profileSubCategory.value,
				addresses: {
					registered: {
						street1: this.linkedAccountForm.controls.street1.value,
						street2: this.linkedAccountForm.controls.street2.value,
						city: this.linkedAccountForm.controls.city.value,
						state: this.linkedAccountForm.controls.state.value,
						postalCode:
							this.linkedAccountForm.controls.postalCode.value,
						country: 'IN'
					}
				}
			}
		};
		if (
			this.linkedAccountForm.controls.pan.value ||
			this.linkedAccountForm.controls.gst.value
		) {
			postBody.legalInfo = {};
		}
		if (this.linkedAccountForm.controls.pan.value) {
			postBody.legalInfo.pan = this.linkedAccountForm.controls.pan.value;
		}
		if (this.linkedAccountForm.controls.gst.value) {
			postBody.legalInfo.gst = this.linkedAccountForm.controls.gst.value;
		}
		if (this.linkedAccountForm.controls.customerFacingBusinessName.value) {
			postBody.customerFacingBusinessName =
				this.linkedAccountForm.controls.customerFacingBusinessName.value;
		}
		if (this.linkedAccountForm.controls.contactName.value) {
			postBody.contactName =
				this.linkedAccountForm.controls.contactName.value;
		}
		await this.getIdToken();

		if (this.updateMode) {
			delete postBody.businessType;
			this.api.updateLinkedAccount(postBody).then(async (data: any) => {
				if (data.title === 'Success') await this.fetchLinkedAccount();
			});
		} else {
			await this.api
				.createLinkedAccount(postBody)
				.then(async (data: any) => {
					if (data.title === 'Success')
						await this.fetchLinkedAccount();
				});
		}
	}

	selectBusinessType(event: any) {
		this.businessType = [];
		if (event) {
			this.businessType.push(event);
		}
		this.businessTypeTouched = true;
	}

	selectBusinessCategory(event: any) {
		this.profileCategory = [];
		if (event) {
			this.profileCategory.push(event);
		}
		this.linkedAccountForm.controls.profileSubCategory.value = '';
		this.businessCategoryTouched = true;
	}
}
